import React, { Component } from 'react';
import styles from './TeamPlayers.module.css';
import {
    Header,
    Segment,
    Table,
    TableRow,
} from 'semantic-ui-react'
import Common from '../../../Common.js';
import PlayerUpdateModalForm from './PlayerUpdateModalForm.js';

class TeamPlayers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberList: "",
            selId: '',
            selMemText: '',
            selMemPosition: '',
            selMemAuthlevel_id: '',
            selMemAuthlevel_time: '',
            selMemRfid: '',
            updateModalOpen: false,
        }
    }

    componentDidMount = () => {
        this.getTeamInfomation();
    }

    async getTeamInfomation() {
        if (sessionStorage.getItem('token')) {
            await fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    'Authorization': `JWT ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(json => {
                    this.getMemberData(json.profile.team.id);
                });
        }
    }

    async getMemberData(teamId) {
        await fetch(Common.backendUrl + "/api/teammember/" + teamId)
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wroeng!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    memberList: data,
                });
            });
    }

    playerTableRowClick = (e, id) => {
        e.preventDefault();
        alert(id)
    }

    modalCloseMethod = () => {
        this.setState({
            updateModalOpen: false,
        })
    }

    render() {

        let players = "";

        if (this.state.memberList !== "") {
            players = this.state.memberList.map(
                (info, index) => (
                    index === this.state.memberList.length - 1 ?
                        <TableRow className={styles.player_tableRow} key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}
                            onClick={() => this.setState({
                                selId: info.id,
                                selMemText: info.member_text,
                                selMemPosition: info.member_position,
                                selMemAuthlevel_id: info.member_authlevel,
                                selMemAuthlevel_time: info.member_authlevel_time,
                                selMemRfid: info.member_rfid,
                                updateModalOpen: true,
                            })}>
                            <Table.Cell textAlign='center'>{info.member_text}</Table.Cell>
                            <Table.Cell textAlign='center'>{info.member_id}</Table.Cell>
                            {info.member_position !== null &&
                                <Table.Cell textAlign='center'>{info.member_position.position_text}</Table.Cell>
                            }
                            {info.member_position === null &&
                                <Table.Cell textAlign='center'>{info.member_position}</Table.Cell>
                            }
                            <Table.Cell textAlign='center'>{info.member_rfid}</Table.Cell>
                            {info.member_authlevel !== null &&
                                <Table.Cell textAlign='center'>{info.member_authlevel.auth_text}</Table.Cell>
                            }
                            {info.member_authlevel === null &&
                                <Table.Cell textAlign='center'>{info.member_authlevel}</Table.Cell>
                            }
                            <Table.Cell textAlign='center'>{info.member_authlevel_time}초</Table.Cell>
                        </TableRow>
                        :
                        <TableRow className={styles.player_tableRow} key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}
                            onClick={() => this.setState({
                                selId: info.id,
                                selMemText: info.member_text,
                                selMemPosition: info.member_position,
                                selMemAuthlevel_id: info.member_authlevel,
                                selMemAuthlevel_time: info.member_authlevel_time,
                                selMemRfid: info.member_rfid,
                                updateModalOpen: true,
                            })}>
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_text}</Table.Cell>
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_id}</Table.Cell>
                            {info.member_position !== null &&
                                <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_position.position_text}</Table.Cell>
                            }
                            {info.member_position === null &&
                                <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_position}</Table.Cell>
                            }
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_rfid}</Table.Cell>
                            {info.member_authlevel !== null &&
                                <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_authlevel.auth_text}</Table.Cell>
                            }
                            {info.member_authlevel === null &&
                                <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_authlevel}</Table.Cell>
                            }
                            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.member_authlevel_time}초</Table.Cell>
                        </TableRow>
                )
            );
        }


        return (
            <Segment style={{ border: "1px solid #000000" }}>
                <Segment attached='top' style={{ border: "1px solid #000000" }}>
                    <Header textAlign='center' as='h3'>등록 선수 현황</Header>
                </Segment>
                <Segment attached style={{ border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>이름</Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>선수자격번호</Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>포지션</Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>RFID</Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>레벨</Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign='center'>기록</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{ display: "block", height: "100%", overflow: "auto" }}>
                            {players}
                        </Table.Body>
                    </Table>
                </Segment>
                <PlayerUpdateModalForm
                    isOpen={this.state.updateModalOpen}
                    id={this.state.selId}
                    member_text={this.state.selMemText}
                    member_position={this.state.selMemPosition.id}
                    authlevel_id={this.state.selMemAuthlevel_id}
                    authlevel_time={this.state.selMemAuthlevel_time}
                    member_rfid={this.state.selMemRfid}
                    closeMethod={this.modalCloseMethod}
                />
            </Segment>
        );
    }
}

export default TeamPlayers;