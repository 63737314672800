import React, { Component } from "react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import styles from "./TeamInfo.module.css";
import {
    Input,
    Dropdown,
    Form,
    Segment,
    List,
    Grid,
    Header,
    Table,
    TableRow,
    Pagination,
} from 'semantic-ui-react'
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import ModalTeamRequest from "../../../warning_modal/ModalTeamRequest.js";

import Common from '../../../Common';

const daum = window.daum;

class TeamInfo extends Component {
    constructor(props) {
        super(props);
        this.team_id = "";
    }
    state = {
        leagueId: "",
        teamName: "",
        address: "",
        membership: "",
        playground: "",
        practiceInfo: "",
        memo: "",
        phone: "",
        team_image: "",
        team_image_file: "",
        teamData: "",
        // memberList: [],
        // aplicationData: [],
        warningModalOpen: false,
        requestModalOpen: false,
        applyModalOpen: false,
        warningText: "",
        requestContent: "",
        warningLocation: false,
        teamRequests: "",
        requestsCnt: 0,
        totalPages: 1,
        selectedPage: 1,
        userProfile: "",
    }


    async getTeamInfomation() {
        if (sessionStorage.getItem('token')) {
            await fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    'Authorization': `JWT ${sessionStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(json => {
                    let newTeamData = {
                        team_id: json.profile.team.id,
                        team_text: json.profile.team.team_text,
                        team_phone: json.profile.team.team_phone,
                        team_image: json.profile.team.team_image,
                        team_image_file: json.profile.team.team_image_file,
                        practice_info: json.profile.team.practice_info,
                        reg_date: json.profile.team.reg_date,
                        membership: json.profile.team.membership,
                        league: json.profile.team.league.id,
                        address: json.profile.team.address,
                        address_detail: json.profile.team.address_detail,
                        playground: json.profile.team.playground,
                        memo_text: json.profile.team.memo_text,
                        info_text: json.profile.team.info_text
                    }


                    this.team_id = json.profile.team.id

                    this.setState({
                        teamData: newTeamData,
                        userProfile: json.profile
                    });

                    this.getMembershipList();
                    // this.getMemberData();
                    // this.getTeamAplicationData();
                });
        }
    }

    // async getMemberData() {
    //     await fetch(Common.backendUrl + "/api/teammember/" + this.state.teamData.team_id)
    //         .then(response => {
    //             if (response.status > 400) {
    //                 return this.setState(() => {
    //                     return { placeholder: "Something went wroeng!" };
    //                 });
    //             }
    //             return response.json();
    //         })
    //         .then(data => {
    //             this.setState({
    //                 memberList: data,
    //             });
    //         });

    // }

    getMembershipList() {
        let arr = [];
        const membership = [{ id: 0, membership: "일반회원" }, { id: 1, membership: "정회원" }]
        membership.forEach(data => arr.push({ key: data.id, text: data.membership, value: data.id }))
        this.setState({ membershipOptions: arr });
    }

    getLeagueList() {
        fetch(Common.backendUrl + "/api/league/")
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                let arr = [];
                data.forEach(league => arr.push({ key: league.id, text: league.league_text, value: league.id }));
                this.setState({ leagueOptions: arr });
            });
    }

    componentDidMount() {
        this.getTeamInfomation();
        // this.getMembershipList();
        this.getLeagueList();

    }

    handleMembershipChange = (e, { value }) => {
        this.setState({ membership: value });
    };

    handleLeagueChange = (e, { value }) => {
        this.setState({ leagueId: value });
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamName: value });
    };

    dateHandleChange = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + '-' + (checkDate.getMonth() + 1) + '-' + checkDate.getDate();
            this.setState({
                regDate: strDate
            });
        }
    };

    searchAddr(objStr) {
        new daum.Postcode({
            oncomplete: function (data) {
                var addr = ''; // 주소 변수
                var extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else { // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                if (data.userSelectedType === 'R') {
                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if (extraAddr !== '') {
                        extraAddr = ' (' + extraAddr + ')';
                    }
                    // 조합된 참고항목을 해당 필드에 넣는다.
                } else {

                }
                document.getElementById(objStr).value = "(" + data.zonecode + ") " + addr + " " + extraAddr;;
                // 우편번호와 주소 정보를 해당 필드에 넣는다.
            }
        }).open();
    }

    searchPlayground(objStr) {
        new daum.Postcode({
            oncomplete: function (data) {
                var addr = ''; // 주소 변수
                var extraAddr = ''; // 참고항목 변수

                //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
                if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                    addr = data.roadAddress;
                } else { // 사용자가 지번 주소를 선택했을 경우(J)
                    addr = data.jibunAddress;
                }

                // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
                if (data.userSelectedType === 'R') {
                    // 법정동명이 있을 경우 추가한다. (법정리는 제외)
                    // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
                    if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
                        extraAddr += data.bname;
                    }
                    // 건물명이 있고, 공동주택일 경우 추가한다.
                    if (data.buildingName !== '' && data.apartment === 'Y') {
                        extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
                    }
                    // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
                    if (extraAddr !== '') {
                        extraAddr = ' (' + extraAddr + ')';
                    }
                    // 조합된 참고항목을 해당 필드에 넣는다.
                } else {

                }
                document.getElementById(objStr).value = "(" + data.zonecode + ") " + addr + " " + extraAddr;
                this.setState({ playground: "(" + data.zonecode + ") " + addr + " " + extraAddr })
                // 우편번호와 주소 정보를 해당 필드에 넣는다.
            }.bind(this)
        }).open();
    }

    handlePracticeInfoChange = (e, { value }) => {
        this.setState({ practiceInfo: value });
    };

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;
        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3);
            let middleNum = checkPhone.slice(3, 7);
            let lastNum = checkPhone.slice(7, 11);
            const strPhone = firstNum + '-' + middleNum + '-' + lastNum
            this.setState({
                phone: strPhone
            });
        }
        else {
            this.setState({
                phone: ""
            });
        }
    };

    handleMemoChange = (e, { value }) => {
        this.setState({ memo: value });
    };

    handleImageChange = (e) => {
        this.setState({
            team_image_file: e.target.files[0]
        })
    };

    handleSubmit = e => {
        e.preventDefault();

        let team = {
            leagueId: "",
            teamName: "",
            address: "",
            membership: "",
            playground: "",
            practiceInfo: "",
            memo: ""
        }

        if (this.state.leagueId === "") {
            team.leagueId = this.state.teamData.league
        } else {
            team.leagueId = this.state.leagueId
        }

        if (this.state.teamName === "") {
            team.teamName = this.state.teamData.team_text
        } else {
            team.teamName = this.state.teamName
        }

        if (this.state.address === "") {
            team.address = this.state.teamData.address
        } else {
            team.address = this.state.address
        }

        if (this.state.membership === "") {
            team.membership = this.state.teamData.membership
        } else {
            team.membership = this.state.membership
        }

        if (this.state.playground === "") {
            team.playground = this.state.teamData.playground
        } else {
            team.playground = this.state.playground
        }

        if (this.state.practiceInfo === "") {
            team.practiceInfo = this.state.teamData.practice_info
        } else {
            team.practiceInfo = this.state.practiceInfo
        }

        if (this.state.phone === "") {
            team.phone = this.state.teamData.team_phone
        } else {
            team.phone = this.state.phone
        }



        let form_data = new FormData();

        if (this.state.team_image_file !== "") {
            form_data.append('team_image', this.state.team_image_file.name)
            form_data.append('team_image_file', this.state.team_image_file, this.state.team_image_file.name)
        } else {
            if (this.state.teamData.team_image_file !== null) {
                let parse = this.state.teamData.team_image_file.split('/')
                form_data.append('team_image', parse[5])
            } else {
                form_data.append('team_image', this.state.teamData.team_image);
            }
        }

        form_data.append('team_text', team.teamName)
        form_data.append('membership', team.membership)
        form_data.append('address', document.getElementById('address').value)
        form_data.append('address_detail', document.getElementById('address_detail').value)
        form_data.append('playground', team.playground)
        form_data.append('practice_info', team.practiceInfo)
        form_data.append('team_phone', team.phone)
        form_data.append('league_id', team.leagueId)
        form_data.append('info_text', document.getElementById("info_text").value)

        fetch(Common.backendUrl + "/api/teamupdate/" + this.state.teamData.team_id, {
            method: 'put',
            body: form_data,
        })
            .then(response => {
                if (response.status >= 400) {
                    return response.json();
                } else {
                    return 200;
                }
            })
            .then(json => {
                if (json !== undefined) {
                    if (json === 200) {
                        this.handleWarningModalOpen("선수단 수정 완료");
                        this.setState({
                            warningLocation: true,
                        })
                        return;
                    }
                    this.handleWarningModalOpen(json.detail);
                } else {
                    this.handleWarningModalOpen("error");
                }
            })
    };

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split('-')
            let monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"]
            let day = parts[2].split('T')

            let d = day[0] + " " + monthNames[parseInt(parts[1]) - 1] + " " + parts[0]
            return new Date(d);
        } else {
            return;
        }
    }

    convertDateTime(dateStr) {
        if (dateStr) {
            let parts = dateStr.split('-')
            let day = parts[2].split('T')
            let time = day[1].split('.')
            let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0] + " " + time[0]
            return covertedDate;
        } else {
            return;
        }
    }

    handleDownloadDoc = fileName => e => {
        // Downloads the file
        let link = document.createElement("a");
        link.download = `${fileName}.txt`;
        let blob = new Blob(["Hello, world!"], { type: "text/plain" });
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);

        // Opens in new window
        // let blob = new Blob(["Hello, world!"], { type: "text/plain" });
        // const fileURL = URL.createObjectURL(blob);
        // window.open(fileURL);
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningLocation: false,
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
        if (this.state.warningLocation) {
            document.location.reload();
        }
    }



    render() {
        let team_practice_info = ''
        let team_memo = ''

        if (this.state.teamData.practice_info === 'nan' | this.state.teamData.practice_info === '\N') {
            team_practice_info = '없음'
        } else {
            team_practice_info = this.state.teamData.practice_info
        }

        if (this.state.teamData.memo_text === 'nan' | this.state.teamData.memo_text === '\N') {
            team_memo = '없음'
        } else {
            team_memo = this.state.teamData.memo_text
        }


        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Segment style={{ width: "90%", border: "1px solid #000000" }} >
                        <Segment style={{ border: "1px solid #000000" }}>
                            <Grid divided='vertically'>
                                <Grid.Row columns={1}>
                                    <Header as='h2' ><label className={styles.team}><span>선수단 정보</span></label></Header>
                                    <Grid.Column>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Field required>
                                                <label className={styles.team}><span>선수단명</span></label>
                                                <Input
                                                    style={{ pointerEvents: 'none', border: "1px solid #000000", borderRadius: "5px" }}
                                                    type="text"
                                                    name="team_text"
                                                    maxLength='50'
                                                    defaultValue={this.state.teamData.team_text}
                                                    onChange={this.handleTeamChange}
                                                    required
                                                />
                                            </Form.Field>
                                            {this.state.teamData !== "" &&
                                                <Form.Field required>
                                                    <label className={styles.team}><span>회원구분</span></label>
                                                    <Input
                                                        style={{ pointerEvents: 'none', border: "1px solid #000000", borderRadius: "5px" }}
                                                        name="membership"
                                                        defaultValue={this.state.teamData.membership === 1 ? "정회원" : "일반회원"}
                                                        fluid
                                                    />
                                                    {/* <Dropdown
                                                        style={{ border: "1px solid #000000" }}
                                                        selection options={this.state.membershipOptions}
                                                        name="membership"
                                                        defaultValue={this.state.teamData.membership}
                                                        onChange={this.handleMembershipChange}
                                                        fluid
                                                    /> */}
                                                </Form.Field>
                                            }
                                            <Form.Field required>
                                                <label className={styles.team}><span>팀창단일</span></label>
                                                <SemanticDatepicker
                                                    className={styles.datepicker_input}
                                                    name="reg_date"
                                                    onChange={this.dateHandleChange}
                                                    value={this.toDate(this.state.teamData.reg_date)}
                                                    required />
                                            </Form.Field>
                                            {this.state.teamData !== "" &&
                                                <Form.Field required>
                                                    <label className={styles.team}><span>리그</span></label>
                                                    <Input
                                                        style={{ pointerEvents: "none", border: "1px solid #000000", borderRadius: "5px" }}
                                                        name="league_text"
                                                        defaultValue={this.state.teamData.league === 1 ? "1부 리그" : this.state.teamData.league === 2 ? "2부 리그" : this.state.teamData.league === 3 ? "3부 리그" : "유소년 리그"} 
                                                        fluid
                                                    />
                                                    {/* <Dropdown
                                                        style={{ border: "1px solid #000000" }}
                                                        selection options={this.state.leagueOptions}
                                                        name="league_text"
                                                        defaultValue={this.state.teamData.league}
                                                        onChange={this.handleLeagueChange}
                                                        fluid
                                                    /> */}
                                                </Form.Field>
                                            }
                                            <Form.Field required>
                                                <label className={styles.team}><span>대표주소</span></label>
                                                <Input
                                                    style={{ border: "1px solid #000000", borderRadius: "5px", marginBottom: "5px" }}
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    defaultValue={this.state.teamData.address}
                                                    onClick={function (e) { this.searchAddr('address') }.bind(this)}
                                                    required
                                                />
                                                <Input
                                                    style={{ border: "1px solid #000000", borderRadius: "5px" }}
                                                    type="text"
                                                    name="address_detail"
                                                    id="address_detail"
                                                    defaultValue={this.state.teamData.address_detail}
                                                    required
                                                />
                                            </Form.Field>
                                            <Form.Field required>
                                                <label className={styles.team}><span>선수단 전화번호</span></label>
                                                <Input
                                                    style={{ border: "1px solid #000000", borderRadius: "5px" }}
                                                    placeholder='-을 제외한 11자리 번호입력'
                                                    type="text"
                                                    name="phone"
                                                    maxLength='15'
                                                    defaultValue={this.state.teamData.team_phone}
                                                    onChange={this.handlePhoneChange}
                                                    required
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <label className={styles.team}><span>팀 로고 이미지 (500x500 ~ 800x800 사이즈)</span></label>
                                                <Input
                                                    style={{border: "1px solid #000000", borderRadius: "5px"}}
                                                    type="file"
                                                    name="profile"
                                                    onChange={this.handleImageChange}
                                                    accept="image/png, image/jpeg"
                                                />
                                                {this.state.teamData.team_image_file !== null &&
                                                    <div style={{ marginTop: '7px' }}>
                                                        <Segment style={{border: "1px solid #000000"}}>
                                                            <List.Item>
                                                                <List.Content>기존 로고 이미지: {this.state.teamData.team_image}</List.Content>
                                                            </List.Item>
                                                        </Segment>
                                                    </div>
                                                }
                                                {this.state.teamData.team_image_file === null &&
                                                    <div style={{ marginTop: '7px' }}>
                                                        <Segment style={{border: "1px solid #000000"}}>
                                                            <List.Item>
                                                                로고 이미지 없음
                                                            </List.Item>
                                                        </Segment>
                                                    </div>
                                                }
                                            </Form.Field>
                                            {this.state.teamData !== "" &&
                                                <Form.Field>
                                                    <label className="label">선수단 소개</label>
                                                    <textarea
                                                        style={{ resize: "none", height: "60px", border: "1px solid #000000" }}
                                                        type="text"
                                                        name="memo_text"
                                                        id="info_text"
                                                        placeholder="선수단을 자유롭게 소개해주세요."

                                                    >
                                                        {this.state.teamData.info_text}
                                                    </textarea>
                                                </Form.Field>
                                            }
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <a className={`${styles.style_a2} ${styles.mouse_hover} `} style={{ marginTop: '20px', cursor: 'pointer' }} onClick={this.handleSubmit}>
                                                수정</a>
                                            </div>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Segment>
                    </Segment>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default TeamInfo