import React, { Component } from "react";
import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";
import styles from "./TeamRankMain.module.css";
import Common from "../Common";

export default class TeamRankMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamList: [],
      modalOpen: false,
      modalTeamId: "",
    };
  }

  componentDidMount() {
    this.getTeamData(1);
  }

  getTeamData(selNum) {
    fetch(Common.backendUrl + "/api/teamrank/" + selNum)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          teamList: data,
        });
      });
  }

  selLeagueHandler(selNum) {
    this.getTeamData(selNum);
  }

  openModal = (e, teamId) => {
    this.setState({
      modalOpen: true,
      modalTeamId: teamId,
    });
  };

  handleClose = (e) => {
    this.setState({
      modalOpen: false,
      modalTeamId: "",
    });
  };

  handleTeamId = (e) => {
    this.setState({
      modalTeamId: "",
    });
  };

  renderImgBorder1 = () => {
    if (isMobile || isAndroid || isIOS || isTablet) {
      return (
        <img
          className={styles.rank_team_border1}
          src="/images/main/1st_border.png"
          alt="1st team"
          style={{ filter: "none" }}
        />
      );
    } else {
      return (
        <img
          className={styles.rank_team_border1}
          src="/images/main/1st_border.png"
          alt="1st team"
        />
      );
    }
  };

  renderImgBorder2 = () => {
    if (isMobile || isAndroid || isIOS || isTablet) {
      return (
        <img
          className={styles.rank_team_border2}
          src="/images/main/2nd_border.png"
          alt="2nd team"
          style={{ filter: "none" }}
        />
      );
    } else {
      return (
        <img
          className={styles.rank_team_border2}
          src="/images/main/2nd_border.png"
          alt="2nd team"
        />
      );
    }
  };

  renderImgBorder3 = () => {
    if (isMobile || isAndroid || isIOS || isTablet) {
      return (
        <img
          className={styles.rank_team_border3}
          src="/images/main/3rd_border.png"
          alt="3rd team"
          style={{ filter: "none" }}
        />
      );
    } else {
      return (
        <img
          className={styles.rank_team_border3}
          src="/images/main/3rd_border.png"
          alt="3rd team"
        />
      );
    }
  };

  render() {
    return (
      <div className={styles.wrap_div}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "300px",
          }}
        >
          <h1>TOP TEAM</h1>
          <hr style={{ width: "100%" }} />
          <button
            className={styles.all_rank_btn}
            onClick={() => (window.location.href = "/rank/team/")}
          >
            전체 순위
          </button>
        </div>

        <div className={styles.content_div}>
          {this.state.teamList ? (
            this.state.teamList.length > 0 ? (
              <>
                <div className={styles.rank_div2}>
                  <div style={{ position: "relative" }}>
                    {this.state.teamList[1]["winner__team_image_file"] ===
                      null ||
                    this.state.teamList[1]["winner__team_image_file"] === "" ? (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img2}
                        src="/images/default_teamlogo_02.png"
                        alt="2nd team logo"
                      />
                    ) : (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img2}
                        src={
                          "https://kdsa-react-s3.s3.amazonaws.com/" +
                          this.state.teamList[1]["winner__team_image_file"]
                        }
                        alt="2nd team logo"
                      />
                    )}
                    {this.renderImgBorder2()}
                    <img
                      className={styles.rank_flag}
                      src="/images/main/2nd_flag.png"
                      alt="2nd team"
                    />
                    <img
                      className={styles.rank_flash2}
                      src="/images/main/2nd_flash.png"
                      art="flash"
                    />
                    <p className={styles.rank_team_name}>
                      {this.state.teamList[1]["winner__team_text"]}
                    </p>
                  </div>
                </div>

                <div className={styles.rank_div}>
                  <div style={{ position: "relative" }}>
                    {this.state.teamList[0]["winner__team_image_file"] ===
                      null ||
                    this.state.teamList[0]["winner__team_image_file"] === "" ? (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img1}
                        src="/images/default_teamlogo_02.png"
                        alt="1st team logo"
                      />
                    ) : (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img1}
                        src={
                          "https://kdsa-react-s3.s3.amazonaws.com/" +
                          this.state.teamList[0]["winner__team_image_file"]
                        }
                        alt="1st team logo"
                      />
                    )}
                    {this.renderImgBorder1()}
                    <img
                      className={styles.rank_flag}
                      src="/images/main/1st_flag.png"
                      alt="1st team"
                    />
                    <img
                      className={styles.rank_flash1}
                      src="/images/main/1st_flash.png"
                      art="flash"
                    />
                    <p className={styles.rank_team_name1}>
                      {this.state.teamList[0]["winner__team_text"]}
                    </p>
                  </div>
                </div>

                <div className={styles.rank_div3}>
                  <div style={{ position: "relative" }}>
                    {this.state.teamList[2]["winner__team_image_file"] ===
                      null ||
                    this.state.teamList[2]["winner__team_image_file"] === "" ? (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img2}
                        src="/images/default_teamlogo_02.png"
                        alt="3rd team logo"
                      />
                    ) : (
                      <img
                        style={{ backgroundColor: "black" }}
                        className={styles.logoTeam_img2}
                        src={
                          "https://kdsa-react-s3.s3.amazonaws.com/" +
                          this.state.teamList[2]["winner__team_image_file"]
                        }
                        alt="3rd team logo"
                      />
                    )}
                    {this.renderImgBorder3()}
                    <img
                      className={styles.rank_flag}
                      src="/images/main/3rd_flag.png"
                      alt="3rd team"
                    />
                    <img
                      className={styles.rank_flash3}
                      src="/images/main/3rd_flash.png"
                      art="flash"
                    />
                    <p className={styles.rank_team_name}>
                      {this.state.teamList[2]["winner__team_text"]}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
