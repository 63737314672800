import React, { Component } from 'react';
import { Button, Header, Icon, Modal, Input } from 'semantic-ui-react'
import Common from '../../../Common.js';

class EditArenaModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editName: "",
            editUrl: "",
            editImg: "",
        }
    }
    

    handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData();

        if (this.state.editName.length === 0) {
            formData.append("name", this.props.banner.name)
        } else {
            formData.append("name", this.state.editName)
        }


        if (this.state.editImg != 0){
           formData.append("banner_img", this.state.editImg);
        }
        

        if (this.state.editUrl.length === 0) {
            formData.append("url", this.props.banner.url);
        } else {
            formData.append("url", this.state.editUrl);
        }

        const conf = {
            method: "put",
            body: formData
        };
        fetch(Common.backendUrl + `/api/banner/update/` + this.props.banner.id, conf)
            .then(response => {
                if (response.status > 400) {
                    alert("error")
                    return;
                } else{
                    alert("수정되었습니다.")
                    this.props.closeMethod();
                    return;
                }
            });

        
    }

   
    render() {
        return (
            <Modal
            open={this.props.isOpen}
            size="tiny"
        >
                <Header>{this.props.banner.name}</Header>


                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{margin: "0px"}}>배너 이름</p>
                    <Input type="text" onChange={(e) =>this.setState({editName: e.target.value})} defaultValue={this.props.banner.name}></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
                    <p style={{ margin: "0px" }}>Url</p>
                    <Input type="text" onChange={(e) =>this.setState({editUrl: e.target.value})} placeholder="https://dronesoccer.or.kr" defaultValue={this.props.banner.url}></Input>
                </Modal.Content>

                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <p style={{ margin: "0px" }}>배너 사진</p>
                        <Input type="file" accept="image/png, image/jpeg" onChange={(e) => this.setState({editImg: e.target.files[0]})} alt="이미지 선택"  />
                    </div>
                </Modal.Content>




                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    <Button color='green' inverted onClick={(e) => this.handleSubmit(e)}>
                        <Icon name='check' />적용
                    </Button>
                    <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                        <Icon name='remove' />닫기
                    </Button>
                </Modal.Actions>

        </Modal>
        );
    }
}

export default EditArenaModal;