import React, { Component } from 'react';
import styles from './Shop.module.css';
import Common from "../Common.js";


class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: "",
        }
    }

    componentDidMount = () => {
        this.getBannerData();
    }

    getBannerData = () => {
        fetch(Common.backendUrl + `/api/banner/list/`)
            .then(res => {
                if (res.status > 400) {
                    alert("error");
                }
                return res.json();
            }).then(banners => {
                // console.log(banners)
                this.setState({
                    banners
                })
            })
    }



    render() {
        const { banners } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div className={styles.wrap_div}>
                <div className={styles.banner_div}>
                {banners ?
                    banners.map(banner => (
                        <div key={banner.id} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => window.open(banner.url)}>
                            <img style={{maxWidth: "100px", marginBottom: "10px"}} src={banner.banner_img} alt="banner image" />
                            <p style={{width: "150px", textAlign: "center", fontFamily: "Noto Sans KR", color: "#FFFFFF", fontWeight: "bold", fontSize: "17px"}}>{banner.name}</p>
                        </div>
                    ))
                    :
                    null
                }
                </div>
            </div>
        );
    }
}

export default Shop;