import React, { Component } from 'react'
import styles from "./RankTypeTab.module.css";
import {withTranslation} from 'react-i18next';

class RankTypeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rankTypeList: [],
      rank_type_id:  1,
    }
  }

  geRankList() {
    const { t } = this.props;
    let arr = [];
        // const rank_type = [{ id: 1, rank_type: "DS포인트" }, { id: 2, rank_type: "다승" }, { id: 3, rank_type: "최다출전" }]
        const rank_type = [{ id: 1, rank_type: t('rank.RankTeam.WinRank') }, { id: 2, rank_type: t('rank.RankTeam.Most_Appearances') }]
        rank_type.forEach(data => arr.push({ id: data.id, rank_type_text: data.rank_type, value: data.id }))
        this.setState({ rankTypeList: arr });
  }

  componentDidMount() {
    this.geRankList();
  }

  onClickHandler(rank_type) {
    this.setState({ rank_type_id: rank_type });
    this.props.onSelRankType(rank_type);
  }

  render() {
    let itemDiv = [];
    for (let i = 0; i < this.state.rankTypeList.length; i++) {
      if (parseInt(this.state.rank_type_id) === parseInt(this.state.rankTypeList[i].id)) {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.rankTypeList[i].id)} className={styles.item_div} key={this.state.rankTypeList[i].id} style={{ backgroundColor: "#5814E1", color: "white" }}>
          {this.state.rankTypeList[i].rank_type_text}
        </div>);
      } else {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.rankTypeList[i].id)} className={styles.item_div} key={this.state.rankTypeList[i].id}>
          {this.state.rankTypeList[i].rank_type_text}
        </div>);
      }
    }
 
    return (
      <div style={{ display: "flex" }}>
        {itemDiv}
      </div>
    )
  }
}

export default withTranslation()(RankTypeTab);