import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./translation.en.json";
import translationKo from "./translation.ko.json";
import translationjp from "./translation.jp.json";
import translationch from "./translation.ch.json";
import translationfr from "./translation.fr.json";
const resource = {
    en: {
        translation: translationEn,
    },
    ko: {
        translation: translationKo,
    },
    jp: {
        translation: translationjp,
    },
    ch: {
        translation: translationch,
    },
    fr: {
        translation: translationfr,
    },
};

i18n.use(Backend)
    .use(LanguageDetector) // 사용자 언어 감지
    .use(initReactI18next) // i18n 인스턴스를 react-i18next에 전달
    //i18next 초기화
    .init({
        detection: {
            lookupLocalStorage: "language",
        },
        resources: resource,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

if (i18n.language === "ko-KR") {
    i18n.changeLanguage("ko");
}
export default i18n;
