import React, { Component } from 'react';
import { Modal, Button, FormField, Input, Form, TextArea } from 'semantic-ui-react';
import styles from "./ApplyTeamRegistrationForm.module.css";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from "../../../Common.js";

class ApplyTeamRegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText: "",
            warningLocation: false,
        }
    }



    handleApproveClick = (e) => {
        e.preventDefault();

        let formData = new FormData();

        if (this.props.registration[0].team_image_file !== null) {
            formData.append('team_image', this.props.registration[0].team_image)
            formData.append('team_image_file', this.props.registration[0].team_image_file)
        }

        formData.append('team_text', this.props.registration[0].team_name)
        // formData.append('memo_text', this.props.registration[0].memo_text)
        formData.append('membership', this.props.registration[0].membership)
        formData.append('league_id', this.props.registration[0].league_text)
        formData.append('address', this.props.registration[0].address)
        formData.append('address_detail', this.props.registration[0].address_detail)
        // formData.append('playground', this.props.registration[0].playground)
        // formData.append('practice_info', this.props.registration[0].practice_cycle)
        formData.append('team_phone', this.props.registration[0].team_phone)
        formData.append('region_id', this.props.registration[0].region_id)


        fetch(Common.backendUrl + "/api/team/make", {
            method: 'post',
            body: formData,
        })
            .then(response => {
                if (response.status >= 400) {
                    return response.json();
                } else {
                    return 200;
                }
            }).then(json => {
                if (json != undefined) {
                    if (json === 200) {
                        this.setState({
                            warningModalOpen: true,
                            warningText: "선수단 창단승인 완료",
                        })
                        this.handleApproveStateChange()
                        return;
                    } else if (json.message) {
                        this.setState({
                            warningModalOpen: true,
                            warningText: json.message,
                            warningLocation: false
                        })
                        return    
                    }
                    this.setState({
                        warningModalOpen: true,
                        warningText: json.detail,
                        warningLocation: false
                    })
                } else {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "error",
                        warningLocation: false
                    })
                }
            })
    }

    handleTeamRegistrationDelete = (e) => {
        e.preventDefault();

        fetch(Common.backendUrl + `/api/teamregistration/update/${this.props.registration[0].id}`,{
            method: 'DELETE'
        })
            .then(response => {
                if (response.status > 400) {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "error"
                    })
                    return;
                } else if (response.status === 204) {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "삭제되었습니다.",
                        warningLocation: true,
                    })

                    return;
                }
            });
    }

    handleApproveStateChange = () => {
        let formData = new FormData();
        formData.append("status", 1);
        formData.append('team_name', this.props.registration[0].team_name)
        formData.append('applicant_name', this.props.registration[0].applicant_name)
        formData.append('applicant_email', this.props.registration[0].applicant_email)

        const conf = {
            method: "put",
            body: formData
        };

        fetch(Common.backendUrl + `/api/teamregistration/update/${this.props.registration[0].id}`, conf)
            .then(response => {
                if (response.status > 400) {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "error",
                        warningLocation: false
                    })
                    return;
                } else if (response.status === 200) {
                    window.location.reload();
                    return;
                }
            });
    }

    handleRejectClick = (e) => {
        e.preventDefault();

        if (document.getElementById('rejectReason').value == '') {
            this.setState({
                warningModalOpen: true,
                warningText: '반려 사유를 입력해주세요.'
            })
            return
        }

        let formData = new FormData();
        formData.append("status", 2);
        formData.append('team_name', this.props.registration[0].team_name)
        formData.append('applicant_name', this.props.registration[0].applicant_name)
        formData.append('applicant_email', this.props.registration[0].applicant_email)
        formData.append('info_text', document.getElementById('rejectReason').value)

        // 반려 메세지 입력햇는지 확인하기.

        const conf = {
            method: "put",
            body: formData
        };

        fetch(Common.backendUrl + `/api/teamregistration/update/${this.props.registration[0].id}`, conf)
            .then(response => {
                if (response.status > 400) {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "error"
                    })
                    return;
                } else if (response.status === 200) {
                    this.setState({
                        warningModalOpen: true,
                        warningText: "반려처리가 완료되었습니다.",
                        warningLocation: true,
                    })

                    return;
                }
            });
    }

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })

        if (this.state.warningLocation) {
            window.location.reload();
        }
    }



    render() {

        
        
        if (this.props.registration) {
            return (
                <Modal
                    size={'tiny'}
                    open={this.props.isOpen}
                >
                    <Modal.Header><p style={{ width: "100%", textAlign: "center", fontFamily: "Noto Sans KR" }} >선수단 창단신청 관리</p></Modal.Header>
                    <Modal.Content>
                        <div className={styles.content_div}>
                            <p><span>*</span>은 필수 항목입니다.</p>
                            <div>
                                <p>지회<span>*</span>: </p>
                                <p>{this.props.registration[0].province_name}</p>
                            </div>
                            <div>
                                <p>지부<span>*</span> : </p>
                                <p>{this.props.registration[0].region_name}</p>
                            </div>
                            <div>
                                <p>선수단명<span>*</span> : </p>
                                <p>{this.props.registration[0].team_name}</p>
                            </div>
                            <div>
                                <p>회원구분<span>*</span> : </p>
                                <p>{this.props.registration[0].membership === 0 ? "일반회원" : "정회원"}</p>
                            </div>
                            <div>
                                <p>팀창단일 : </p>
                                <p>{this.props.registration[0].reg_date}</p>
                            </div>
                            <div>
                                <p>리그<span>*</span> : </p>
                                <p>{this.props.registration[0].league_text === "1" ? "1부리그" : (this.props.registration[0].league_text === "2" ? "2부리그" : this.props.registration[0].league_text === "3" ? "3부리그" : "유소년 리그")}</p>
                            </div>
                            <div>
                                <p>대표주소<span>*</span> : </p>
                                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                    <p>{this.props.registration[0].address}</p>
                                    <p>{this.props.registration[0].address_detail}</p>
                                </div>
                            </div>


                            <div style={{border: 0, marginBottom: "0px"}}>
                                <p>선수단장 정보<span>*</span></p>
                            </div>
                            <div style={{border: 0, marginBottom: "0px"}}>
                                <p>이름 : </p>
                                <p>{this.props.registration[0].applicant_name}</p>
                            </div>
                            <div style={{border: 0, marginBottom: "0px"}}>
                                <p>이메일 : </p>
                                <p>{this.props.registration[0].applicant_email}</p>
                            </div>
                            <div>
                                <p>전화번호 : </p>
                                <p>{this.props.registration[0].team_phone}</p>
                            </div>
                            <div>
                                <p style={{flex: 1}}>팀 로고 : </p>
                                {this.props.registration[0].team_image_file === null || this.props.registration[0].team_image_file.length === 0 ?
                                    <p>등록된 로고가 없습니다.</p>
                                    :
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                                        <img  width='100px' height='100px' src={this.props.registration[0].team_image_file} alt="team logo" />
                                    </div>
                                }
                            </div>
                            <div>
                                <p>선수단 소개</p>
                                <Form>
                                    <TextArea
                                        style={{ resize: "none", height: "60px" }}
                                    >
                                        {this.props.registration[0].info_text}
                                    </TextArea>
                                </Form>
                            </div>
                            <Form style={{ width: "80%" }}>
                                <TextArea
                                    style={{ resize: "none", height: "60px" }}
                                    id="rejectReason"
                                    placeholder="반려 사유를 입력해주세요."                        
                                >
                                </TextArea>
                            </Form>
                        </div>
                    </Modal.Content>
                    <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        {this.props.registration[0].status === 0 ?
                            <>
                                <Button color='green' inverted onClick={(e) => this.handleApproveClick(e)}>
                                    승인
                                </Button>
                                <Button color='orange' inverted onClick={(e) => this.handleRejectClick(e)}>
                                    반려
                                </Button>
                            </>
                            :
                            <></>
                        }
                        {this.props.registration[0].status === 2 ?
                                <Button color='green' inverted onClick={(e) => this.handleTeamRegistrationDelete(e)}>
                                    삭제
                                </Button>
                            :
                            <></>
                        }

                        <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                            닫기
                        </Button>
                    </Modal.Actions>
                    <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                </Modal>
            );
        } else {
            return <></>
        }
    }
}



export default ApplyTeamRegistrationForm;