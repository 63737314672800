import React, { Component } from "react";
import queryString from "query-string";
import Topmenu from "../../Topmenu/Topmenu.js";
import Footer from "../../footer/Footer.js";
import styles from "./Modify.module.css";
import Common from "../../Common";
import { withTranslation } from "react-i18next";
import ModalChoiceOne from "../../warning_modal/ModalChoiceOne.js";
import ModalConfirm from "../../warning_modal/ModalConfirm.js";
import { Loader, Checkbox } from "semantic-ui-react";

const smartEditor = window.nhn.husky;

let oEditors = [];

class Modify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            content: "",
            attachfiles: [],
            newAttachfiles: [],
            uploadList: [],
            queryStringStr: null,
            warningModalOpen: false,
            warningText: "",
            warningLocation: false,
            confirmFileModalOpen: false,
            confirmText: "",
            delFileId: "",
            delFile: false,
            isConfirm: false,
            isImportant: false,
            // e: "",
        };

        const queryValue = queryString.parse(this.props.location.search);
        let queryStringStr = "";

        if (queryValue.pageNum) {
            queryStringStr = queryStringStr + "?pageNum=" + queryValue.pageNum;
        }

        if (queryValue.column) {
            queryStringStr = queryStringStr + "&column=" + queryValue.column;
        }

        if (queryValue.searchString) {
            queryStringStr = queryStringStr + "&searchString=" + queryValue.searchString;
        }

        this.state.queryStringStr = queryStringStr;
    }

    queryValue = queryString.parse(this.props.location.search);

    fileChangedHandler = (e) => {
        const maxSize = 100 * 1024 * 1024; // 100M 제한
        const files = e.target.files;
        let sumFileSize = 0;

        for (let i = 0; i < files.length; i++) {
            sumFileSize += files[i].size;
        }

        if (sumFileSize >= maxSize) {
            this.setState({
                warningModalOpen: true,
                warningText: "최대 업로드 용량(100M)을 초과했습니다",
            });
            return;
        }

        document.getElementById("loader").style.display = "flex";
        document.getElementById("wrap_div").style.pointerEvents = "none";
        this.setState({
            newAttachfiles: files,
        });
        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("attachfiles", files[i]);
        }

        fetch(Common.backendUrl + "/board/api/board/", {
            method: "POST",
            body: formData,
        })
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                if (json.msg === "success") {
                    this.setState({
                        uploadList: json.data,
                    });
                } else if (json.filepath) {
                    // alert(json.filepath[0])
                    this.setState({
                        warningModalOpen: true,
                        warningText: json.filepath[0],
                    });
                } else {
                    // alert(json.msg)
                    this.setState({
                        warningModalOpen: true,
                        warningText: json.msg,
                    });
                }

                document.getElementById("loader").style.display = "none";
                document.getElementById("wrap_div").style.pointerEvents = "auto";
            })
            .catch((e) => {
                document.getElementById("loader").style.display = "none";
                document.getElementById("wrap_div").style.pointerEvents = "auto";

                this.setState({
                    warningModalOpen: true,
                    warningText: "error",
                });
            });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (oEditors.getById.se2.getIR().length < 10) {
            this.setState({
                warningModalOpen: true,
                warningText: "내용을 10글자 이상 입력해주세요.",
            });
            return;
        }

        const { t } = this.props;
        const formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("content", oEditors.getById.se2.getIR());
        // formData.append("attachfiles", this.state.attachfiles);
        if (document.getElementById("isImportant")) {
            formData.append("is_important", document.getElementById("isImportant").checked);
        }

        for (let i = 0; i < this.state.uploadList.length; i++) {
            formData.append("attachfileId", this.state.uploadList[i].id);
        }

        const conf = {
            method: "put",
            // headers: new Headers({ "Content-Type": "multipart/form-data" }),
            body: formData,
        };
        fetch(Common.backendUrl + "/board/api/board/" + this.props.match.params.board_id, conf).then((response) => {
            if (response.status > 400) {
                this.handleWarningModalOpen(t("board.edu.Post_correction_error"));
                return;
            } else if (response.status === 200) {
                this.handleWarningModalOpen(t("board.edu.The_post_has_been_modified"));
                this.setState({
                    warningLocation: true,
                });
                return;
            }
        });
    };

    get_board_data = (page) => {
        fetch(Common.backendUrl + "/board/api/board/" + this.props.match.params.board_id)
            .then((response) => {
                if (response.status > 400) {
                    console.log("error: no data");
                    return;
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    subject: data.subject,
                    content: data.content,
                    attachfiles: data.attachfiles,
                    isImportant: data.is_important,
                });

                smartEditor.EZCreator.createInIFrame({
                    oAppRef: oEditors,
                    elPlaceHolder: "se2",
                    sSkinURI: "/smart_editor2/SmartEditor2Skin.html",
                    htParams: {
                        bUseToolbar: true,
                        bUseVerticalResizer: true,
                        bUseModeChanger: true,
                        fOnBeforeUnload: function () {},
                    },
                    fCreator: "createSEditor2",
                });
            });
    };

    reloadAttachfiles = (page) => {
        fetch(Common.backendUrl + "/board/api/board/" + this.props.match.params.board_id)
            .then((response) => {
                if (response.status > 400) {
                    console.log("error: no data");
                    return;
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    attachfiles: data.attachfiles,
                    confirmFileModalOpen: false,
                });
            });
    };

    componentDidUpdate = () => {
        if (document.getElementById("isImportant")) {
            if (this.state.isImportant) {
                document.getElementById("isImportant").checked = true;
            } else {
                document.getElementById("isImportant").checked = false;
            }
        }
    };

    query_value = queryString.parse(this.props.location.search);

    componentDidMount() {
        this.get_board_data(this.props.match.params.board_id);
        window.scrollTo(0, 0);

        // smartEditor.EZCreator.createInIFrame({
        //     oAppRef: oEditors,
        //     elPlaceHolder: "se2",
        //     sSkinURI: "/smart_editor2/SmartEditor2Skin.html",
        //     htParams: {
        //         bUseToolbar: true,
        //         bUseVerticalResizer: true,
        //         bUseModeChanger: true,
        //     },
        //     fCreator: "createSEditor2"
        // });
    }

    pasteHTML = (e, url) => {
        e.preventDefault();
        let sHTML = `<img src=${url.split("?X-Amz-Algorithm")[0]} style="max-width: 90%;" alt='image' /> `;

        oEditors.getById.se2.exec("PASTE_HTML", [sHTML]);
    };

    pasteVideoUrl = (e) => {
        e.preventDefault();
        if (document.getElementById("videoURL").value !== "") {
            let sHTML = document.getElementById("videoURL").value;

            oEditors.getById.se2.exec("PASTE_HTML", [sHTML]);
        }
    };

    delTempFile = (e, tempfileId, filepath, index) => {
        e.preventDefault();

        let newAttachfiles = Array.from(this.state.newAttachfiles);
        newAttachfiles.splice(index, 1);
        let newUploadList = Array.from(this.state.uploadList);
        newUploadList.splice(index, 1);

        const formData = new FormData();
        formData.append("deleteTemp", true);
        formData.append("tempfilename", decodeURIComponent(filepath.split("?")[0].split("/")[filepath.split("?")[0].split("/").length - 1]));

        fetch(Common.backendUrl + `/board/api/board/${tempfileId}`, {
            // method: "DELETE",
            method: "PUT",
            body: formData,
        }).then((res) => {
            if (res.status === 204) {
                this.setState({
                    warningModalOpen: true,
                    warningText: "삭제되었습니다.",

                    newAttachfiles: newAttachfiles,
                    uploadList: newUploadList,
                });
            }
        });
    };

    delFile = (id) => {
        // e.preventDefault();
        const { t } = this.props;

        const formData = new FormData();
        formData.append("del_check", 1);
        const conf = {
            method: "put",
            body: formData,
        };

        fetch(Common.backendUrl + "/board/api/board/attachfile/" + id, conf).then((response) => {
            if (response.status > 400) {
                this.handleWarningModalOpen(t("board.edu.Attachment_deletion_error"));
                return;
            } else if (response.status === 200) {
                this.handleWarningModalOpen(t("board.edu.The_attachment_has_been_deleted"));
                this.setState({
                    delFile: true,
                });
                return;
            }
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
            warningLocation: false,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });

        if (this.state.warningLocation) {
            document.location.href = "/edu/content/" + this.props.match.params.board_id + this.state.queryStringStr;
        }

        if (this.state.delFile) {
            this.reloadAttachfiles(this.props.match.params.board_id);
        }
    };

    handleFileDelIsConfirm = () => {
        this.delFile(this.state.delFileId);
    };
    handleFileDelConfirmClose = () => {
        this.setState({
            confirmFileModalOpen: false,
            isConfirm: false,
        });
    };

    render() {
        let files = [];
        const { t } = this.props;

        for (let i = 0; i < this.state.attachfiles.length; i++) {
            files.push(
                <div key={i} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", margin: "5px 0px" }}>
                    #{i + 1}&nbsp;&nbsp;:&nbsp;&nbsp;
                    <a href={this.state.attachfiles[i].filepath} target="_blank">
                        {this.state.attachfiles[i].filename}
                    </a>
                    {/* <button className={styles.del_btn} onClick={(e) => this.delFile(this.state.attachfiles[i].id, e)}>DEL</button> */}
                    <button
                        className={styles.del_btn}
                        onKeyDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                            e.preventDefault();
                            this.setState({ confirmFileModalOpen: true, confirmText: "삭제하시겠습니까?", delFileId: this.state.attachfiles[i].id });
                        }}
                    >
                        DEL
                    </button>
                    {this.state.attachfiles[i].image_check === 1 ? (
                        <button onKeyDown={(e) => e.preventDefault()} style={{ marginLeft: "5px" }} onClick={(e) => this.pasteHTML(e, this.state.attachfiles[i].filepath)}>
                            이미지 삽입
                        </button>
                    ) : null}
                </div>
            );
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", width: "100vw", overflowX: "hidden" }}>
                <Topmenu />
                <div id="wrap_div" className={styles.wrap_div}>
                    <div className={styles.title_div}>
                        <h1>{t("board.edu.Qualification_Education_Guide")}</h1>
                        <hr style={{ border: 0, borderBottom: "1px solid #020202" }} />
                    </div>
                    <div className={styles.content_div}>
                        {sessionStorage.getItem("isAdmin") ? (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                                <Checkbox name="isImportant" id="isImportant" />
                                <p style={{ margin: 0, color: "#ff4e59", fontWeight: "600", marginLeft: "5px" }}>공지게시글로 등록</p>
                            </div>
                        ) : null}
                        <form style={{ width: "100%" }}>
                            <div>
                                <div style={{ display: "flex", width: "100%", padding: "10px 0" }}>
                                    <div style={{ width: "12%", borderRight: "1px solid #e2e2e2", textAlign: "center", fontFamily: "Noto Sans KR", fontSize: "1em" }}>{t("board.edu.Title")}</div>
                                    <div style={{ width: "88%", paddingLeft: "10px", textAlign: "left", fontFamily: "Noto Sans KR", fontSize: "1em" }}>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            style={{ width: "100%", background: "rgba(226, 226, 226, 0.5)", fontFamily: "Noto Sans KR", border: "0px", outline: "none", padding: "5px", color: "#020202" }}
                                            onChange={this.handleChange}
                                            value={this.state.subject}
                                            required
                                        ></input>
                                    </div>
                                </div>
                                <hr style={{ border: 0, borderBottom: "1px solid #e2e2e2" }} />
                            </div>
                            <div style={{ borderBottom: "1px solid #e2e2e2", display: "flex", padding: "10px 0", width: "100%" }}>
                                <div style={{ width: "12%", borderRight: "1px solid #e2e2e2", textAlign: "center" }}>{t("board.edu.Attachments")}</div>
                                <div style={{ width: "88%", paddingLeft: "10px" }}>
                                    <input type="text" style={{ width: "50%", marginRight: "5px" }} placeholder="동영상 URL을 입력해주세요." id="videoURL" />
                                    <button onClick={(e) => this.pasteVideoUrl(e)}>동영상 삽입</button>
                                    {files}
                                    <hr style={{ border: 0, borderBottom: "1px dashed #e2e2e2", margin: "5px" }} />
                                    <div>
                                        {t("board.edu.File_Attachments")} ({t("board.edu.Allow_multiple_files")})
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <input type="file" multiple onChange={this.fileChangedHandler} style={{ width: "100%", overflowX: "auto" }}></input>
                                        {this.state.uploadList ? (
                                            this.state.uploadList.map((file, index) => {
                                                return (
                                                    <div key={file.id} id={"tempfiles" + file.id} style={{ borderTop: "1px dashed #e2e2e2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", padding: "5px 0px" }}>
                                                        <p style={{ margin: 0, marginRight: "5px" }}>#{index + 1}: </p>
                                                        <p style={{ margin: 0 }}>{file.filename}</p>
                                                        <button className={styles.del_btn} onKeyDown={(e) => e.preventDefault()} onClick={(e) => this.delTempFile(e, file.id, file.filepath, index)}>
                                                            DEL
                                                        </button>
                                                        {file.image_check === 1 ? (
                                                            <button onKeyDown={(e) => e.preventDefault()} style={{ marginLeft: "5px" }} onClick={(e) => this.pasteHTML(e, file.filepath)}>
                                                                이미지 삽입
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div style={{ borderTop: "1px dashed #e2e2e2", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                                                <p style={{ margin: 0 }}>업로드된 파일이 없습니다.</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div id="se2_div" style={{ borderBottom: "1px solid #e2e2e2", padding: "10px", width: "100%" }}>
                                <textarea name="content" id="se2" style={{ width: "100%", minHeight: "600px", background: "rgba(226, 226, 226, 0.5)", padding: "5px", outline: "none", resize: "none" }} value={this.state.content} required></textarea>
                            </div>
                        </form>
                        <div style={{ paddingTop: "20px", textAlign: "center" }}>
                            <button className={styles.modify_btn} style={{ marginRight: "10px" }} onClick={this.handleSubmit}>
                                {t("board.edu.Apply")}
                            </button>
                            <button className={styles.modify_btn} onClick={() => window.history.back()}>
                                {t("board.edu.Cancel")}
                            </button>
                        </div>
                    </div>
                </div>
                {this.state.confirmFileModalOpen ? <ModalConfirm isOpen={this.state.confirmFileModalOpen} confirmText={this.state.confirmText} confirmMethd={this.handleFileDelIsConfirm} closeMethod={this.handleFileDelConfirmClose} /> : <></>}
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <div id="loader" style={{ display: "none", backgroundColor: "rgba(0, 0, 0, 0.2)", width: "100vw", height: "100vh", position: "fixed", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Loader style={{ display: "flex", fontFamily: "Noto Sans KR", fontSize: "30px" }}>uploading ...</Loader>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(Modify);
