import React, { Component } from 'react'
import {
    Button,
    Modal,
    Input,
    Form,
    Header,
    Dropdown,
} from 'semantic-ui-react'
import styles from "./ScoreModal.module.css"
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

export default class ScoreModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_open: false,
            set_options: [
                { key: 0, value: null, text: "set를 선택하세요." },
                { key: 1, value: "1set", text: "1set" },
                { key: 2, value: "2set", text: "2set" },
                { key: 3, value: "3set", text: "3set" },
            ],
            choiced_set: "",
            warningModalOpen: false,
            warningText: "",
        }
    }

    setChange = (e, { value }) => {
        this.setState({
            choiced_set: value,
        })
    }

    teamScoreBtnClick = (team) => {
        if (this.state.choiced_set) {
            window.open(`/competitions/score/${team}/${this.props.game_id}/${this.state.choiced_set}`)
        } else {
            this.handleWarningModalOpen("set를 먼저 선택하세요");
        }
    }

    scoreInputBtnClick = (team) => {
        if (this.state.choiced_set) {
            window.open(`/competitions/score/input/${team}/${this.props.game_id}/${this.state.choiced_set}`)
        } else {
            this.handleWarningModalOpen("set를 먼저 선택하세요");
        }
    }

    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        return (
            <Modal
                size={'tiny'}
                onClose={() => this.setState({ modal_open: false })}
                onOpen={() => this.setState({ modal_open: true })}
                open={this.state.modal_open}
                trigger={<Button primary style={{ margin: '0px 0px 0px 0px' }} size='mini'>설정</Button>}
            >
                <Modal.Header style={{ textAlign: "center", fontFamily: "Noto Sans KR" }}>점수관리</Modal.Header>
                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <Dropdown
                        className={styles.set_drop}
                        onChange={this.setChange}
                        options={this.state.set_options}
                        defaultValue={null}
                        // search={true} 
                        selection
                        placeholder='set를 선택하세요.'
                        fluid />

                    <div className={styles.score_div}>
                        <div onClick={() => this.teamScoreBtnClick("redteam")}>
                            <p>RED TEAM</p>
                            <p>전광판</p>
                        </div>
                        <div onClick={() => this.teamScoreBtnClick("blueteam")}>
                            <p>BLUE TEAM</p>
                            <p>전광판</p>
                        </div>
                    </div>
                    <div>
                        <Button positive onClick={() => this.scoreInputBtnClick("redteam")}>
                            RED TEAM 점수 입력하기
                        {/* onclick event에 점수 입력창으로 새 창 open 하기 */}
                        </Button>
                        <Button positive onClick={() => this.scoreInputBtnClick("blueteam")}>
                            BLUE TEAM 점수 입력하기
                        {/* onclick event에 점수 입력창으로 새 창 open 하기 */}
                        </Button>
                    </div>
                </Modal.Content>
                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Button negative onClick={() => this.setState({ modal_open: false })}>
                        닫기
                    </Button>
                </Modal.Actions>

                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </Modal>
        );
    }
}