import React, { Component } from "react";
import styles from "./AdminManager.module.css";
import TopMenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer.js";
import LeftMenu from "../left_menu/LeftMenu.js";

import CompetitionManager from "../competition/CompetitionManager.js";
import ManageArena from "./admin_menu/manage_arena/ManageArena.js";
import TeamManager from "./admin_menu/manage_team/TeamManager.js";
import CsvDownloadManager from "./admin_menu/csv_download/CsvDownloadManager";
import ProvinceRegionManager from "./admin_menu/manage_province_region/ProvinceRegionManager.js";
import NationalPlayerManager from "./admin_menu/manage_national/NationalPlayerManager.js";
import RegionalPlayerManager from "./admin_menu/manage_regional/RegionalPlayerManager.js";
import EduApplyManager from "./admin_menu/manage_edu_apply/EduApplyManager.js";
import ManageBanner from "./admin_menu/manage_banner/ManageBanner.js";
import PaymentManager from "./admin_menu/manage_payment/PaymentManager.js";

import TeamInfo from "./captain_menu/team_info/TeamInfo.js";
import TeamPlayer from "./captain_menu/team_players/TeamPlayers.js";
import HistoryManager from "./captain_menu/participation_history/HistoryManager.js";
import ChangeManager from "./captain_menu/change_team_player/ChangeManager.js";

import TeamRequestsManage from "./admin_menu/manage_requests/TeamRequestsManage.js";
import ApplyTeamRegistrationManager from "./admin_menu/manage_requests/ApplyTeamRegistrationManager.js";

class AdminManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leftSelectedMenu: "", //처음 기본으로 선택될 메뉴 정하기 ->  협회 관리자, 선수단장, 둘다 속할때 전부 다르게 해야할 듯.
        };
    }

    componentDidMount = () => {
        if (sessionStorage.getItem("leftMenu")) {
            this.setState({
                leftSelectedMenu: sessionStorage.getItem("leftMenu"),
            });
        } else if (sessionStorage.getItem("isAdmin") === "true") {
            this.setState({
                leftSelectedMenu: "대회 관리",
            });
        } else if (sessionStorage.getItem("level") === "210") {
            this.setState({
                leftSelectedMenu: "선수단 정보",
            });
        }
    };

    leftMenuChange = (selectedMenu) => {
        this.setState({
            leftSelectedMenu: selectedMenu,
        });
    };

    render() {
        const { leftSelectedMenu } = this.state;
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <TopMenu />
                <div className={styles.wrap_div}>
                    <LeftMenu menuChange={this.leftMenuChange} />
                    <div className={styles.content_div}>
                        {leftSelectedMenu === "대회 관리" ? <CompetitionManager /> : <></>}

                        {leftSelectedMenu === "경기장 관리" ? <ManageArena /> : <></>}
                        {leftSelectedMenu === "배너 관리" ? <ManageBanner /> : <></>}
                        {leftSelectedMenu === "결제 관리" ? <PaymentManager /> : <></>}
                        {leftSelectedMenu === "선수/선수단 관리" || leftSelectedMenu === "선수/선수단 검색" ? <TeamManager leftMenu={leftSelectedMenu} /> : <></>}
                        {leftSelectedMenu === "선수단목록 다운로드" || leftSelectedMenu === "선수단목록 다운로드" ? <CsvDownloadManager /> : <></>}
                        {leftSelectedMenu === "지회/지부 관리" ? <ProvinceRegionManager /> : <></>}
                        {leftSelectedMenu === "국가대표 관리" ? <NationalPlayerManager /> : <></>}
                        {leftSelectedMenu === "지역대표 관리" ? <RegionalPlayerManager /> : <></>}
                        {leftSelectedMenu === "자격교육 관리" ? <EduApplyManager /> : <></>}

                        {leftSelectedMenu === "선수단 정보" ? <TeamInfo /> : <></>}
                        {leftSelectedMenu === "선수 현황" ? <TeamPlayer /> : <></>}

                        {leftSelectedMenu === "대회 참가 이력" ? <HistoryManager /> : <></>}

                        {leftSelectedMenu === "선수상태 및 선수단 변경" ? <ChangeManager /> : <></>}

                        {leftSelectedMenu === "선수/선수단 변경 요청" ? <TeamRequestsManage /> : <></>}

                        {leftSelectedMenu === "선수단 창단 요청" ? <ApplyTeamRegistrationManager /> : <></>}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AdminManager;
