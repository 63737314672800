import React, { Component } from "react";
import styles from "./Content.module.css";
import BoardFilesMobile from "../../board/notice/BoardFilesMobile.js";
import BoardHeaderDesktop from "../../board/notice/BoardHeaderDesktop.js";
import Topmenu from "../../Topmenu/Topmenu.js";
import Footer from "../../footer/Footer.js";
import queryString from "query-string";
import nl2br from "react-newline-to-break";
import Common from "../../Common";
import { createMedia } from "@artsy/fresnel";
import { withTranslation } from "react-i18next";
import ModalChoiceOne from "../../warning_modal/ModalChoiceOne.js";
import ModalConfirm from "../../warning_modal/ModalConfirm.js";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
  },
});

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardData: {},
      editCommentIndex: null,
      editCommentCheck: false,
      editCommentContent: null,
      queryStringStr: null,
      warningModalOpen: false,
      warningText: "",
      editComment: false,
      warningLocationDelBoard: false,
      warningLocationPleaseLogin: false,

      confirmBoardModalOpen: false,
      confirmText: "",
      isConfirm: false,
      delCommentId: "",
    };
    const queryValue = queryString.parse(this.props.location.search);
    let queryStringStr = "";

    if (queryValue.pageNum) {
      queryStringStr = queryStringStr + "?pageNum=" + queryValue.pageNum;
    }

    if (queryValue.column) {
      queryStringStr = queryStringStr + "&column=" + queryValue.column;
    }

    if (queryValue.searchString) {
      queryStringStr =
        queryStringStr + "&searchString=" + queryValue.searchString;
    }

    this.state.queryStringStr = queryStringStr;
  }

  getBoardData = (page) => {
    fetch(Common.backendUrl + "/board/api/board/" + page)
      .then((response) => {
        if (response.status > 400) {
          console.log("error: no data");
          return;
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ boardData: data });
      });
  };

  componentDidMount() {
    this.getBoardData(this.props.match.params.board_id);
    window.scrollTo(0, 0);
  }

  isEmptyObject(param) {
    return Object.keys(param).length === 0 && param.constructor === Object;
  }

  reformTime(sourceTime) {
    let newDate = new Date(sourceTime);
    let [month, date, year] = newDate.toLocaleDateString("en-US").split("/");
    let timeStr = newDate.toLocaleTimeString();
    if (month < 10) month = "0" + month;
    if (date < 10) date = "0" + date;
    return [year, month, date].join("-") + "  " + timeStr;
  }

  reformTimeDate(sourceTime) {
    let newDate = new Date(sourceTime);
    let [month, date, year] = newDate.toLocaleDateString("en-US").split("/");
    return [year, month, date].join("-");
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { t } = this.props;
    // console.log(sessionStorage.getItem('id'))
    if (sessionStorage.getItem("id") === null) {
      this.handleWarningModalOpen(
        t("board.information_exchange.Please_log_in")
      );
      this.setState({
        warningLocationPleaseLogin: true,
      });
    } else if (document.getElementById("comment_content").value.length === 0) {
      this.handleWarningModalOpen(
        t("board.information_exchange.Please_write_a_comment")
      );
      return;
    }

    const formData = new FormData();
    formData.append("profile_id", sessionStorage.getItem("id"));
    formData.append("board_id", this.props.match.params.board_id);
    formData.append(
      "content",
      document.getElementById("comment_content").value
    );
    const conf = {
      method: "post",
      body: formData,
    };
    fetch(Common.backendUrl + "/board/api/board/comment/", conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(
            t("board.information_exchange.Comment_writing_error")
          );
          return;
        } else if (response.status === 201) {
          this.handleWarningModalOpen(
            t("board.information_exchange.Your_comment_has_been_posted")
          );
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  };

  handleClickCommentEditBtn(e, index, content) {
    // console.log(e.target.offsetTop);

    // window.scrollTo(e.target.offsetTop ,0)
    this.setState({
      editCommentIndex: index,
      editCommentCheck: true,
      editCommentContent: content,
    });
  }

  editComment(id) {
    const { t } = this.props;
    const formData = new FormData();
    formData.append("id", id);
    formData.append(
      "content",
      document.getElementById("editCommentContent").value
    );
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(Common.backendUrl + "/board/api/board/comment/" + id, conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(
            t("board.information_exchange.Comment_correction_error")
          );
          return;
        } else if (response.status === 200) {
          this.handleWarningModalOpen(
            t("board.information_exchange.The_fix_has_been_applied")
          );
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  }

  delComment(id) {
    const { t } = this.props;

    const formData = new FormData();
    formData.append("del_check", 1);
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(Common.backendUrl + "/board/api/board/comment/" + id, conf).then(
      (response) => {
        if (response.status > 400) {
          this.handleWarningModalOpen(
            t("board.information_exchange.Comment_deletion_error")
          );
          return;
        } else if (response.status === 200) {
          this.handleWarningModalOpen(
            t("board.information_exchange.The_comment_has_been_deleted")
          );
          this.setState({
            editComment: true,
          });
          return;
        }
      }
    );
  }

  delBoard() {
    const { t } = this.props;

    const formData = new FormData();
    formData.append("del_check", 1);
    const conf = {
      method: "put",
      body: formData,
    };

    fetch(
      Common.backendUrl +
        "/board/api/board/" +
        this.props.match.params.board_id,
      conf
    ).then((response) => {
      if (response.status > 400) {
        this.handleWarningModalOpen(
          t("board.information_exchange.Post_deletion_error")
        );
        return;
      } else if (response.status === 200) {
        this.handleWarningModalOpen(
          t("board.information_exchange.The_post_has_been_deleted")
        );

        this.setState({
          warningLocationDelBoard: true,
        });
        return;
      }
    });
  }

  handleWarningModalOpen = (text) => {
    this.setState({
      warningModalOpen: true,
      warningText: text,
      warningLocationDelBoard: false,
      warningLocationPleaseLogin: false,
    });
  };

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });

    if (this.state.warningLocationDelBoard) {
      document.location.href =
        "/information_exchange/list" + this.state.queryStringStr;
    }

    if (this.state.warningLocationPleaseLogin) {
      window.location.href = "/login";
    }

    if (this.state.editComment) {
      document.location.reload();
    }
  };

  handleBoardDelIsConfirm = () => {
    this.delBoard();
  };

  handleBoardDelConfirmClose = () => {
    this.setState({
      confirmBoardModalOpen: false,
      isConfirm: false,
    });
  };

  handleCommentDelIsConfirm = () => {
    this.delComment(this.state.delCommentId);
  };
  handleCommentDelConfirmClose = () => {
    this.setState({
      confirmCommentModalOpen: false,
      isConfirm: false,
    });
  };

  render() {
    let boardData = this.state.boardData;
    let files = [];
    let comments = [];
    let board_writer = "";
    let board_id = 0;
    let image_files = [];
    const { t } = this.props;

    if (boardData.profile != undefined) {
      board_writer = boardData.profile.member_text;
      board_id = boardData.profile.id;
    }

    if (!this.isEmptyObject(boardData)) {
      let attachfiles = boardData.attachfiles;
      let comment = boardData.comments;

      for (let i = 0; i < attachfiles.length; i++) {
        files.push(
          <div key={i} style={{ lineHeight: "29px" }}>
            &nbsp;&nbsp;#{i + 1}&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;
            <a href={attachfiles[i].filepath} target="_blank">
              {attachfiles[i].filename}
            </a>
          </div>
        );
        // if (attachfiles[i].image_check === 1) {
        //     image_files.push(attachfiles[i].filepath)
        // }
      }

      for (let i = 0; i < comment.length; i++) {
        let commentInnerEl;

        if (
          this.state.editCommentCheck === true &&
          this.state.editCommentIndex === i
        ) {
          commentInnerEl = (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px 10px",
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: "bold",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    {comment[i].profile.member_text}
                  </p>
                  <span
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      fontFamily: "Noto Sans KR",
                      fontSize: "10px",
                      color: "#BBBBBB",
                    }}
                    title={this.reformTime(comment[i].created_date)}
                  >
                    {this.reformTimeDate(comment[i].created_date)}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={styles.comment_btn}
                    onKeyDown={(e) => e.preventDefault()}
                    style={{ marginRight: "10px" }}
                    onClick={() => this.editComment(comment[i].id)}
                  >
                    {t("board.information_exchange.Apply")}
                  </button>
                  <button
                    className={styles.comment_btn}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={() => this.setState({ editCommentCheck: false })}
                  >
                    {t("board.information_exchange.Cancel")}
                  </button>
                </div>
              </div>
              <hr
                style={{
                  border: 0,
                  borderBottom: "1px dashed #e2e2e2",
                  margin: "5px 0px",
                }}
              />
              <div style={{ flex: 8 }}>
                <textarea
                  autoFocus
                  style={{
                    width: "100%",
                    height: "80px",
                    fontFamily: "Noto Sans KR",
                    fontSize: "1em",
                    color: "#020202",
                    background: "rgba(255, 255, 255, 0.6)",
                    outline: "none",
                    resize: "none",
                  }}
                  defaultValue={comment[i].content}
                  name="editCommentContent"
                  id="editCommentContent"
                ></textarea>
              </div>
            </div>
          );
        } else {
          commentInnerEl = (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "10px 10px",
                borderBottom: "1px solid #e2e2e2",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Noto Sans KR",
                      fontWeight: "bold",
                      fontSize: "12px",
                      margin: "0px",
                    }}
                  >
                    {comment[i].profile.member_text}
                  </p>
                  <span
                    style={{
                      textAlign: "left",
                      marginLeft: "10px",
                      fontFamily: "Noto Sans KR",
                      fontSize: "10px",
                      color: "#BBBBBB",
                    }}
                    title={this.reformTime(comment[i].created_date)}
                  >
                    {this.reformTimeDate(comment[i].created_date)}
                  </span>
                </div>
                {comment[i].profile.id ===
                parseInt(sessionStorage.getItem("id")) ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={styles.comment_btn}
                      style={{ marginRight: "10px" }}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={(e) =>
                        this.handleClickCommentEditBtn(e, i, comment[i].content)
                      }
                    >
                      {t("board.information_exchange.Modify")}
                    </button>
                    <button
                      className={styles.comment_btn}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() =>
                        this.setState({
                          confirmCommentModalOpen: true,
                          confirmText: "삭제하시겠습니까?",
                          delCommentId: comment[i].id,
                        })
                      }
                    >
                      {t("board.information_exchange.Delete")}
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <hr
                style={{
                  border: 0,
                  borderBottom: "1px dashed #e2e2e2",
                  margin: "5px 0px",
                }}
              />
              <div style={{ flex: 8 }}>{nl2br(comment[i].content)}</div>
            </div>
          );
        }
        let commentDiv = (
          <div
            key={i}
            style={{
              display: "flex",
              width: "100%",
              padding: "15px 10px",
              borderBottom: "1px dashed #e2e2e2",
            }}
          >
            <div style={{ flex: 1 }}>{comment[i].profile.member_text}</div>
            {commentInnerEl}
          </div>
        );
        comments.push(commentInnerEl);
      }
    }

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Topmenu />
        <div className={styles.wrap_div}>
          <div className={styles.title_div}>
            <h1>{t("board.information_exchange.title")}</h1>
            <hr style={{ border: 0, borderBottom: "1px solid #020202" }} />
          </div>
          <div className={styles.content_div}>
            <MediaContextProvider>
              <Media at="mobile">
                <BoardHeaderMobile
                  subject={this.state.boardData.subject}
                  writer={board_writer}
                  created_date={this.reformTime(boardData.created_date)}
                  updated_date={this.reformTime(boardData.updated_date)}
                  files={files}
                  isImportent={this.state.boardData.is_important}
                />
              </Media>
              <Media greaterThan="mobile">
                <BoardHeaderDesktop
                  subject={this.state.boardData.subject}
                  writer={board_writer}
                  created_date={this.reformTime(boardData.created_date)}
                  updated_date={this.reformTime(boardData.updated_date)}
                  files={files}
                  isImportent={this.state.boardData.is_important}
                />
              </Media>
            </MediaContextProvider>
            <div>
              <div className={styles.board_content_div}>
                {/* {image_files.length > 0 ?
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                        {image_files.map((image_file) =>
                                            <img className={styles.img_preview} src={image_file} alt='board image' />
                                        )}
                                    </div>
                                    :
                                    <></>
                                } */}
                {/* <p style={{ textAlign: "left" }}>{nl2br(boardData.content)}</p> */}
                {/* <p style={{ textAlign: "left" }}>{nl2br(boardData.content)}</p> */}
                <div
                  className={styles.video_iframe}
                  dangerouslySetInnerHTML={{ __html: boardData.content }}
                ></div>
              </div>
              <hr style={{ border: 0, borderBottom: "1px solid #e2e2e2" }} />
              <MediaContextProvider>
                <Media at="mobile">
                  <BoardFilesMobile files={files} />
                  <hr
                    style={{ border: 0, borderBottom: "1px solid #e2e2e2" }}
                  />
                </Media>
              </MediaContextProvider>
            </div>
            {comments}
            <form onSubmit={this.handleSubmit} style={{ width: "100%" }}>
              <div
                style={{ padding: "20px 10px", width: "100%", display: "flex" }}
              >
                <div style={{ flex: 9 }}>
                  <textarea
                    style={{
                      width: "100%",
                      height: "80px",
                      color: "#020202",
                      fontFamily: "Noto Sans KR",
                      outline: "none",
                      resize: "none",
                      background: "rgba(255, 255, 255, 0.6)",
                      overflow: "hidden",
                    }}
                    name="comment_content"
                    id="comment_content"
                  ></textarea>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <button
                    className={styles.submit_btn}
                    type="submit"
                    onKeyDown={(e) => e.preventDefault()}
                    style={{
                      width: "80px",
                      height: "80px",
                      color: "#000000",
                      background: "rgba(226, 226, 226, 0.9)",
                      borderRadius: "3px",
                      outline: "none",
                      border: "0px",
                      marginLeft: "10px",
                    }}
                  >
                    {t("board.information_exchange.Enrollment")}
                  </button>
                </div>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href={"/information_exchange/list" + this.state.queryStringStr}
                style={{
                  color: "#020202",
                  fontWeight: "bold",
                  fontSize: "15px",
                  fontFamily: "Noto Sans KR",
                }}
              >
                {t("board.information_exchange.List")}
              </a>
              {parseInt(sessionStorage.getItem("id")) === board_id ||
              sessionStorage.getItem("isAdmin") ? (
                <>
                  <a
                    onKeyDown={(e) => e.preventDefault()}
                    className={styles.submit_btn}
                    href={
                      "/information_exchange/modify/" +
                      this.props.match.params.board_id +
                      this.state.queryStringStr
                    }
                    style={{
                      color: "#020202",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontFamily: "Noto Sans KR",
                      marginLeft: "20px",
                    }}
                  >
                    {t("board.information_exchange.Modify")}
                  </a>
                  <a
                    onKeyDown={(e) => e.preventDefault()}
                    className={styles.submit_btn}
                    style={{
                      marginLeft: "20px",
                      color: "#020202",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontFamily: "Noto Sans KR",
                    }}
                    onClick={() =>
                      this.setState({
                        confirmBoardModalOpen: true,
                        confirmText: "삭제하시겠습니까?",
                      })
                    }
                  >
                    {t("board.information_exchange.Delete")}
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {this.state.confirmBoardModalOpen ? (
          <ModalConfirm
            isOpen={this.state.confirmBoardModalOpen}
            confirmText={this.state.confirmText}
            confirmMethd={this.handleBoardDelIsConfirm}
            closeMethod={this.handleBoardDelConfirmClose}
          />
        ) : (
          <></>
        )}
        {this.state.confirmCommentModalOpen ? (
          <ModalConfirm
            isOpen={this.state.confirmCommentModalOpen}
            confirmText={this.state.confirmText}
            confirmMethd={this.handleCommentDelIsConfirm}
            closeMethod={this.handleCommentDelConfirmClose}
          />
        ) : (
          <></>
        )}
        <ModalChoiceOne
          isOpen={this.state.warningModalOpen}
          warningText={this.state.warningText}
          closeMethod={this.handleWarningModalClose}
        />
        <Footer />
      </div>
    );
  }
}

class BoardHeaderMobile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#E4EAF8",
          borderBottom: "1px solid #000000",
          padding: "5px",
        }}
      >
        {this.props.isImportent ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              fontFamily: "Noto Sans KR",
              fontSize: "16px",
            }}
          >
            <span
              style={{
                fontWeight: "800",
                marginRight: "5px",
                display: "inline-block",
                color: "#ff4e59",
                fontSize: "10px",
                backgroundColor: "#ffe3e4",
                border: "1px solid #ffc6c9",
                borderRadius: "3px",
                padding: "0px 3px",
              }}
            >
              공지
            </span>
            {this.props.subject}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              fontFamily: "Noto Sans KR",
              fontSize: "16px",
            }}
          >
            {" "}
            {this.props.subject}
          </div>
        )}
        <div
          style={{
            width: "100%",
            textAlign: "right",
            fontFamily: "Noto Sans KR",
            fontSize: "12px",
          }}
        >
          {this.props.writer}
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            fontFamily: "Noto Sans KR",
            fontSize: "12px",
          }}
        >
          {this.props.created_date}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Content);
