import React, { Component } from "react";
import MemberUpdateModalForm from "../manage_team/MemberUpdateModalForm.js";
import MemberModalForm from "../manage_team/MemberModalForm.js";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import { Header, Segment, Button, Table, TableRow, Image, Grid } from "semantic-ui-react";
import Common from "../../../Common";

class NationalPlayerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText: "",
            profiles: "",
        };
    }

    componentDidMount = () => {
        this.getNationalProfileData();
    };

    getNationalProfileData = () => {
        fetch(Common.backendUrl + "/api/player/national/profile")
            .then((res) => {
                if (res.status > 400) {
                    this.handleWarningModalOpen("error❗❗");
                }
                return res.json();
            })
            .then((profiles) => {
                this.setState({
                    profiles,
                });
            });
    };

    reloadPage = () => {
        this.getNationalProfileData();
    };

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let day = parts[2].split("T");

            let d = parts[0] + "-" + parts[1] + "-" + day[0];
            return d;
        } else {
            return;
        }
    }

    render() {
        let list = new Array(1);
        let match_type_str;
        if (this.state.profiles !== "") {
            list[0] = this.state.profiles.map((info, index) =>
                index === this.state.profiles.length - 1 ? (
                    <TableRow key={info.id}>
                        <Table.Cell textAlign="center">
                            <Image src={info.image_file} size="tiny" verticalAlign="middle" />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {info.member_text}
                            <br />({info.user_site_name})
                        </Table.Cell>
                        <Table.Cell textAlign="center">{info.member_id}</Table.Cell>
                        <Table.Cell textAlign="center">{this.toDate(info.birth_date)}</Table.Cell>
                        {info.member_position !== null && <Table.Cell textAlign="center">{info.member_position.position_text}</Table.Cell>}
                        {info.member_position === null && <Table.Cell textAlign="center">{info.member_position}</Table.Cell>}
                        <Table.Cell textAlign="center">{info.member_phone}</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_email}</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_rfid}</Table.Cell>
                        {info.member_authlevel !== null && <Table.Cell textAlign="center">{info.member_authlevel.auth_text}</Table.Cell>}
                        {info.member_authlevel === null && <Table.Cell textAlign="center">{info.member_authlevel}</Table.Cell>}
                        <Table.Cell textAlign="center">{info.cert_grade}급</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_authlevel_time}초</Table.Cell>
                        <Table.Cell textAlign="center">{info.isAdmin ? "✅" : "❌"}</Table.Cell>
                        <Table.Cell textAlign="center">
                            <MemberUpdateModalForm
                                province_id={info.team.region.province.id}
                                region_id={info.team.region.id}
                                team_id={info.team.id}
                                member_text={info.member_text}
                                member_id={info.member_id}
                                birth_date={info.birth_date}
                                member_position={info.member_position}
                                member_phone={info.member_phone}
                                member_email={info.member_email}
                                member_rfid={info.member_rfid}
                                authlevel_id={info.member_authlevel}
                                authlevel_time={info.member_authlevel_time}
                                cert_grade={info.cert_grade}
                                kind={info.kind}
                                use_value={info.use_value}
                                image={info.image}
                                image_file={info.image_file}
                                id={info.id}
                                national={info.national_player}
                                regional={info.regional_player}
                                isAdmin={info.isAdmin}
                                reloadPage={this.reloadPage}
                            />
                        </Table.Cell>
                    </TableRow>
                ) : (
                    <TableRow key={info.id}>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            <Image src={info.image_file} size="tiny" verticalAlign="middle" />
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_text}
                            <br />({info.user_site_name})
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_id}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {this.toDate(info.birth_date)}
                        </Table.Cell>
                        {info.member_position !== null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_position.position_text}
                            </Table.Cell>
                        )}
                        {info.member_position === null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_position}
                            </Table.Cell>
                        )}
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_phone}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_email}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_rfid}
                        </Table.Cell>
                        {info.member_authlevel !== null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_authlevel.auth_text}
                            </Table.Cell>
                        )}
                        {info.member_authlevel === null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_authlevel}
                            </Table.Cell>
                        )}
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.cert_grade}급
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_authlevel_time}초
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.isAdmin ? "✅" : "❌"}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            <MemberUpdateModalForm
                                province_id={info.team.region.province.id}
                                region_id={info.team.region.id}
                                team_id={info.team.id}
                                member_text={info.member_text}
                                member_id={info.member_id}
                                birth_date={info.birth_date}
                                member_position={info.member_position}
                                member_phone={info.member_phone}
                                member_email={info.member_email}
                                member_rfid={info.member_rfid}
                                authlevel_id={info.member_authlevel}
                                authlevel_time={info.member_authlevel_time}
                                cert_grade={info.cert_grade}
                                kind={info.kind}
                                use_value={info.use_value}
                                image={info.image}
                                image_file={info.image_file}
                                id={info.id}
                                national={info.national_player}
                                regional={info.regional_player}
                                isAdmin={info.isAdmin}
                                reloadPage={this.reloadPage}
                            />
                        </Table.Cell>
                    </TableRow>
                )
            );
        }
        return (
            <div>
                <Segment attached="top" clearing secondary style={{ width: "100%", border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        국가대표 목록
                    </Header>
                </Segment>
                <Segment attached style={{ display: "block", width: "100%", overflow: "auto", border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    사진
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    이름(id)
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    선수자격번호
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    생년월일
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    포지션
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    전화번호
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    이메일
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    RFID
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    레벨
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    급수
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    기록
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    관리자
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    수정
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>{list[0]}</Table.Body>
                    </Table>
                </Segment>

                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

export default NationalPlayerManager;
