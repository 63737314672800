import React, { Component } from "react";
import { Form, Grid, Header, Confirm, Segment } from 'semantic-ui-react'
import styles from "./FindPassword.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer"
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import {withTranslation} from 'react-i18next';

class SendEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_password: "",
      member_password_confirm: "",
      member_token: "",
      open: false,
      warningModalOpen: false,
      warningText: "",
      warningLocation: false,
    };
  }

  handleMemberPasswordChange = (e, { value }) => {
    this.setState({ member_password: value });
  };

  handleMemberPasswordConfirmChange = (e, { value }) => {
    this.setState({ member_password_confirm: value });
  };

  handleMemberTokenChange = (e, { value }) => {
    this.setState({ member_token: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { t } = this.props;
    let form_data = new FormData();

    if (this.state.member_token === "") {
      this.handleWarningModalOpen(t('find_password.SendMail.Authentication_key_Alert'));
      return;
    }

    if (this.state.member_password === "") {
      this.handleWarningModalOpen(t('find_password.SendMail.Password_Alert'));
      return;
    }

    if (this.state.member_password_confirm === "") {
      this.handleWarningModalOpen(t('find_password.SendMail.Password_confirmation_Alert'));
      return;
    }

    if (this.state.member_password !== this.state.member_password_confirm) {
      this.handleWarningModalOpen(t('find_password.SendMail.Password_match_alert'));
      return;
    }

    form_data.append('token', this.state.member_token);
    form_data.append('password', this.state.member_password);

    fetch( Common.backendUrl + '/api/password_reset/confirm/', {
      method: 'POST',
      body: form_data
    })

      .then(response => {
        if (response.status == 200) {
          this.handleWarningModalOpen(t('find_password.SendMail.Success'));
          this.setState({
            warningLocation: true,
          })
        } else if (response.status === 400) {
          this.handleWarningModalOpen(t('find_password.SendMail.Fail_Password'));
        } else if (response.status === 404) {
          this.handleWarningModalOpen(t('find_password.SendMail.Fail_Auth'))
        }
      })
  };

  handleWarningModalOpen = (text) => {
    this.setState({
      warningLocation: false,
      warningModalOpen: true,
      warningText: text,
    })
  }


  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    })
    if (this.state.warningLocation) {
      document.location.href = '/login/';
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Topmenu />
        <div style={{minHeight: "1080px", backgroundColor: "red", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}} >
        <div className={styles.stars}></div>
        <div className={styles.twinkling}></div>
        <div className={styles.clouds}></div>

        <div className={styles.login_form_wrap}>
          <Grid textAlign='center' style={{ height: '1090px' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
              <Form size='large'>
                <Segment textAlign='left' className={styles.login_form_div}>
                  <Header as='h2' inverted color='grey' textAlign='center' style={{ marginBottom: "50px" }}>
                  {t('find_password.SendMail.Find_Password')}
                    </Header>
                  <label className={styles.label}><span>{t('find_password.SendMail.Authentication_key')}</span></label>
                  <Form.Input
                    fluid
                    icon='mail'
                    iconPosition='left'
                    placeholder={t('find_password.SendMail.Auth_placeholder')}
                    onChange={this.handleMemberTokenChange}
                  />
                  <label className={styles.label}><span>{t('find_password.SendMail.New_password')}</span></label>
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder={t('find_password.SendMail.password_placeholder')}
                    maxLength='16'
                    minLength='8'
                    type='password'
                    onChange={this.handleMemberPasswordChange}
                  />
                  <label className={styles.label}><span>{t('find_password.SendMail.New_password_confirmation')}</span></label>
                  <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder={t('find_password.SendMail.password_placeholder')}
                    maxLength='16'
                    minLength='8'
                    type='password'
                    onChange={this.handleMemberPasswordConfirmChange}
                  />
                  <a className={`${styles.style_a} ${styles.mouse_hover} `} onClick={this.handleSubmit} style={{ textAlign: 'center', width: "100%" }} >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                      비밀번호 변경
                    </a>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        </div>
        <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
        <Footer styles={{ backgroundColor: "red" }} />
        </div>
      </>
    );
  }
}

export default withTranslation()(SendEmail);