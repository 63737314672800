import React, { Component } from 'react'
import { Button, Header, Icon, Modal, Divider } from 'semantic-ui-react'

export default class ModalTeamRequest extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      this.props.requestContent.length !== 0 ?
        <Modal
          open={this.props.isOpen}
          size='tiny'
        >
          <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
          <h1 style={{ fontFamily: "Noto Sans KR", fontSize: "22px", fontWeight: "bold" }}>요청사항</h1>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", fontWeight: "bold" }}>
                            <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["id"]}</p>
                            <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["create_at"].split("T")[0]}</p>
                        </div>
                        <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "normal", margin: "0px", fontWeight: "bold" }}>{this.props.requestContent[0]["select_menu"]} 신청</p>
          </Header>
          <Divider style={{ margin: "0px" }} />
          <Modal.Content style={{ padding: "20px", height: "200px", overflowY: "auto" }}>
            <p style={{ textAlign: "left", fontSize: "15px", fontFamily: "Noto Sans KR", fontWeight: "bold" }}>{this.props.requestContent[0]["content"]}</p>
          </Modal.Content>
          <Modal.Actions style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Button color='red' inverted onClick={() => this.props.closeMethod()}>
              <Icon name='remove' /> 닫기
            </Button>
          </Modal.Actions>
        </Modal>
        :
        <></>

    )
  }
}

