import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import { Pagination } from 'semantic-ui-react'
import Common from "../../Common.js";
import styles from './List.module.css'

class MobileApp extends Component {
    constructor(props) {
        super(props);
    }

    // componentDidUpdate = () => {
    //     document.querySelector("#navPage .active").style.fontSize = "13px"
    //     document.querySelector("#navPage .active").style.fontWeight = "bold"
    // }

    navOnClick = (e, page) => {
        // console.log(page)
        let url = Common.paginationUrl + "/free/list/?pageNum=" + page;
        // let url = Common.backendUrl + "/notice/list/?pageNum=" + page;
        if (this.props.column) {
            url = url + "&column=" + this.props.column;
        }
        if (this.props.searchString) {
            url = url + "&searchString=" + this.props.searchString;
        }
        window.location.href = url;
    }

    render() {
        const { t } = this.props;

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    {this.props.boardList}
                </div>
                <div style={{ marginTop: "10px", textAlign: "left", width: "100%" }}>
                    <button className={styles.board_btn} onClick={() => { document.location.href = '/free/list'; document.location.reload() }} style={{ padding: "0px 11px" }}>{t('board.free.List')}</button>
                    <button className={styles.board_btn} onClick={() => { document.location.href = '/free/write'; }} style={{ marginLeft: "20px" }}>{t('board.free.Writing')}</button>
                </div>
                <div style={{ marginTop: "10px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1200px" }}>
                    <div style={{flex: 1}}></div>
                    <div style={{ flex: 3, textAlign: "center" }}>
                        <Pagination
                            id="navPage"
                            className={styles.board_pagination}
                            boundaryRange={1}
                            activePage={this.props.pageNum}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={5}
                            totalPages={this.props.pageCount}
                            onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                        />
                    </div>
                    <div style={{ flex: 1 }}></div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(MobileApp);