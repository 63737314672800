import React, { Component } from 'react';
import Topmenu from '../Topmenu/Topmenu.js';
import Footer from "../footer/Footer.js";
import styles from './AdultSelect.module.css';

class AdultSelect extends Component {

    handleClick = (e) => {
        e.preventDefault();
        sessionStorage.setItem('adult', true)
        window.location.href = '/privacypolicy'
    }

    handleKidsClick = (e) => {
        e.preventDefault();
        sessionStorage.setItem('adult', false)
        window.location.href = '/privacypolicy'
    }

    render() {
        return (
            <>
                <Topmenu />
                <div style={{ minHeight: "1000px", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "hidden" }}>
                    <div style={{ minHeight: "1000px", height: "100vh" }} className={styles.stars}></div>
                    <div style={{ minHeight: "1000px", height: "100vh" }} className={styles.twinkling}></div>

                    <div className={styles.wrap_div}>
                        <div className={styles.content_div}>
                            <p>회원가입 방식 선택</p>
                            <div>
                                <div className={styles.memberselect_div}>
                                    <img width="100px" src='/images/login/adult.png' alt='adult' />
                                    <p>(일반회원)</p>
                                    <p>만 14세 이상 개인</p>
                                    <a className={styles.style_a} onClick={(e) => this.handleClick(e)} >
                                        회원가입
                                </a>
                                </div>
                                <div className={styles.memberselect_div}>
                                <img width="100px" src='/images/login/kids.png' alt='kids' />
                                    <p>(어린이 회원)</p>
                                    <p>만 14세 미만 개인</p>
                                    <a className={styles.style_a} onClick={(e) => this.handleKidsClick(e)} >
                                        회원가입
                                </a>
                                </div>
                            </div>
                            <a className={styles.style_a1} onClick={() => window.location.href = '/login'} >
                                    취소
                                </a>
                        </div>
                    </div>

                </div>
                <Footer />
            </>
        );
    }
}

export default AdultSelect;