import React, { Component } from 'react';
import Topmenu from "../Topmenu/Topmenu"
import Footer from "../footer/Footer"
import Common from '../Common.js'
import { createMedia } from '@artsy/fresnel'

const naverMap = window.naver;
const mapOptions = {
    center: new naverMap.maps.LatLng(36.45510493715033, 128.01426511227197),
    zoom: 8
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})


class IntroArena extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.getArenaData();
    }

    getArenaData = () => {
        fetch(Common.backendUrl + `/api/arena/list/`)
            .then(res => {
                if (res.status > 400) {
                    alert("error");
                }
                return res.json();
            })
            .then(data => {
                const map = new naverMap.maps.Map(document.getElementById('map'), mapOptions)
                let lat = ""
                let lng = ""
                let clustering = new Array();

                data.map(arena => {
                    lat = parseFloat(arena.address_latLng.split(',')[0])
                    lng = parseFloat(arena.address_latLng.split(',')[1])
                    const latLng = new naverMap.maps.LatLng(lat, lng);
                    const clickCenter = new naverMap.maps.LatLng(lat+0.1, lng);
                    let arenaText = ''

                    if (arena.stadium_img !== null) {
                        arenaText = "<div style='width:350px;border-radius: 8px; background-color: rgb(255, 255, 255); box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px; overflow: hidden; text-align: left;'>" +
                        `<img src=${arena.stadium_img} width='100%' height='230px'>` +
                        "<div style='padding: 13px;padding-top:5px;'>" +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 600; font-style: normal;line-height:160%'>${arena.name}</p>` +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 400; font-style: normal;line-height:160%'>${arena.address}</p>` +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 400; font-style: normal;'>${arena.phone_text}</p>` +
                        "</div>" +
                        "</div>";
                    } else {
                        arenaText = "<div style='width:350px;border-radius: 8px; background-color: rgb(255, 255, 255); box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px; overflow: hidden; text-align: left;'>" +
                        "<div style='padding: 13px;padding-top:5px;'>" +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 600; font-style: normal;line-height:160%'>${arena.name}</p>` +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 400; font-style: normal;line-height:160%'>${arena.address}</p>` +
                        `<p style='font-family: Noto Sans KR; font-size: 11pt; margin: 0; font-weight: 400; font-style: normal;'>${arena.phone_text}</p>` +
                        "</div>" +
                        "</div>";
                    }

                    

                    let marker = new naverMap.maps.Marker({
                        position: latLng,
                        map: map,
                        icon: {
                            // content: "<div style=' border: 1px solid #0679E7 !important; display: flex; flex-direction: row; align-items: center; justify-content: center; background-color: #FFFFFF; padding: 5px; border-radius: 28px; box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px;' >" +
                            // // "<div></div>" +
                            // "<img src='/images/intro/mjMarker2.png' />" +
                            // `<p style='white-space:nowrap; font-family: Noto Sans KR; color: #222222; font-weight: 600;'>${arena.name}</p>` +
                            // "</div>",
                            content: "<div style=' border: 1px solid blue !important; display: flex; flex-direction: row; align-items: center; justify-content: center; background-color: red; background-color: #FFFFFF; padding: 5px; padding-right: 10px; border-radius: 28px; box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px;' >" +
                                "<div style='width: 15px; height: 15px; border: 1px solid blue; border-radius: 50%; margin-right: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;'>" +
                                "<div style='width: 0; height: 0; border-left: 6px solid transparent; border-right: 5px solid transparent; border-top: 10px solid blue; '></div>" +
                                "</div>" +
                                `<p style='white-space:nowrap; font-family: Noto Sans KR; color: #222222; font-weight: 600;'>${arena.name}</p>` +
                                "</div>",
                        }
                    });

                    let infoWindow = new naverMap.maps.InfoWindow({
                        content: arenaText,
                        boxSizing: "content-box",
                        backgroundColor: "rgba(0,0,0,0)",
                        borderWidth: 0,
                        boxShadow: "rgba(0, 0, 0, 0.28) 0px 8px 28px",
                        anchorSize: "none",
                        pixelOffset: new naverMap.maps.Point(0, -10),
                    })
                    naverMap.maps.Event.addListener(marker, "click", function (e) {
                        if (infoWindow.getMap()) {
                            infoWindow.close();
                        } else {
                            // map.setCenter(latLng)
                            map.setCenter(clickCenter)
                            infoWindow.open(map, marker)
                        }
                    })
                    naverMap.maps.Event.addListener(map, 'click', function(e) {
                        if (infoWindow.getMap()){
                            infoWindow.close();
                        }
                    })

                    clustering.push(marker)
                })

                // console.log(clustering)

                let htmlMarker1 = {
                    content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/images/arena/cluster-marker-1.png);background-size:contain;"></div>',
                    size: naverMap.maps.Size(40, 40),
                    anchor: naverMap.maps.Point(20, 20)
                },
                htmlMarker2 = {
                    content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/images/arena/cluster-marker-2.png);background-size:contain;"></div>',
                    size: naverMap.maps.Size(40, 40),
                    anchor: naverMap.maps.Point(20, 20)
                },
                htmlMarker3 = {
                    content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/images/arena/cluster-marker-3.png);background-size:contain;"></div>',
                    size: naverMap.maps.Size(40, 40),
                    anchor: naverMap.maps.Point(20, 20)
                },
                htmlMarker4 = {
                    content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/images/arena/cluster-marker-4.png);background-size:contain;"></div>',
                    size: naverMap.maps.Size(40, 40),
                    anchor: naverMap.maps.Point(20, 20)
                },
                htmlMarker5 = {
                    content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:url(/images/arena/cluster-marker-5.png);background-size:contain;"></div>',
                    size: naverMap.maps.Size(40, 40),
                    anchor: naverMap.maps.Point(20, 20)
                }
                // htmlMarker6 = {
                //     content: "<div style=' border: 1px solid blue !important; display: flex; flex-direction: row; align-items: center; justify-content: center; background-color: red; background-color: #FFFFFF; padding: 5px; padding-right: 10px; border-radius: 28px; box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px; position: relative;' >" +
                //     "<div style=' font-size: 10px; font-family: Noto Sans KR; font-weight: bold; position: absolute; top: -12px; right: -10px; border: 1px solid blue !important; display: flex; flex-direction: row; align-items: center; justify-content: center; background-color: red; background-color: #FFFFFF; border-radius: 28px; padding: 0px 5px;'></div>" +
                //     "<div style='width: 15px; height: 15px; border: 1px solid blue; border-radius: 50%; margin-right: 5px; display: flex; flex-direction: column; align-items: center; justify-content: center;'>" +
                //     "<div style='width: 0; height: 0; border-left: 6px solid transparent; border-right: 5px solid transparent; border-top: 10px solid blue; '></div>" +
                //     "</div>" +
                //     `<p style='white-space:nowrap; font-family: Noto Sans KR; color: #222222; font-weight: 600;'>test</p>` +
                //     "</div>",
                //     size: naverMap.maps.Size(40, 40),
                //     anchor: naverMap.maps.Point(20, 20)
                // };



                let markerClustering = new window.MarkerClustering({
                    minClusterSize: 2,
                    // maxZoom: 13,
                    maxZoom: 16,
                    map: map,
                    markers: clustering,
                    disableClickZoom: false,
                    // gridSize: 120,
                    gridSize: 200,
                    // gridSize: 300,
                    icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
                    // icons: [htmlMarker6],
                    indexGenerator: [2, 4, 6, 8, 10],
                    stylingFunction: function (clusterMarker, count, clusterMember) {
                        window.$(clusterMarker.getElement()).find('div:first-child').text(count);
                        // window.$(clusterMarker.getElement()).find(':first-child>:first-child').text("+" + count);
                        // const startIndex = clusterMember[0].icon.content.indexOf("</div><p style='white-space:nowrap; font-family: Noto Sans KR; color: #222222; font-weight: 600;'>");
                        // const lastIndex = clusterMember[0].icon.content.indexOf("</p></div>");
                        // window.$(clusterMarker.getElement()).find('div>p').text(clusterMember[0].icon.content.slice(startIndex+98, lastIndex));
                    }
                })

            })
    }



    render() {
        return (
            <MediaContextProvider>
                <Media at='mobile'>
                    <MobileApp />
                </Media>
                <Media greaterThan='mobile'>
                    <DeskTopApp />
                </Media>
            </MediaContextProvider>
        );
    }
}

class DeskTopApp extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw", height: "100vh" }}>
                <Topmenu />
                <div id="map" style={{ width: "100%", height: "100%" }}>
                </div>
                <Footer />
            </div>
        );
    }
}

class MobileApp extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100vw" }}>
                <Topmenu />
                <div id="map" style={{ width: "100%", height: "100vh" }}>
                </div>
                <Footer />
            </div>
        );
    }
}

export default IntroArena;