import React, { Component } from 'react';
import {
    Header,
    Segment,
    Button,
    Table,
    TableRow,
    Dropdown,
    Pagination
} from 'semantic-ui-react'
import Common from '../../../Common.js';
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

class EduApplyManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropOptions: '',
            eduApplyList: '',
            crntTitleId: '',
            selectedPage: 1,
            totalPages: 0,
        }
    }

    componentDidMount = () => {
        this.get_boardData()
    }


    get_boardData = () => {
        let url = Common.backendUrl + "/board/api/board/?board_kind=edu";

        fetch(url)
            .then(response => {
                if (response.status > 400) {
                    console.log("error: no data");
                    return;
                }
                return response.json();
            })
            .then(data => {
                let dropOptions = [];
                data.results.map((result, index) => (
                    dropOptions.push({ 'key': index, 'text': result.subject, 'value': result.id })
                ))
                this.setState({
                    dropOptions
                })
            });
    }

    optionChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            crntTitleId: value
        })
        this.getEduApplicantData(value, 1)
    }

    getEduApplicantData = (value, page) => {
        fetch(Common.backendUrl + `/board/api/edulist/${value}?page=${page}`)

            .then(response => {
                if (response.status >= 400) {
                    alert('error');
                    return;
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if (data["count"] % 20 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10) + 1
                    })
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10)
                    })
                }

                this.setState({
                    eduApplyList: data.results,
                })
            })
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page
        })
        this.getEduApplicantData(this.state.crntTitleId, page)
    }

    handleEduListDelete = (e, id) => {
        e.preventDefault();
        fetch(Common.backendUrl + `/board/api/eduapply/delete/${id}`, {
            method: 'DELETE'
        })
            .then(res => {
                this.getEduApplicantData(this.state.crntTitleId, this.state.selectedPage)
            })
    }



    render() {
        let list = [];

        if (this.state.eduApplyList !== '') {
            list = this.state.eduApplyList.map((info, index) => (
                index === this.state.eduApplyList.length - 1 ?
                    <TableRow key={info.id} >
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>{info.id}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>{info.profile.member_text}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>{info.profile.member_phone}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>{info.board_name}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>{info.create_at.split('T')[0]}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px' }} textAlign='center'>
                            <Button onClick={(e) => this.handleEduListDelete(e, info.id)} color='red' inverted style={{ padding: '5px', fontSize: '12px' }} >삭제하기</Button>
                        </Table.HeaderCell>
                    </TableRow>
                    :
                    <TableRow key={info.id} >
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>{info.id}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>{info.profile.member_text}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>{info.profile.member_phone}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>{info.board_name}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>{info.create_at.split('T')[0]}</Table.HeaderCell>
                        <Table.HeaderCell style={{ padding: '10px 0px', borderBottom: "1px solid #000000" }} textAlign='center'>
                            <Button onClick={(e) => this.handleEduListDelete(e, info.id)} color='red' inverted style={{ padding: '5px', fontSize: '12px' }} >삭제하기</Button>
                        </Table.HeaderCell>
                    </TableRow>
            ))
        } else {
            list = <TableRow>
                <Table.HeaderCell style={{ padding: '10px 0px' }} colSpan='6' textAlign='center'>신청내역이 없습니다.</Table.HeaderCell>
            </TableRow>
        }

        return (
            <div>
                <Segment attached='top' clearing secondary style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: "center", border: "1px solid #000000" }}>
                    <Header as='h3' floated='left'>자격교육 신청 관리</Header>
                    <Dropdown
                        style={{ width: "40%", border: "1px solid #000000" }}
                        onChange={this.optionChange}
                        options={this.state.dropOptions}
                        // search={true} 
                        selection
                        placeholder='자격교육을 선택하세요.'
                        fluid />
                </Segment>
                <Segment attached style={{ display: "flex", flexDirection: 'column', alignItems: "center", width: "100%", overflow: "auto", border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{border: "1px solid #000000"}}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>no</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>이름</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>전화번호</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>신청 자격교육</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>신청일자</Table.HeaderCell>
                                <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} textAlign='center'>삭제</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {list}
                        </Table.Body>
                    </Table>
                    {this.state.totalPages > 0 ?
                        <Pagination
                            style={{ marginTop: "10px"}}
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={this.state.totalPages}
                            onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                        />
                        :
                        <></>
                    }
                </Segment>


                {/* <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} /> */}
            </div>
        );
    }
}

export default EduApplyManager;