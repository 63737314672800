import React, { Component } from "react";

import {
    Button,
    Modal,
    Form,
    TableRow,
    Table
} from 'semantic-ui-react'
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from '../Common';

class CompetitionDrawModalForm extends Component {
    state = {
        modal_open: false,
        teamData: "",
        teamGroupNum: [],
        firstRoundGroup: [],
        warningModalOpen: false,
        warningText: "",
    };

    handleGroupNum(e, id) {
        let teams = document.querySelectorAll(".teams");
        let groupNum = []
        for (let i = 0; i < teams.length; i++) {
            groupNum.push({id:teams[i].id, group_num:teams[i].value})
        }
        this.setState({
            teamGroupNum : groupNum});
    }

    handleFirstRoundGroup(e, id) {
        let teams = document.querySelectorAll(".first_round_group");
        let group = []
        for (let i = 0; i < teams.length; i++) {
            group.push({first_round_group:teams[i].value})
        }
        this.setState({
            firstRoundGroup : group});
    }

    handleSubmit = e => {
        e.preventDefault();
        let temp_arr = []
        let form_data = new FormData();
        if (this.state.teamGroupNum.length > 0 ){
            for (let i = 0; i < this.state.teamGroupNum.length; i++) {
                temp_arr.push(this.state.teamGroupNum[i].group_num);
                form_data.append('id', this.state.teamGroupNum[i].id);
                form_data.append('group_num', this.state.teamGroupNum[i].group_num);
                form_data.append('first_round_group', this.state.firstRoundGroup[i].first_round_group);  
            };
        } else if (this.state.teamGroupNum.length === 0 && this.state.firstRoundGroup.length > 0) {
                let i = 0 ;
                this.props.joinTeamList.map(
                    info => {
                        form_data.append('id', info.id);
                        form_data.append('group_num', info.group_num);
                        form_data.append('first_round_group', this.state.firstRoundGroup[i].first_round_group);
                        i++; 
                    }
                );    
        } else {
            this.props.joinTeamList.map(
                info => {
                    form_data.append('id', info.id);
                    form_data.append('group_num', info.group_num);
                    form_data.append('first_round_group', info.first_round_group); 
                }
            );
        }

        let set = new Set(temp_arr);
        
        if (set.size !== temp_arr.length){
            this.handleWarningModalOpen("중복된 조추첨 번호가 있습니다.");
            return;
        }
        
        fetch(Common.backendUrl + "/api/competitionteam/update/",{
            method: 'put',
            body : form_data
        })
            .then(response => {
                if (response.status > 400) {
                    this.handleWarningModalOpen('error');
                    return;
                } else {
                    this.handleWarningModalOpen("조추첨 완료");
                    this.props.reloadPage();
                    this.setState({ modal_open: false });
                    return response.json();
                }
            })

    };

    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
      handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        let list = []
       
        if (this.props.joinTeamList.length > 0){
            list[0] = this.props.joinTeamList.map(
                info => (
                    <TableRow key={info.id}>
                        <Table.Cell textAlign='center'>{info.team.team_text}</Table.Cell>
                        <Table.Cell>
                        <input
                            type="number"
                            className="teams"
                            name="group_num"
                            id={info.id}
                            min={1}
                            max={this.props.joinTeamList.length}
                            defaultValue={info.group_num}
                            onChange={(e)=>this.handleGroupNum(e)}
                            required
                        />
                        </Table.Cell>
                        <Table.Cell>
                        <input
                            type="number"
                            className="first_round_group"
                            name="first_round_group"
                            min={1}
                            max={8}
                            defaultValue={info.first_round_group}
                            onChange={(e)=>this.handleFirstRoundGroup(e)}
                            required
                        />
                        </Table.Cell>
                    </TableRow>
                )
            );
        }
    
        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button size='medium' primary>조 추첨</Button>}
                >
                    <Modal.Header>조 추첨</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                            <Table striped unstackable>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={1} textAlign='center'>팀명</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'>조추첨 번호</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign='center'>조</Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {list[0]}
                                </Table.Body>
                                </Table>
                                <Button positive type="submit" className="button is-info">등록하기</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionDrawModalForm