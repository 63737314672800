import React, { Component } from "react";
import styles from "./MemberSignUpGeral.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer"
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

import { Button, Icon, Form, Grid, Header, Segment } from 'semantic-ui-react'

class MemberSignUpGeneral extends Component {
    state = {
        warningModalOpen: false,
        warningText: "",
    };

    go_general_nextpage = () => {
        document.location.href = '/privacypolicy'
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        return (
            <>
                <Topmenu />
                <div style={{ minHeight: "1300px", backgroundColor: "red", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div className={styles.stars}></div>
                    <div className={styles.twinkling}></div>
                    <div className={styles.clouds}></div>

                    <div className={styles.login_form_wrap}>
                        <Grid textAlign='center' verticalAlign='middle'>
                            <Grid.Column style={{ maxWidth: "1000px" }}>
                                <Form size='large'>
                                    <Segment className={styles.login_form_div}>
                                        <Header as='h1' inverted color='grey' textAlign='center' style={{ marginTop: "30px", marginBottom: "100px", fontFamily: "Noto Sans KR" }}>
                                            대한드론축구협회 회원가입
                                        </Header>
                                        <Header as='h2' inverted color='grey' textAlign='center' style={{ marginTop: "30px", marginBottom: "100px", fontFamily: "Noto Sans KR" }}>
                                            * 회원가입 방식 선택
                                        </Header>
                                        <Grid textAlign='center' >
                                            <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <div style={{display: 'grid', textAlign:'center', justifyContent:'center'}}>
                                                <Icon style={{textAlign:'center', justifyContent:'center'}} inverted color='grey' size='massive' name='users'></Icon>
                                                <Header as='h3' inverted color='grey' textAlign='center' style={{ fontFamily: "Noto Sans KR" }}>
                                                    (일반 회원) <br/>14세 이상
                                                </Header>
                                                <a className={`${styles.style_a2} ${styles.mouse_hover} `} onClick={this.go_general_nextpage}>
                                                    <p style={{ background: "none" }}>회원가입</p>
                                                </a>
                                            </div>
                                            </Grid.Column> 
                                            <Grid.Column mobile={16} tablet={8} computer={5}>
                                            <div style={{display: 'grid', textAlign:'center', justifyContent:'center'}}>
                                                <Icon style={{textAlign:'center', justifyContent:'center'}} inverted color='grey' size='massive' name='users'></Icon>
                                                <Header as='h3' inverted color='grey' textAlign='center' style={{ fontFamily: "Noto Sans KR" }}>
                                                    (어린이 회원) <br/>14세 이하
                                                </Header>
                                                <a className={`${styles.style_a2} ${styles.mouse_hover} `} onClick={this.go_general_nextpage}>
                                                    <p style={{ background: "none" }}>회원가입</p>
                                                </a>
                                            </div>
                                            </Grid.Column>
                                        </Grid>
                                    </Segment>
                                </Form>

                            </Grid.Column>
                        </Grid>
                    </div>

                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <Footer />
            </>
        );
    }
}

export default MemberSignUpGeneral;