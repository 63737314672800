import React, { Component } from "react";
import { Input, Table, TableRow, Button } from "semantic-ui-react";
import Common from "../../../Common.js";
import styles from "./PlayerSearch.module.css";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

export default class PlayerSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerList: "",
            warningModalOpen: false,
            warningText: "",
        };
    }

    getPlayerSearchData = (playerName) => {
        fetch(Common.backendUrl + `/api/player/search/${playerName}`)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ playerList: data });
            });
    };

    clickSearchBtn = (e) => {
        e.preventDefault();
        if (document.getElementById("searchPlayerName").value === "") {
            this.handleWarningModalOpen("선수명을 입력하세요");
            return;
        }

        this.getPlayerSearchData(document.getElementById("searchPlayerName").value);
    };

    clickSearchPlayerTableRow = (e, provinceId, regionId, teamId) => {
        e.preventDefault();
        this.props.changeProvince(e, provinceId);
        this.props.changeRegion(e, regionId);
        this.props.teamChange(e, teamId);
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        let players = [];

        if (this.state.playerList !== "") {
            players = this.state.playerList.map((player) => (
                <TableRow key={player.id} onClick={(e) => this.clickSearchPlayerTableRow(e, player.team.region.province.id, player.team.region.id, player.team.id)} className={styles.tableRow} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                    <Table.Cell textAlign="center">{player.team.team_text}</Table.Cell>
                    <Table.Cell textAlign="center">{player.member_text}</Table.Cell>
                    {player.birth_date !== null ? <Table.Cell textAlign="center">{player.birth_date.split("T")[0]}</Table.Cell> : <Table.Cell textAlign="center">미입력</Table.Cell>}
                    <Table.Cell textAlign="center">{player.member_id}</Table.Cell>
                </TableRow>
            ));
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>선수 검색</p>
                <div style={{ marginBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Input id="searchPlayerName" placeholder="검색할 선수명 또는 선수자격번호를 입력하세요." style={{ width: "300px", marginRight: "10px", border: "1px solid #000000", borderRadius: ".28571429rem" }} />
                    <Button primary onClick={(e) => this.clickSearchBtn(e)}>
                        검색
                    </Button>
                </div>
                <div>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed", borderBottom: "1px solid #000000" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell width="1" textAlign="center">
                                    소속팀
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign="center">
                                    이름
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign="center">
                                    생년월일
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign="center">
                                    선수자격번호
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>{players}</Table.Body>
                    </Table>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}
