import React, { Component } from 'react'
import styles from "./MatchType.module.css";
import {withTranslation} from 'react-i18next';

class MatchTypeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchTypeList: [],
      sel_matchtype_id: 2,
    }
  }

  getMatchtypeList() {
    const { t } = this.props;

    let arr = [];
    const match_type = [
      { id: 2, match_type: t('competition_info_detail.CompetitionDetailManager.Finals')},
      { id: 4, match_type: t('competition_info_detail.CompetitionDetailManager.Semifinals')},
      { id: 8, match_type: t('competition_info_detail.CompetitionDetailManager.Quarterfinals')},
      { id: 16, match_type: t('competition_info_detail.CompetitionDetailManager.Tryout')}
    ]
    match_type.forEach(data => arr.push({ id: data.id, match_type_text: data.match_type, value: data.id }))
    this.setState({ matchTypeList: arr });
  }

  componentDidMount() {
    this.getMatchtypeList();
  }

  onClickHandler(match_type) {
    this.setState({ sel_matchtype_id: match_type });
    this.props.onSelMatchType(match_type);
  }

  render() {
    let itemDiv = [];
    for (let i = 0; i < this.state.matchTypeList.length; i++) {
      if (parseInt(this.state.sel_matchtype_id) === parseInt(this.state.matchTypeList[i].id)) {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.matchTypeList[i].id)} className={styles.item_div} key={this.state.matchTypeList[i].id} style={{ backgroundColor: "#5814E1", color: "white", fontWeight: "bold" }}>
          {this.state.matchTypeList[i].match_type_text}
        </div>);
      } else {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.matchTypeList[i].id)} className={styles.item_div} key={this.state.matchTypeList[i].id} style={{fontWeight: "bold"}}>
          {this.state.matchTypeList[i].match_type_text}
        </div>);
      }
    }
 
    return (
      <div style={{ display: "flex" }}>
        {itemDiv}
      </div>
    )
  }
}

export default withTranslation()(MatchTypeTab);