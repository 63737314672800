import React, { Component } from "react";
import { Table, TableRow, Segment, Header, Button, Pagination } from "semantic-ui-react";
import PaymentsDetailModal from "./PaymentsDetailModal.js";
import Common from "../../../Common.js";
import styles from "./PaymentManager.module.css";

class PaymentManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentList: "",
            isRefund: "False",
            selectedPage: 1,
            totalPages: 0,
            isOpen: false,
            selectedPayment: "",
        };
    }

    componentDidMount = () => {
        this.getPaymentForRefundData(this.state.isRefund, this.state.selectedPage);
    };

    getPaymentForRefundData(isRefund, page) {
        fetch(Common.backendUrl + `/api/paymanage/get/${isRefund}?page=${page}`)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
                return response.json();
            })
            .then((data) => {
                if (data["count"] % 10 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10) + 1,
                    });
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10),
                    });
                }
                this.setState({ paymentList: data.results });
            })
            .catch((error) => {
                alert(error + "");
            });
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page,
        });
        this.getPaymentForRefundData(this.state.isRefund, page);
    };

    // isRefundChange = (e, isRefund) => {
    //     e.preventDefault();

    //     this.getPaymentForRefundData(isRefund, 1)
    // }

    isPaymentClick = (e, paymentData) => {
        e.preventDefault();

        this.setState({
            selectedPayment: paymentData,
            isOpen: true,
        });
    };

    modalCloseMethod = () => {
        this.setState({
            selectedPayment: "",
            isOpen: false,
        });
    };

    render() {
        let paymentList = [];
        if (this.state.paymentList !== "") {
            if (this.state.paymentList[0] === undefined) {
                paymentList[0] = (
                    <TableRow style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                        <Table.Cell textAlign="center">결제내역이 없습니다.</Table.Cell>
                    </TableRow>
                );
            } else if (this.state.paymentList[0].count !== 0) {
                paymentList[0] = this.state.paymentList.map((info, index) =>
                    index === this.state.paymentList.length - 1 ? (
                        <TableRow onClick={(e) => this.isPaymentClick(e, info)} className={styles.table_row} key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <Table.Cell textAlign="center">{info.unique_num}</Table.Cell>
                            <Table.Cell textAlign="center">{info.profile.member_text}</Table.Cell>
                            <Table.Cell textAlign="center">{info.product}</Table.Cell>
                            {info.is_refund ? <Table.Cell textAlign="center">환불</Table.Cell> : <Table.Cell textAlign="center">결제완료</Table.Cell>}
                            <Table.Cell textAlign="center">
                                {info.create_at.split("T")[0]} {info.create_at.split("T")[1].split(".")[0]}
                            </Table.Cell>
                        </TableRow>
                    ) : (
                        <TableRow onClick={(e) => this.isPaymentClick(e, info)} className={styles.table_row} key={info.id} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.unique_num}
                            </Table.Cell>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.profile.member_text}
                            </Table.Cell>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.product}
                            </Table.Cell>
                            {info.is_refund ? (
                                <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    환불
                                </Table.Cell>
                            ) : (
                                <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    결제완료
                                </Table.Cell>
                            )}
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.create_at.split("T")[0]} {info.create_at.split("T")[1].split(".")[0]}
                            </Table.Cell>
                        </TableRow>
                    )
                );
            }
        }

        return (
            <Segment style={{ border: "1px solid #000000" }}>
                <Segment secondary clearing attached="top" style={{ border: "1px solid #000000" }}>
                    <Segment style={{ border: "1px solid #000000" }}>
                        <Header as="h3" textAlign="center">
                            결제 관리
                        </Header>
                    </Segment>
                </Segment>
                <Segment attached style={{ display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid #000000", borderTop: "0" }}>
                    {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <Button onClick={(e) => this.isRefundChange(e, "False") } color="facebook" style={{marginRight: "10px"}}>결제목록</Button>
                        <Button onClick={(e) => this.isRefundChange(e, "True") } color="facebook">환불목록</Button>
                    </div> */}
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign="center">
                                    고유넘버
                                </Table.HeaderCell>{" "}
                                {/* Unique num */}
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign="center">
                                    구매자
                                </Table.HeaderCell>{" "}
                                {/* Profile */}
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign="center">
                                    구매상품
                                </Table.HeaderCell>{" "}
                                {/* Product */}
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign="center">
                                    상태
                                </Table.HeaderCell>{" "}
                                {/* checking */}
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width="1" textAlign="center">
                                    구매일시
                                </Table.HeaderCell>{" "}
                                {/* create_at */}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>{paymentList[0]}</Table.Body>
                    </Table>
                    {this.state.totalPages > 0 ? <Pagination style={{ marginTop: "10px" }} boundaryRange={1} defaultActivePage={1} ellipsisItem={null} firstItem={null} lastItem={null} siblingRange={2} totalPages={this.state.totalPages} onPageChange={(e, data) => this.navOnClick(e, data.activePage)} /> : <></>}
                </Segment>
                <PaymentsDetailModal isOpen={this.state.isOpen} paymentData={this.state.selectedPayment} modalCloseMethod={this.modalCloseMethod} />
            </Segment>
        );
    }
}

export default PaymentManager;
