import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import { Button, Modal, Input, Dropdown, Form, Segment, List, TextArea } from "semantic-ui-react";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";

class CompetitionModifiedModalForm extends Component {
    state = {
        selCompetitionType: 1,
        competitionTypeOptions: [],
        competitionStateOptions: "",
        competition_text: "",
        registration_start_date: "",
        registration_end_date: "",
        competition_start_date: "",
        competition_end_date: "",
        competition_state: "",
        image_file: "",
        first_league_num: "",
        second_league_num: "",
        third_league_num: "",
        youth_league_num: "",
        weight: "",
        modal_open: false,
        warningModalOpen: false,
        warningText: "",
        isReLoad: false,
    };

    componentDidMount() {
        this.getCompetitionStateList();
        this.getCompetitionTypeOptions();
    }

    getCompetitionTypeOptions = () => {
        fetch(Common.backendUrl + "/api/competition/type/", {
            method: "get",
        })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요");
                }
                return res.json();
            })
            .then((json) => {
                const competitionTypeOptions = [];
                json.map((type) => {
                    if (type.competition_type === "종합") {
                        return;
                    }
                    competitionTypeOptions.push({ key: type.id, text: type.competition_type, value: type.id });
                });
                this.setState({
                    competitionTypeOptions,
                });
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    componentDidUpdate(prevProps) {
        if (this.props.selCompetitionType !== prevProps.selCompetitionType) {
            this.setState({
                selCompetitionType: this.props.selCompetitionType,
            });
        }
        if (this.props.competition_id !== prevProps.competition_id) {
            this.getCompetitionStateList();
        }
    }

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            let d = parts[2] + " " + monthNames[parseInt(parts[1]) - 1] + " " + parts[0];

            return new Date(d);
        } else {
            return;
        }
    }

    toDateTime(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let dayParts = parts[2].split("T");
            let day = dayParts[0];

            let d = day + " " + monthNames[parseInt(parts[1]) - 1] + " " + parts[0];

            return new Date(d);
        } else {
            return;
        }
    }

    getCompetitionStateList() {
        fetch(Common.backendUrl + "/api/competitionstate/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((state) => arr.push({ key: state.id, text: state.state_text, value: state.id }));
                this.setState({ competitionStateOptions: arr });
            });
    }

    handleCompetitionTextChange = (e, { value }) => {
        this.setState({ competition_text: value });
    };

    handleDateChange1 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate();
            this.setState({
                competition_start_date: strDate,
            });
        }
    };

    handleDateChange2 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate();
            this.setState({
                competition_end_date: strDate,
            });
        }
    };

    registDateHandleChange1 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "00:00:00.000000";
            this.setState({
                registration_start_date: strDate,
            });
        }
    };

    registDateHandleChange2 = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "23:59:59.000000";
            this.setState({
                registration_end_date: strDate,
            });
        }
    };

    handleCompetitionStateChange = (e, { value }) => {
        this.setState({ competition_state: value });
    };

    handleWeightChange = (e, { value }) => {
        this.setState({ weight: value });
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleFirstLeagueNumChange = (e, { value }) => {
        this.setState({ first_league_num: value });
    };

    handleSecondLeagueNumChange = (e, { value }) => {
        this.setState({ second_league_num: value });
    };

    handleThirdLeagueNumChange = (e, { value }) => {
        this.setState({ third_league_num: value });
    };

    handleYouthLeagueNumChange = (e, { value }) => {
        this.setState({ youth_league_num: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let temp_competition = {
            id: this.props.competition_id,
        };

        if (this.state.competition_text === "") {
            temp_competition.competition_text = this.props.competition_text;
        } else {
            temp_competition.competition_text = this.state.competition_text;
        }

        if (this.state.competition_start_date === "") {
            temp_competition.competition_start_date = this.props.competition_start_date;
        } else {
            temp_competition.competition_start_date = this.state.competition_start_date;
        }

        if (this.state.competition_end_date === "") {
            temp_competition.competition_end_date = this.props.competition_end_date;
        } else {
            temp_competition.competition_end_date = this.state.competition_end_date;
        }

        if (this.state.registration_start_date === "") {
            temp_competition.registration_start_date = this.props.registration_start_date;
        } else {
            temp_competition.registration_start_date = this.state.registration_start_date;
        }

        if (this.state.registration_end_date === "") {
            temp_competition.registration_end_date = this.props.registration_end_date;
        } else {
            temp_competition.registration_end_date = this.state.registration_end_date;
        }

        if (this.state.competition_state === "") {
            temp_competition.competition_state = this.props.competition_state;
        } else {
            temp_competition.competition_state = this.state.competition_state;
        }

        if (this.state.first_league_num === "") {
            temp_competition.first_league_num = this.props.first_league_num;
        } else {
            temp_competition.first_league_num = this.state.first_league_num;
        }

        if (this.state.second_league_num === "") {
            temp_competition.second_league_num = this.props.second_league_num;
        } else {
            temp_competition.second_league_num = this.state.second_league_num;
        }

        if (this.state.third_league_num === "") {
            temp_competition.third_league_num = this.props.third_league_num;
        } else {
            temp_competition.third_league_num = this.state.third_league_num;
        }

        if (this.state.youth_league_num === "") {
            temp_competition.youth_league_num = this.props.youth_league_num;
        } else {
            temp_competition.youth_league_num = this.state.youth_league_num;
        }

        if (this.state.weight === "") {
            temp_competition.weight = this.props.weight;
        } else {
            temp_competition.weight = this.state.weight;
        }

        let form_data = new FormData();

        if (this.state.image_file !== "") {
            form_data.append("competition_image", this.state.image_file.name);
            form_data.append("competition_image_file", this.state.image_file, this.state.image_file.name);
        } else {
            if (this.props.competition_image_file !== null) {
                form_data.append("competition_image", this.props.competition_image);
            } else {
                form_data.append("competition_image", this.props.competition_image_file);
            }
        }

        form_data.append("place", document.getElementById("competition_place").value);

        form_data.append("competition_text", temp_competition.competition_text);
        form_data.append("registration_start_date", temp_competition.registration_start_date);
        form_data.append("registration_end_date", temp_competition.registration_end_date);
        form_data.append("competition_start_date", temp_competition.competition_start_date);
        form_data.append("competition_end_date", temp_competition.competition_end_date);
        form_data.append("competition_state_id", temp_competition.competition_state);
        form_data.append("first_league_num", temp_competition.first_league_num);
        form_data.append("second_league_num", temp_competition.second_league_num);
        form_data.append("third_league_num", temp_competition.third_league_num);
        form_data.append("youth_league_num", temp_competition.youth_league_num);
        form_data.append("weight", temp_competition.weight);
        form_data.append("competition_info", document.getElementById("competition_info").value);
        form_data.append("competition_type_id", this.state.selCompetitionType);

        fetch(Common.backendUrl + "/api/competition/update/" + this.props.competition_id, {
            method: "put",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    if (response.status === 403) {
                        throw new Error("1500x1500 이하의 포스터를 업로드해주세요.");
                    } else if (response.status === 406) {
                        throw new Error("허용되지 않는 포스터 이미지 형식입니다.");
                    }
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요.");
                }
                return response.json();
            })
            .then((data) => {
                this.handleWarningModalOpen("대회정보수정 완료");
                this.setState({ isReLoad: true, modal_open: false });
                this.props.reloadPage();
            })
            .catch((error) => {
                this.handleWarningModalOpen(error + "");
            });
    };

    handleCompetitionTypeChange = (e, { value }) => {
        this.setState({ selCompetitionType: value });
    };

    matchDateInputChange = (e, data) => {
        const checkDate = data.value;

        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate();
            this.setState((prev) => ({
                [data.name]: strDate,
            }));
        } else {
            this.setState((prev) => ({
                [data.name]: null,
            }));
        }
    };

    handleCompetitionDel = (e) => {
        e.preventDefault();

        const form_data = new FormData();

        form_data.append("isDel", true);

        fetch(Common.backendUrl + "/api/competition/update/" + this.props.competition_id, {
            method: "put",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen("Bad Request");
                    this.setState({ modal_open: false });
                } else {
                    this.handleWarningModalOpen("대회삭제 완료");
                    this.setState({ isReLoad: true });
                    return response.json();
                }
            })

            .then((data) => {
                this.setState({ modal_open: false });
                this.props.reloadPage();
            });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.isReLoad) {
            window.location.reload();
        }
    };

    render() {
        return (
            <>
                <Modal
                    size={"tiny"}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={
                        <Button size="medium" primary>
                            대회정보수정
                        </Button>
                    }
                >
                    <Modal.Header>대회 정보수정</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">대회종류</label>
                                    <Dropdown value={this.state.selCompetitionType} selection options={this.state.competitionTypeOptions} name="competition_type" fluid onChange={this.handleCompetitionTypeChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회명</label>
                                    <Input type="text" name="competition_text" onChange={this.handleCompetitionTextChange} defaultValue={this.props.competition_text} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회접수 시작일</label>
                                    <SemanticDatepicker name="competition_start_date" onChange={this.registDateHandleChange1} value={this.toDateTime(this.props.registration_start_date)} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회접수 종료일</label>
                                    <SemanticDatepicker name="competition_end_date" onChange={this.registDateHandleChange2} value={this.toDateTime(this.props.registration_end_date)} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회시작일</label>
                                    <SemanticDatepicker name="competition_start_date" onChange={this.handleDateChange1} value={this.toDate(this.props.competition_start_date)} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회종료일</label>
                                    <SemanticDatepicker name="competition_end_date" onChange={this.handleDateChange2} value={this.toDate(this.props.competition_end_date)} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회안내</label>
                                    <TextArea id="competition_info" defaultValue={this.props.competition_info} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회장소</label>
                                    <Input id="competition_place" defaultValue={this.props.place} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">1부리그 조별 팀수</label>
                                    <Input type="number" name="first_league_num" defaultValue={this.props.first_league_num} onChange={this.handleFirstLeagueNumChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">2부리그 조별 팀수</label>
                                    <Input type="text" name="second_league_num" defaultValue={this.props.second_league_num} onChange={this.handleSecondLeagueNumChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">3부리그 조별 팀수</label>
                                    <Input type="text" name="third_league_num" defaultValue={this.props.third_league_num} onChange={this.handleThirdLeagueNumChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">유소년리그 조별 팀수</label>
                                    <Input type="text" name="youth_league_num" defaultValue={this.props.youth_league_num} onChange={this.handleYouthLeagueNumChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">DS포인트 가중치</label>
                                    <Input type="float" name="ds_point" defaultValue={this.props.weight} onChange={this.handleWeightChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회진행상태</label>
                                    <Dropdown selection options={this.state.competitionStateOptions} name="competition_state" defaultValue={this.props.competition_state_id} onChange={this.handleCompetitionStateChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">대회 이미지 (jpg 파일)</label>
                                    <Input type="file" name="profile" onChange={this.handleImageChange} accept="image/jpeg" />
                                    {this.props.competition_image_file !== null && (
                                        <div style={{ marginTop: "7px" }}>
                                            <Segment>
                                                <List.Item>
                                                    <List.Content>기존 대회 이미지: {this.props.competition_image}</List.Content>
                                                </List.Item>
                                            </Segment>
                                        </div>
                                    )}
                                    {this.props.competition_image_file === null && (
                                        <div style={{ marginTop: "7px" }}>
                                            <Segment>
                                                <List.Item>로고 이미지 없음</List.Item>
                                            </Segment>
                                        </div>
                                    )}
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    수정
                                </Button>
                                <Button negative onClick={(e) => this.handleCompetitionDel(e)} className="button is-info">
                                    삭제
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionModifiedModalForm;
