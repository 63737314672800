import React, { Component } from "react";
import styles from "./LeftMenu.module.css";
import Common from "../Common.js";
import ModalConfirm from "../warning_modal/ModalConfirm.js";
import WarningModal from "../warning_modal/ModalChoiceOne.js";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

class LeftMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevMenu: "",
            requestsCnt: 0,
            registrationCnt: 0,
            confirmModalOpen: false,
            confirmModalText: "",
            warningModalOpen: false,
            warningModalText: "",
        };
    }

    componentDidMount = () => {
        this.getTeamRegistrationData();
        this.getTeamRequestsData();
    };

    getTeamRequestsData() {
        fetch(Common.backendUrl + "/api/team/requests/manage/count")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    requestsCnt: data.count,
                });
            });
    }

    getTeamRegistrationData() {
        fetch(Common.backendUrl + "/api/teamregistration/list/0")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    registrationCnt: data.count,
                });
            });
    }

    confirmModalClose = () => {
        this.setState({
            confirmModalOpen: false,
        });
    };

    warningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    clickMenu = (e, text) => {
        sessionStorage.setItem("leftMenu", text);
        this.props.menuChange(text);
        if (this.state.prevMenu !== text) {
            e.target.style.backgroundColor = "rgba(17, 12, 46, 0.05)";
            if (this.state.prevMenu !== "") {
                document.getElementById(this.state.prevMenu).style.backgroundColor = "#F4F8FF";
            }
        }

        this.setState({ prevMenu: text });
    };

    updatePlayerRank = () => {
        fetch(Common.backendUrl + "/api/player/rank/update/", {
            method: "post",
        }).then((response) => {
            if (response.status >= 400) {
                this.setState({
                    warningModalOpen: true,
                    warningModalText: "error",
                    confirmModalOpen: false,
                });
            } else {
                this.setState({
                    warningModalOpen: true,
                    warningModalText: "랭킹정보 업데이트 완료",
                    confirmModalOpen: false,
                });
                return;
            }
        });
    };

    render() {
        const { requestsCnt, registrationCnt } = this.state;
        return (
            <div style={{ position: "sticky", left: "10px", top: "10%", width: "300px", marginRight: "30px", height: "max-content", display: "flex", flexDirection: "column", alignItems: "left" }}>
                <div className={styles.menu_div}>
                    {sessionStorage.getItem("isAdmin") === "true" ? (
                        <>
                            <h1>협회 관리자</h1>
                            <hr />
                            <p id="대회 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                대회 관리
                            </p>
                            <p id="지회/지부 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                지회/지부 관리
                            </p>
                            <p id="선수/선수단 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수/선수단 관리
                            </p>
                            <p id="선수/선수단 검색" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수/선수단 검색
                            </p>
                            <p id="선수단목록 다운로드" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수단목록 다운로드
                            </p>
                            <p id="국가대표 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                국가대표 관리
                            </p>
                            <p id="지역대표 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                지역대표 관리
                            </p>
                            <p id="자격교육 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                자격교육 관리
                            </p>
                            <p id="경기장 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                경기장 관리
                            </p>
                            <p id="배너 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                배너 관리
                            </p>
                            <p id="배너 관리" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                결제 관리
                            </p>
                            <p
                                id="랭킹정보 업데이트"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ confirmModalOpen: true, confirmModalText: "랭킹정보를 업데이트 하시겠습니까?" });
                                }}
                                style={{ marginBottom: "10px" }}
                            >
                                랭킹정보 업데이트
                            </p>
                        </>
                    ) : (
                        <></>
                    )}

                    {sessionStorage.getItem("level") === "210" ? (
                        <>
                            <h1>선수 단장</h1>
                            <hr />
                            <p id="선수단 정보" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수단 정보
                            </p>
                            <p id="선수 현황" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수 현황
                            </p>
                            <p id="선수상태 및 선수단 변경" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                선수상태 및 선수단 변경
                            </p>
                            <p id="대회 참가 이력" onClick={(e) => this.clickMenu(e, e.target.innerText)}>
                                대회 참가 이력
                            </p>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {sessionStorage.getItem("isAdmin") === "true" ? (
                    <>
                        <div id="선수/선수단 변경 요청" className={styles.alarm_div1} onClick={(e) => this.clickMenu(e, "선수/선수단 변경 요청")}>
                            선수/선수단 변경 요청 {requestsCnt}건
                        </div>
                        <div id="선수단 창단 요청" className={styles.alarm_div2} onClick={(e) => this.clickMenu(e, "선수단 창단 요청")}>
                            선수단 창단 요청 {registrationCnt}건
                        </div>
                    </>
                ) : (
                    <></>
                )}
                <ModalConfirm isOpen={this.state.confirmModalOpen} confirmText={this.state.confirmModalText} confirmMethd={this.updatePlayerRank} closeMethod={this.confirmModalClose} />
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningModalText} closeMethod={this.warningModalClose} />
            </div>
        );
    }
}

export default LeftMenu;
