import React, { Component } from "react";

import { Button, Modal, Input, Form, Dropdown } from "semantic-ui-react";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";

class CompetitionTeamApproveModalForm extends Component {
    state = {
        modal_open: false,
        register_state: "",
        receipt: "",
        warningModalOpen: false,
        warningText: "",
        teamMembers: [],
    };

    componentDidMount = () => {
        // let memberData = this.props.applicationMember.slice(3, -3).split("|||");
        if (this.props.applicationMember) {
            this.getTeamMembers(this.props.applicationMember.slice(3, -3).split("|||"));
        }
    };

    getTeamMembers = (memberData) => {
        let teamMembers = new Array();
        if (memberData) {
            memberData.map((data) =>
                fetch(Common.backendUrl + `/api/profile/one/${data.split(",")[0]}`)
                    .then((response) => {
                        if (response.status >= 400) {
                            alert("error");
                            return;
                        } else {
                            return response.json();
                        }
                    })
                    .then((data) => {
                        if (data) {
                            teamMembers.push([data.member_text, data.member_position.position_text, data.id, data.birth_date, data.team.team_text]);
                            this.setState({
                                teamMembers,
                            });
                        }
                    })
            );
        }
    };

    handleRegisterStateChange = (e, { value }) => {
        this.setState({ register_state: value });
    };

    handleFileChange = (e) => {
        this.setState({
            receipt: e.target.files[0],
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let form_data = new FormData();

        if (this.state.register_state !== "") {
            form_data.append("register_state", this.state.register_state);
        }

        if (this.state.receipt !== "") {
            form_data.append("receipt", this.state.receipt);
        }

        form_data.append("competition_id", this.props.competition_id);
        form_data.append("team_id", this.props.team_id);

        fetch(Common.backendUrl + "/api/competitiont_aplication/update/" + this.props.id, {
            method: "put",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 409) {
                    this.handleWarningModalOpen("이미 승인 하였습니다.");
                    // return;
                    return response.json();
                } else {
                    this.handleWarningModalOpen("error");
                    // return;
                    return response.json();
                }
            } else {
                this.setState({ modal_open: false });
                this.handleWarningModalOpen("수정되었습니다.");
                this.props.reloadPage();
                this.props.getJoinTeamData();
                // return;
                return response.json();
            }
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        let stateOptions = [];
        const register_state = [
            { id: 1, register_state: "승인" },
            { id: 2, register_state: "반려" },
        ];
        register_state.forEach((data) => stateOptions.push({ key: data.id, text: data.register_state, value: data.id }));

        return (
            <>
                <Modal
                    size={"tiny"}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={
                        <Button primary style={{ margin: "0px 0px 0px 0px" }} size="mini">
                            참가신청관리
                        </Button>
                    }
                >
                    <Modal.Header>신청관리</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            {/* <Form onSubmit={this.handleSubmit}> */}
                            <Form>
                                {this.state.teamMembers && (
                                    <Form.Field>
                                        <label className="label">참가선수</label>
                                        {this.state.teamMembers.map((member, index) => (
                                            <p>
                                                {index + 1}. {member[0]}({member[1]})
                                            </p>
                                        ))}
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label className="label">신청 상태</label>
                                    <Dropdown placeholder="신청 상태" selection options={stateOptions} name="register_state" defaultValue={this.props.register_state} onChange={this.handleRegisterStateChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">참가비 영수증</label>
                                    <Input type="file" name="receipt" onChange={this.handleFileChange} />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info" onClick={this.handleSubmit}>
                                    업데이트
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionTeamApproveModalForm;
