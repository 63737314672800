import React, { Component } from 'react';
import styles from './ModalConfirm.module.css';
import { Button, Confirm, Header, Icon, Segment } from 'semantic-ui-react';

class ModalConfirm extends Component {
    render() {
        return (
            <Confirm className={styles.wrap_div}
                open={this.props.isOpen}
                header={
                    <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px" }}>
                        <Icon name='info' color="yellow" style={{ margin: "0px", fontSize: "15pt" }} />
                    </Header>
                }

                content={
                    <div style={{ padding: "21px" }}>
                        <p style={{ textAlign: "center", fontSize: "10pt", fontFamily: "Noto Sans KR", fontWeight: "bold", color: "black" }}>{this.props.confirmText}</p>
                    </div>
                }

                confirmButton={
                    <Button color='red' inverted onClick={() => this.props.confirmMethd()}>
                        <Icon name='remove' /> 확인
                </Button>
                }

                cancelButton={
                    <Button color='green' inverted onClick={() => this.props.closeMethod()}>
                        <Icon name='remove' /> 닫기
                </Button>
                }
                size="mini"
            />
        );
    }
}

export default ModalConfirm;