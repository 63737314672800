import React, { Component } from "react";
import CompetitionMatchInfoModalForm from "./CompetitionMatchInfoModalForm.js";
import CompetitionFirstRoundResult from "./CompetitionFirstRoundResult.js";
import CompetitionFirstRoundResultModalForm from "./CompetitionFirstRoundResultModalForm.js";
import CompetitionFirstRoundResultTap from "./CompetitionFirstRoundResultTap.js";
import CompetitionSecondRoundResultModalForm from "./CompetitionSecondRoundResultModalForm.js";
import CompetitionRankTap from "./CompetitionRankTap.js";
import ScoreModal from "./ScoreModal.js";
import styles from "./competition.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

import { Header, Segment, Button, Table, TableRow } from "semantic-ui-react";
import Common from "../Common";

class CompetitionGameList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamList: "",
            matchTypeCount: 0,
            first_league_num: "",
            second_league_num: "",
            third_league_num: "",
            youth_league_num: "",
            firstRoundResult: "",
            competitionRankData: "",
            warningModalOpen: false,
            warningText: "",
        };
        this.setFirstRoundGame = this.setFirstRoundGame.bind(this);
        this.setSecondRoundGame = this.setSecondRoundGame.bind(this);
        this.setSemifinal = this.setSemifinal.bind(this);
        this.setFinal = this.setFinal.bind(this);
    }

    getCompetitionData() {
        fetch(Common.backendUrl + "/api/competition/" + this.props.competition_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    first_league_num: data.first_league_num,
                    second_league_num: data.second_league_num,
                    third_league_num: data.third_league_num,
                    youth_league_num: data.youth_league_num,
                });
            });
    }

    getFirstRoundResultData() {
        fetch(Common.backendUrl + "/api/first_round_game/rank/" + this.props.competition_id + "/" + this.props.league_id)
            .then((response) => {
                if (response.status >= 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ firstRoundResult: data });
            });
    }

    getFinalRankData() {
        fetch(Common.backendUrl + "/api/competition/rank/" + this.props.competition_id + "/" + this.props.league_id)
            .then((response) => {
                if (response.status >= 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ competitionRankData: data });
            });
    }

    getMatchTypeCountData() {
        const data = { competition_id: this.props.competition_id, league: this.props.league_id };

        const conf = {
            method: "post",
            body: JSON.stringify(data),
            headers: new Headers({ "Content-Type": "application/json" }),
        };

        fetch(Common.backendUrl + "/api/matchtypecount/", conf)
            .then((response) => {
                if (response.status >= 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ matchTypeCount: data });
            });
    }

    componentDidMount() {
        this.getCompetitionData();

        if (this.props.match_type === 2) {
            this.getFinalRankData();
        }
        if (this.props.match_type === "예선") {
            this.getMatchTypeCountData();
            this.getFirstRoundResultData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.competition_id !== prevProps.competition_id) {
            this.getCompetitionData();
        }
        if (this.props.competition_id !== prevProps.competition_id && this.props.match_type === "예선") {
            this.getMatchTypeCountData();
            this.getFirstRoundResultData();
        }
        if (this.props.competition_id !== prevProps.competition_id && this.props.match_type === 2) {
            this.getFinalRankData();
        }
    }

    setFirstRoundGame() {
        if (!window.confirm("예선전 경기를 생성하시겠습니까?")) {
            return;
        }

        let league_group_num = 1;

        if (this.props.league_id === 1) {
            league_group_num = this.state.first_league_num;
        } else if (this.props.league_id === 2) {
            league_group_num = this.state.second_league_num;
        } else if (this.props.league_id === 3) {
            league_group_num = this.state.third_league_num;
        } else {
            league_group_num = this.state.youth_league_num;
        }
        let form_data = new FormData();

        form_data.append("competition_id", this.props.competition_id);
        form_data.append("league_id", this.props.league_id);
        form_data.append("league_group_num", league_group_num);

        fetch(Common.backendUrl + "/api/first_round_game/create/", {
            method: "post",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 409) {
                    this.handleWarningModalOpen("경기을 이미 생성하였습니다");
                    return;
                } else if (response.status === 406) {
                    this.handleWarningModalOpen("조추첨을 진행해주세요.");
                    return;
                } else if (response.status === 404) {
                    this.handleWarningModalOpen("대회정보 수정에서 리그별 조의 팀개수를 설정해주세요.");
                    return;
                } else {
                    this.handleWarningModalOpen("경기를 생성하기 위한 팀 개수가 맞지않습니다. 팀 추가 또는 팀 승인을 진행해주세요.");
                    return;
                }
            } else {
                if (response.status === 201) {
                    this.handleWarningModalOpen("경기 생성 완료");
                    this.props.getGameList();
                    return;
                }
            }
        });
    }

    setSecondRoundGame() {
        if (!window.confirm("8강 경기를 생성하시겠습니까?")) {
            return;
        }

        let league_group_num = 1;

        if (this.props.league_id === 1) {
            league_group_num = this.state.first_league_num;
        } else if (this.props.league_id === 2) {
            league_group_num = this.state.second_league_num;
        } else if (this.props.league_id === 3) {
            league_group_num = this.state.third_league_num;
        } else {
            league_group_num = this.state.youth_league_num;
        }

        let group_count = this.props.joinTeamList.length / league_group_num;
        let form_data = new FormData();

        form_data.append("competition_id", this.props.competition_id);
        form_data.append("league_id", this.props.league_id);
        form_data.append("group_count", group_count);

        fetch(Common.backendUrl + "/api/second_round_game/create/", {
            method: "post",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 409) {
                    this.handleWarningModalOpen("경기을 이미 생성하였습니다");
                    return;
                } else {
                    this.handleWarningModalOpen("error");
                    return;
                }
            } else {
                if (response.status === 201) {
                    this.handleWarningModalOpen("경기 생성 완료");
                    this.props.getGameList();
                    return;
                }
            }
        });
    }

    setSemifinal() {
        if (!window.confirm("4강 경기를 생성하시겠습니까?")) {
            return;
        }

        let form_data = new FormData();

        form_data.append("competition_id", this.props.competition_id);
        form_data.append("league_id", this.props.league_id);
        form_data.append("group_count", 2);
        form_data.append("join_count", this.props.joinTeamList.length);

        fetch(Common.backendUrl + "/api/semi_final_game/create/", {
            method: "post",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 409) {
                    this.handleWarningModalOpen("경기을 이미 생성하였습니다");
                    return;
                } else {
                    this.handleWarningModalOpen("error");
                    return;
                }
            } else {
                if (response.status === 201) {
                    this.handleWarningModalOpen("경기 생성 완료");
                    this.props.getGameList();
                    return;
                }
            }
        });
    }

    setFinal() {
        if (!window.confirm("결승전 경기를 생성하시겠습니까?")) {
            return;
        }

        let form_data = new FormData();

        form_data.append("competition_id", this.props.competition_id);
        form_data.append("league_id", this.props.league_id);
        form_data.append("group_count", 2);

        fetch(Common.backendUrl + "/api/final_game/create/", {
            method: "post",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 409) {
                    this.handleWarningModalOpen("경기을 이미 생성하였습니다");
                    return;
                } else if (response.status === 400) {
                    this.handleWarningModalOpen("4강경기 승리팀을 선택해주세요");
                    return;
                } else if (response.status === 404) {
                    this.handleWarningModalOpen("4강경기가 존재하지 않습니다.");
                    return;
                } else {
                    this.handleWarningModalOpen("error");
                    return;
                }
            } else {
                if (response.status === 201) {
                    this.handleWarningModalOpen("경기 생성 완료");
                    this.props.getGameList();
                    return;
                }
            }
        });
    }

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let day = parts[2].split("T");
            let time = day[1].split(".");
            let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0] + " " + time[0];
            return covertedDate;
        } else {
            return;
        }
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    matchDeleteBtnClick = (gameMatchId) => {
        if (gameMatchId.length === 0) {
            alert("삭제할 경기가 없습니다.");
            return;
        }

        if (!window.confirm(`경기를 전부 삭제하시겠습니까?\n삭제된 경기는 되돌릴 수 없습니다.`)) {
            return;
        }

        this.handleWarningModalOpen("삭제 중입니다.");

        const formData = new FormData();
        formData.append("gameMatchId", gameMatchId);

        fetch(Common.backendUrl + "/api/gamematch/delete/", {
            method: "delete",
            body: formData,
        }).then((res) => {
            this.handleWarningModalOpen("삭제가 완료되었습니다.");
            this.props.getGameList();
        });
    };

    render() {
        let list = new Array(1);
        let match_type_str;
        let delGameMatch = new Array();

        if (this.props.gameList.length > 0) {
            list[0] = this.props.gameList.map((info) => {
                delGameMatch.push(info.id);
                return (
                    <TableRow key={info.id}>
                        {this.props.match_type === 2 && <Table.Cell>{info.match_type.match_type}</Table.Cell>}
                        {this.props.match_type === 4 && <Table.Cell>{info.match_type.match_type + " " + info.match_type.match_type_str}</Table.Cell>}
                        {this.props.match_type === 8 && <Table.Cell>{info.match_type.match_type + " " + info.match_type.match_type_str}</Table.Cell>}
                        {this.props.match_type === "예선" && <Table.Cell>{info.match_type.match_type + " " + info.match_type.match_type_str}</Table.Cell>}
                        <Table.Cell textAlign="center">
                            <div onClick={() => window.open(`/kiosk.html?red&${this.props.competition_id}&${info.team_red.team.id}`)} style={{ cursor: "pointer" }} className={styles.redText}>
                                {info.team_red.team.team_text}
                            </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            <div onClick={() => window.open(`/kiosk.html?blue&${this.props.competition_id}&${info.team_blue.team.id}`)} style={{ cursor: "pointer" }} className={styles.blueText}>
                                {info.team_blue.team.team_text}
                            </div>
                        </Table.Cell>
                        <Table.Cell textAlign="center">{info.first_set_score}</Table.Cell>
                        <Table.Cell textAlign="center">{info.second_set_score}</Table.Cell>
                        <Table.Cell textAlign="center">{info.third_set_score}</Table.Cell>
                        {info.winner !== null && <Table.Cell textAlign="center">{info.winner.team_text}</Table.Cell>}
                        {info.winner === null && <Table.Cell textAlign="center">{info.winner}</Table.Cell>}
                        <Table.Cell textAlign="center">{this.toDate(info.match_date)}</Table.Cell>
                        <Table.Cell textAlign="center">{info.match_stadium}</Table.Cell>
                        {info.game_state === 0 && <Table.Cell textAlign="center">경기 예정</Table.Cell>}
                        {info.game_state === 1 && <Table.Cell textAlign="center">경기 진행중</Table.Cell>}
                        {info.game_state === 2 && <Table.Cell textAlign="center">경기 종료</Table.Cell>}
                        <Table.Cell textAlign="center">
                            <CompetitionMatchInfoModalForm
                                competition_id={this.props.competition_id}
                                game_id={info.id}
                                first_set_score={info.first_set_score}
                                second_set_score={info.second_set_score}
                                third_set_score={info.third_set_score}
                                game_state={info.game_state}
                                match_stadium={info.match_stadium}
                                video_url={info.video_url}
                                winner={info.winner}
                                team_red={info.team_red.team}
                                team_blue={info.team_blue.team}
                                match_date={info.match_date}
                                reloadPage={this.props.getGameList}
                            />
                        </Table.Cell>
                        {/* this.props.competition_id + "/" + this.props.match_type + "/" + this.props.league_id */}
                        <Table.Cell textAlign="center">
                            <ScoreModal game_id={info.id} />
                        </Table.Cell>
                    </TableRow>
                );
            });
        }

        let button = new Array(1);
        let result = new Array(this.state.matchTypeCount.match_type_count);
        let bgcolor = "red";

        if (this.props.match_type === 2) {
            match_type_str = "결승전 & 3/4위전";
            button[0] = (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Button size="medium" primary onClick={this.setFinal}>
                        결승전 생성
                    </Button>
                    <Button primary color="google plus" style={{ marginLeft: "10px" }} onClick={() => this.matchDeleteBtnClick(delGameMatch)}>
                        결승전 삭제
                    </Button>
                </div>
            );
        } else if (this.props.match_type === 4) {
            match_type_str = "4강전";
            button[0] = (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Button size="medium" primary onClick={this.setSemifinal}>
                        4강경기 생성
                    </Button>
                    <Button primary color="google plus" style={{ marginLeft: "10px" }} onClick={() => this.matchDeleteBtnClick(delGameMatch)}>
                        4강경기 삭제
                    </Button>
                </div>
            );
        } else if (this.props.match_type === "예선") {
            match_type_str = "예선전";
            for (let i = 1; i <= this.state.matchTypeCount.match_type_count; i++) {
                // if (this.state.firstRoundResult.length > 0) {
                if (this.state.firstRoundResult) {
                    if (Object.getOwnPropertyNames(this.state.firstRoundResult).length !== 0) {
                        result.push(<CompetitionFirstRoundResult firstRoundResult={this.state.firstRoundResult.filter((data) => data.first_round_group == i)} first_round_group={i} />);
                    }
                }
            }
            button[0] = (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Button size="medium" primary onClick={this.setFirstRoundGame}>
                        예선경기 생성
                    </Button>
                    <Button primary color="google plus" style={{ marginLeft: "10px" }} onClick={() => this.matchDeleteBtnClick(delGameMatch)}>
                        예선경기 삭제
                    </Button>
                </div>
            );
        } else if (this.props.match_type === 8) {
            match_type_str = "8강전";
            if (this.props.joinTeamList.length > 8) {
                button[0] = (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Button size="medium" primary onClick={this.setSecondRoundGame}>
                            8강경기 생성
                        </Button>
                        <Button primary color="google plus" style={{ marginLeft: "10px" }} onClick={() => this.matchDeleteBtnClick(delGameMatch)}>
                            8강경기 삭제
                        </Button>
                    </div>
                );
            }
        }

        let leagueList = [1, 2, 3, 4];

        return (
            <div>
                <Segment attached="top" clearing secondary style={{ border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        {match_type_str}
                    </Header>
                    {this.props.league_id == 1 && match_type_str === "예선전" && (
                        <Header as="h4" floated="right">
                            <CompetitionFirstRoundResultModalForm team_count={this.state.first_league_num} joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 2 && match_type_str === "예선전" && (
                        <Header as="h4" floated="right">
                            <CompetitionFirstRoundResultModalForm team_count={this.state.second_league_num} joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 3 && match_type_str === "예선전" && (
                        <Header as="h4" floated="right">
                            <CompetitionFirstRoundResultModalForm team_count={this.state.third_league_num} joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 4 && match_type_str === "예선전" && (
                        <Header as="h4" floated="right">
                            <CompetitionFirstRoundResultModalForm team_count={this.state.youth_league_num} joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 1 && match_type_str === "8강전" && this.props.joinTeamList.length > 8 && (
                        <Header as="h4" floated="right">
                            <CompetitionSecondRoundResultModalForm joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 2 && match_type_str === "8강전" && this.props.joinTeamList.length > 8 && (
                        <Header as="h4" floated="right">
                            <CompetitionSecondRoundResultModalForm joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 3 && match_type_str === "8강전" && this.props.joinTeamList.length > 8 && (
                        <Header as="h4" floated="right">
                            <CompetitionSecondRoundResultModalForm joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    {this.props.league_id == 4 && match_type_str === "8강전" && this.props.joinTeamList.length > 8 && (
                        <Header as="h4" floated="right">
                            <CompetitionSecondRoundResultModalForm joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                        </Header>
                    )}
                    <Header as="h4" floated="right">
                        {button[0]}
                    </Header>
                </Segment>
                {match_type_str === "예선전" && <CompetitionFirstRoundResultTap list={list[0]} result={result} reloadPage={this.getGameList} />}
                {match_type_str === "8강전" && (
                    <Segment attached style={{ border: "1px solid #000000", borderTop: "0" }}>
                        <Table striped unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>경기분류</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        <div className={styles.redHederText}>RED팀</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        <div className={styles.blueHederText}>BLUE팀</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        1세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        2세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        3세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        승리팀
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기시간
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기장소
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기상태
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기관리
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        점수관리
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{list[0]}</Table.Body>
                        </Table>
                    </Segment>
                )}

                {match_type_str === "4강전" && (
                    <Segment attached style={{ border: "1px solid #000000", borderTop: "0" }}>
                        <Table striped unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>경기분류</Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        <div className={styles.redHederText}>RED팀</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        <div className={styles.blueHederText}>BLUE팀</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        1세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        2세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        3세트
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        승리팀
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기시간
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기장소
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기상태
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        경기관리
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} textAlign="center">
                                        점수관리
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>{list[0]}</Table.Body>
                        </Table>
                    </Segment>
                )}
                {match_type_str === "결승전 & 3/4위전" && <CompetitionRankTap list={list[0]} competitionRankData={this.state.competitionRankData} competition_id={this.props.competition_id} league_id={this.props.league_id} />}
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

export default CompetitionGameList;
