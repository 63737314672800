import React, { Component } from "react";
import { Dropdown, Pagination, Grid } from "semantic-ui-react";
import LeagueTab from "../main/LeagueTab";
import CompetitionGameMatchResult from "./CompetitionGameMatchResult.js";
import MatchTypeTab from "./MatchTypeTab";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./CompetitionInfo.module.css";
import { withTranslation } from "react-i18next";
import Common from "../Common";

// 전체대회 페이지
class CompetitionInfoManager extends Component {
    constructor(props) {
        super(props);

        const maxYear = new Date().getFullYear();
        const minYear = 2020;
        const years = [];

        for (let i = maxYear; i >= minYear; i--) {
            years.push({ key: i, text: i, value: i });
        }

        this.state = {
            crntYear: parseInt(this.props.match.params.competitionYear),
            yearDropDownOptions: years,
            selType: this.props.match.params.competitionType,
            competitionTypeOptions: [],
            selectedCompetitionId: this.props.match.params.competitionId, // 여기에 app.js에서 받은 url 쿼리 스트링 값(competition id) 넣기
            teamList: "",
            leagueId: 1,
            competitions: [],
            matchType: 2,
            lastCompetitionIndex: 0,
        };
    }

    componentDidMount() {
        this.getLastYearCompetition();
        this.getCompetition(this.state.crntYear, this.state.selType);
        this.getCompetitionTypeOptions();
        this.getCompetitionJoinTeam(this.state.selectedCompetitionId, 1);
    }

    getLastYearCompetition() {
        fetch(Common.backendUrl + "/api/competition/lastone")
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("error");
                }
                return res.json();
            })
            .then((data) => {
                const lastYear = parseInt(data[0].competition_start_date.split("-")[0]);
                if (this.state.yearDropDownOptions[0].key < lastYear) {
                    this.setState({
                        yearDropDownOptions: [{ key: lastYear, text: lastYear, value: lastYear }, ...this.state.yearDropDownOptions],
                    });
                }
            });
    }

    getCompetitionTypeOptions = () => {
        fetch(Common.backendUrl + "/api/competition/type/", {
            method: "get",
        })
            .then((res) => {
                if (res.status >= 400) {
                    throw new Error("오류가 발생했습니다. 관리자에게 문의하세요");
                }
                return res.json();
            })
            .then((json) => {
                const competitionTypeOptions = [];
                json.map((type) => {
                    competitionTypeOptions.push({ key: type.id, text: type.competition_type, value: type.competition_type });
                });
                this.setState({
                    competitionTypeOptions,
                });
            })
            .catch((error) => {
                alert(error + "");
            });
    };

    selMatchTypeHandler(match_type) {
        if (match_type === 16) {
            this.setState({ matchType: "예선" });
        } else {
            this.setState({ matchType: match_type });
        }
    }

    getCompetition(year, type) {
        // fetch(Common.backendUrl + `/api/competition`)
        fetch(Common.backendUrl + `/api/show_competition/${year}/${type}/`)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let index = -1;
                let competitions = [];
                for (let competition of data) {
                    competitions.push({ key: competition.id, text: competition.competition_text, value: competition.id });
                    index += 1;
                }
                // console.log(competitions[0]["key"])
                this.setState({
                    competitions: competitions,
                    lastCompetitionIndex: index,
                });
            });
    }

    getCompetitionJoinTeam(competitionId, selNum) {
        fetch(Common.backendUrl + "/api/competitionteam/" + competitionId + "/" + selNum)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ teamList: data });
            });
    }

    handleCompetitionChange = (e, data) => {
        // console.log(data.value)
        this.setState({ selectedCompetitionId: data.value });
        window.location.href = `/info/competition/detail/${this.state.crntYear}/${this.state.selType}/${data.value}`;
    };

    selLeagueHandler(selNum) {
        this.setState({ leagueId: selNum });
    }

    yearDropdownChange = (e, { value }) => {
        this.getCompetition(value, this.state.selType);
        this.setState({
            crntYear: value,
        });
    };

    typeDropdownChange = (e, { value }) => {
        this.getCompetition(this.state.crntYear, value);
        this.setState({
            selType: value,
        });
    };

    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;
        return (
            <>
                <Topmenu />

                <div className={styles.competition_div}>
                    <div className={styles.competition_wrap}>
                        <h1 style={{ marginBottom: "50px" }}>{t("competition_info_detail.CompetitionDetailManager.Main_Title")}</h1>

                        <div className={styles.competition_dropdown}>
                            <div style={{ marginBottom: "10px" }}>
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.yearDropdownChange} value={this.state.crntYear} options={this.state.yearDropDownOptions} selection placeholder="대회연도" />
                                <Dropdown style={{ border: "1px solid #000000" }} onChange={this.typeDropdownChange} value={this.state.selType} options={this.state.competitionTypeOptions} selection placeholder="대회리그" />
                            </div>
                            <div>
                                <Dropdown onChange={(e, data) => this.handleCompetitionChange(e, data)} options={this.state.competitions} search={true} defaultValue={parseInt(this.state.selectedCompetitionId)} selection placeholder="대회목록" /> {/* 여기에 app.js에서 받은 url 쿼리 스트링 값(competition id) 넣기 */}
                            </div>
                        </div>
                        <div className={styles.league_tab}>
                            <LeagueTab onSelLeague={(selNum) => this.selLeagueHandler(selNum)} />
                        </div>
                        <div className={styles.matchType_tab}>
                            <MatchTypeTab onSelMatchType={(match_type) => this.selMatchTypeHandler(match_type)} />
                        </div>
                        <div>
                            <CompetitionGameMatchResult competition_id={this.state.selectedCompetitionId} league_id={this.state.leagueId} match_type={this.state.matchType} />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(CompetitionInfoManager);
