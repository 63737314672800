import React, { Component } from 'react'
import Topmenu from "../Topmenu/Topmenu"
import Footer from "../footer/Footer";
import styles from "./IntroLocation.module.css";
import { Icon } from "semantic-ui-react"
import { withTranslation } from "react-i18next";

class IntroLocation extends Component {
    componentDidMount = () => {
        let naverMap = window.naver;
        let mapOptions = {
            center: new naverMap.maps.LatLng(35.86829322499631, 127.06521975827799),
            zoom: 16
        };
        let map = new naverMap.maps.Map(document.getElementById('map'), mapOptions);
        let marker = new naverMap.maps.Marker({
            // position: new naverMap.maps.LatLng(35.868034, 127.064505),
            position: new naverMap.maps.LatLng(35.86829322499631, 127.06521975827799),
            map: map,
        })
        const point1 = new naverMap.maps.LatLng(35.86606929418949, 127.06222341868063)
        const point2 = new naverMap.maps.LatLng(35.86623677088896, 127.06467975315941)
        const point3 = new naverMap.maps.LatLng(35.86651126290707, 127.06465518240536)

        let openArrowLine = new naverMap.maps.Polyline({
            path: [
                point1,
                point2,
                point3
            ],
            map: map,
            endIcon: naverMap.maps.PointingIcon.OPEN_ARROW,
            strokeColor: '#ff0000',
            strokeWeight: 2,
        });

        let entrance = new naverMap.maps.Marker({
            position: new naverMap.maps.LatLng(35.86651126290707, 127.06465518240536),
            map: map,
            icon : {
                content: [
                    '<div">',
                    '<p style="color: #020202; font-weight: bold; font-size: 16px;">남문</p>',
                    '</div>'
                ].join(''),
                size: new naverMap.maps.Size(55,19),
                origin: new naverMap.maps.Point(0,0),
                anchor: new naverMap.maps.Point(15, 27)
            },
        })



        let contentString = [
            '<div style="padding: 5px;">',
            '   <p style="font-family: Noto Sans KR; color: #020202; font-weight: bold; ">대한드론축구협회</p>',
            '   <p style="font-family: Noto Sans KR; color: #020202;">전라북도 전주시 덕진구 기린대로 1055,<br />',
            '       전주드론축구상설체험장 (전주월드컵경기장 內) <br /> 사단법인 대한드론축구협회<br /><br />',
            '       <a style="font-family: Noto Sans KR; color: #499df2;" href="https://map.naver.com/v5/search/%EC%A0%84%EC%A3%BC%EB%93%9C%EB%A1%A0%EC%B6%95%EA%B5%AC%EC%83%81%EC%84%A4%EC%B2%B4%ED%97%98%EC%9E%A5/place/1584482708?c=14144469.0483155,4282276.4229417,16,0,0,0,dh" target="_blank">지도에서 보기</a>',
            '   </p>',
            '</div>'
        ].join('');

        let infowindow = new naverMap.maps.InfoWindow({
            content: contentString
        });

        naverMap.maps.Event.addListener(marker, 'click', () => {
            if (infowindow.getMap()) {
                infowindow.close();
            } else {
                infowindow.open(map, marker);
            }
            // window.open('https://map.naver.com/v5/search/%EC%A0%84%EC%A3%BC%EB%93%9C%EB%A1%A0%EC%B6%95%EA%B5%AC%EC%83%81%EC%84%A4%EC%B2%B4%ED%97%98%EC%9E%A5/place/1584482708?c=14144469.0483155,4282276.4229417,16,0,0,0,dh')
        })
        infowindow.open(map, marker)
    }

    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Topmenu />
                <div className={styles.forthtab} style={{ backgroundImage: "url('/images/intro/bg_03.jpg')" }}>

                        <div id="map" className={styles.map_div}></div>

                        <div className={styles.bus_car_div} >

                            <div className={styles.how_come} >
                                <Icon name="bus" style={{marginRight: "30px"}} />
                                <div style={{ marginBottom: "18px" }}>
                                    <h3>{t("Introduction.IntroLocation.Public")}</h3>
                                    <div dangerouslySetInnerHTML={{__html: t("Introduction.IntroLocation.Public_Way")}}></div>
                                </div>
                            </div>
                            <div className={styles.vertical_line} style={{ height: "200px", margin: "0px 50px" }}></div>
                            <div className={styles.how_come} >
                                <Icon name="car" />
                                <div>
                                    <h3 >{t("Introduction.IntroLocation.Honam")}</h3>
                                    <div dangerouslySetInnerHTML={{__html: t("Introduction.IntroLocation.Honam_Way")}}></div>
                                </div>
                            </div>
                        </div>

                    </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation() (IntroLocation)