import React, { Component } from "react";
import styles from "./Topmenu.module.css";
import { Image, Dropdown, Grid, Icon, Divider } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";

class Topmenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false, //sessionStorage.getItem('token') ? true : false,
            member_pk: "",
            profile: {
                member_text: "",
                member_email: "",
                member_phone: "",
                kind: "",
                image: "",
                member_position: "",
                birth_date: "",
                team_id: "",
                region_id: "",
                province_id: "",
                warningModalOpen: false,
                warningText: "",
            },
            loginOptions: "",
        };
    }

    componentDidMount = () => {
        if (window.location.pathname !== "/maintain") {
            this.getServerChecker();
        }
        this.getCurrentUser();
        if (window.location.toString().split("/").indexOf("management") !== 3) {
            sessionStorage.removeItem("leftMenu");
        }
    };

    // fatch 동작하는지 확인용임.
    getServerChecker = () => {
        fetch(Common.backendUrl + `/api/servercheck`).catch((error) => {
            window.location.href = "/maintain";
        });
    };

    handleBtnClick = (e) => {
        if (document.querySelector("#miniMenu").style.display === "none") {
            document.querySelector("#miniMenu").style.display = "flex";
        } else {
            document.querySelector("#miniMenu").style.display = "none";
        }
    };

    handleBtnBlur = (e) => {
        e.preventDefault();
        document.querySelector("#miniMenu").style.display = "none";
    };

    handlelogoutClick = (e) => {
        e.preventDefault();
        sessionStorage.clear();
        document.location.href = "/";
        this.setState({ login: false });
    };

    handleCompetitionDetail = () => {
        fetch(Common.backendUrl + "/api/competition/lastone")
            .then((res) => res.json())
            .then((data) => {
                window.location.href = `/info/competition/detail/${data[0].competition_start_date.split("-")[0]}/${data[0].competition_type.competition_type}/${data[0].id}`;
            });
    };

    getCurrentUser() {
        if (sessionStorage.getItem("token")) {
            fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    Authorization: `JWT ${sessionStorage.getItem("token")}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    let image_file = "";
                    if (json.profile.image_file === null) {
                        image_file = "/images/default_profile.png";
                    } else {
                        // image_file = `${Common.backendUrl}${json.profile.image_file}`
                        image_file = `${json.profile.image_file}`;
                    }

                    let newProfile = {
                        member_text: json.profile.member_text,
                        member_email: json.profile.member_email,
                        member_phone: json.profile.member_phone,
                        kind: json.profile.kind,
                        image: image_file,
                        member_position: json.profile.member_position,
                        birth_date: json.profile.birth_date,
                        team_id: json.profile.team.id,
                        team_name: json.profile.team.team_text,
                        region_id: json.profile.team.region.id,
                        province_id: json.profile.team.region.province.id,
                    };

                    this.province_id = json.profile.team.region.province.id;
                    this.region_id = json.profile.team.region.id;

                    this.setState({
                        login: true,
                        member_pk: json.id,
                        profile: newProfile,
                    });
                });
        } else {
            this.setState({
                login: false,
            });
        }
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    loginDrodDownChange = (e, { value }) => {
        if (value === 1) {
            window.location.href = "/mypage";
        } else if (value === 2) {
            this.handlelogoutClick(e);
        }
    };

    render() {
        const { login, profile } = this.state;
        const { t } = this.props;

        const trigger = [
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Image src="/images/topmenu/icon_dronesoccer.png" width="28px" height="28px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.DroneSoccer.Text")}</p>
            </div>,
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Image src="/images/topmenu/icon_association.png" width="31px" height="31px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.Association.Text")}</p>
            </div>,
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Image src="/images/topmenu/icon_competition.png" width="25.5px" height="27.98px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.Competition.Text")}</p>
            </div>,
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Image src="/images/topmenu/icon_teams.png" width="32px" height="33px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.Teams.Text")}</p>
            </div>,
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Image src="/images/topmenu/icon_rank.png" width="29px" height="29px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.Rank.Text")}</p>
            </div>,
            <div style={{ width: "95px", height: "79px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", zIndex: "999" }}>
                <Image src="/images/topmenu/icon_noticeboard.png" width="33px" height="33px" />
                <p style={{ fontFamily: "Noto Sans KR", color: "white", marginTop: "10px", fontStyle: "normal", fontWeight: "bold", fontSize: "15px", lineHeight: "17px" }}>{t("Topmenu.Trigger.Board.Text")}</p>
            </div>,
        ];

        const loginOptions = [
            {
                key: 0,
                text: <span>{profile.member_text}</span>,
                value: 0,
                image: { avatar: true, size: "huge", src: profile.image },
            },
            {
                key: 1,
                text: "마이페이지",
                value: 1,
            },
            {
                key: 2,
                text: "로그아웃",
                value: 2,
            },
        ];

        return (
            <div className={styles.wrapper}>
                <Grid className={styles.topmenuWrap}>
                    {/* <Grid.Row columns="equal" textAlign="left" style={{ padding: "0px" }}> */}
                    <Grid.Row columns="equal" style={{ padding: "0px" }}>
                        <Grid.Column textAlign="left" className={styles.largeMenuLogoColumn}>
                            {/* <img className={styles.largeMenuLogo} src="/images/topmenu/menu_logo_new.png" onClick={() => window.location.href = "/"} /> */}
                            <img className={styles.largeMenuLogo} src="/images/topmenu/new_logo1.png" onClick={() => (window.location.href = "/")} />
                            <p style={{ marginLeft: "5px", fontFamily: "Noto Sans KR", color: "#ffffff", fontSize: "20px", fontWeight: "600", cursor: "pointer" }} onClick={() => (window.location.href = "/")}>
                                KDSA
                            </p>
                        </Grid.Column>
                        <Grid.Column className={styles.largeMenuDivColumn}>
                            <div className={styles.largeMenuDiv}>
                                <Dropdown simple icon={null} trigger={trigger[0]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/dronesoccer")} text={t("Topmenu.Trigger.DroneSoccer.DropDown.Introduction")} />
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/arena")} text={t("Topmenu.Trigger.DroneSoccer.DropDown.Arena")} />
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown simple icon={null} trigger={trigger[1]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/kdsa")} text={t("Topmenu.Trigger.Association.DropDown.Introduction")} />
                                        {/* <Dropdown.Item onClick={() => window.location.href = "/intro/msg/"} text={t('Topmenu.Trigger.Association.DropDown.Greeting')} /> */}
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/status/")} text={t("Topmenu.Trigger.Association.DropDown.Status")} />
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/mascot/")} text={t("Topmenu.Trigger.Association.DropDown.Mascot")} />
                                        <Dropdown.Item onClick={() => (window.location.href = "/intro/location/")} text={t("Topmenu.Trigger.Association.DropDown.WayToCome")} />
                                        {/* <Dropdown.Item onClick={() => window.location.href = "/press/list"} text={t('Topmenu.Trigger.Association.DropDown.press')} /> */}
                                        <Dropdown.Item onClick={() => (window.location.href = "/press/list")}>
                                            <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Association.DropDown.press") }}></div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown simple icon={null} trigger={trigger[2]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Notice")} onClick={() => (window.location.href = "/competition_notice/list")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Information")} onClick={() => (window.location.href = "/info/competition")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Entire")} onClick={() => this.handleCompetitionDetail()} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Picture")} onClick={() => (window.location.href = "/competition_picture/list")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Video")} onClick={() => (window.location.href = "/competition_video/list")} />
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown simple icon={null} trigger={trigger[3]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Club")} onClick={() => (window.location.href = "/info/team")} />
                                        <Dropdown.Item style={{ fontSize: "5px !important" }} text={t("Topmenu.Trigger.Teams.DropDown.National")} onClick={() => (window.location.href = "/info/nationalplayer")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Regional")} onClick={() => (window.location.href = "/info/regionalplayer")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Registration")} onClick={() => (window.location.href = "/apply/teamregistration")} />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown simple icon={null} trigger={trigger[4]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.Team")} onClick={() => (window.location.href = "/rank/team/")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.Player")} onClick={() => (window.location.href = "/rank/player")} /> {/*onClick={() => window.location.href = "/rank/player"} /> */}
                                        <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.YouthTeam")} onClick={() => this.handleWarningModalOpen("준비중 입니다.")} /> {/*onClick={() => window.location.href = "/rank/youthteam"} /> */}
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown simple icon={null} trigger={trigger[5]} className={styles.dropdown_tap}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Notice")} onClick={() => (window.location.href = "/notice/list")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.News")} onClick={() => (window.location.href = "/news/list")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Edu")} onClick={() => (window.location.href = "/edu/list")} />
                                        <Dropdown.Item onClick={() => (window.location.href = "/squad_board/list")}>
                                            <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.squad_board") }}></div>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => (window.location.href = "/information_exchange/list")}>
                                            <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.information_exchange") }}></div>
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => (window.location.href = "/reference_room/list")}>
                                            <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.reference_room") }}></div>
                                        </Dropdown.Item>
                                        <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.qna_board")} onClick={() => (window.location.href = "/qna_board/list")} />
                                        <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Free")} onClick={() => (window.location.href = "/free/list")} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Grid.Column>

                        <Grid.Column className={styles.miniMenuColumn}>
                            <div className={styles.miniMenuDiv}>
                                <Dropdown simple icon={null} trigger={[<Icon inverted name="bars" />]}>
                                    <Dropdown.Menu>
                                        <Dropdown text={t("Topmenu.Trigger.DroneSoccer.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/dronesoccer/")} text={t("Topmenu.Trigger.DroneSoccer.DropDown.Introduction")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/arena")} text={t("Topmenu.Trigger.DroneSoccer.DropDown.Arena")} />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown text={t("Topmenu.Trigger.Association.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/kdsa")} text={t("Topmenu.Trigger.Association.DropDown.Introduction")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/status/")} text={t("Topmenu.Trigger.Association.DropDown.Status")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/mascot/")} text={t("Topmenu.Trigger.Association.DropDown.Mascot")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/intro/location/")} text={t("Topmenu.Trigger.Association.DropDown.WayToCome")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/press/list")}>
                                                    <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Association.DropDown.press") }}></div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown text={t("Topmenu.Trigger.Competition.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Notice")} onClick={() => (window.location.href = "/competition_notice/list")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Information")} onClick={() => (window.location.href = "/info/competition")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Entire")} onClick={() => this.handleCompetitionDetail()} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Picture")} onClick={() => (window.location.href = "/competition_picture/list")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Competition.DropDown.Video")} onClick={() => (window.location.href = "/competition_video/list")} />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown text={t("Topmenu.Trigger.Teams.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Club")} onClick={() => (window.location.href = "/info/team")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.National")} onClick={() => (window.location.href = "/info/nationalplayer")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Regional")} onClick={() => (window.location.href = "/info/regionalplayer")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Teams.DropDown.Registration")} onClick={() => (window.location.href = "/apply/teamregistration")} />
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown text={t("Topmenu.Trigger.Rank.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.Team")} onClick={() => (window.location.href = "/rank/team/")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.Player")} onClick={() => (window.location.href = "/rank/player")} /> {/*onClick={() => window.location.href = "/rank/player"} /> */}
                                                <Dropdown.Item text={t("Topmenu.Trigger.Rank.DropDown.YouthTeam")} onClick={() => this.handleWarningModalOpen("준비중 입니다.")} /> {/*onClick={() => window.location.href = "/rank/youthteam"} /> */}
                                            </Dropdown.Menu>
                                        </Dropdown>

                                        <Dropdown text={t("Topmenu.Trigger.Board.Text")} simple icon={null} className={`${styles.dropdown_tap} link item`}>
                                            <Dropdown.Menu>
                                                <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Notice")} onClick={() => (window.location.href = "/notice/list")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.News")} onClick={() => (window.location.href = "/news/list")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Edu")} onClick={() => (window.location.href = "/edu/list")} />
                                                <Dropdown.Item onClick={() => (window.location.href = "/squad_board/list")}>
                                                    <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.squad_board") }}></div>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => (window.location.href = "/information_exchange/list")}>
                                                    <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.information_exchange") }}></div>
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => (window.location.href = "/reference_room/list")}>
                                                    <div className={styles.longTitle} style={{ width: "95px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} dangerouslySetInnerHTML={{ __html: t("Topmenu.Trigger.Board.DropDown.reference_room") }}></div>
                                                </Dropdown.Item>
                                                <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.qna_board")} onClick={() => (window.location.href = "/qna_board/list")} />
                                                <Dropdown.Item text={t("Topmenu.Trigger.Board.DropDown.Free")} onClick={() => (window.location.href = "/free/list")} />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {login ? (
                                            <>
                                                <Divider />
                                                <Dropdown text={t("Topmenu.Button.MyPage")} simple icon={null} className={`${styles.dropdown_tap} link item`} onClick={() => (window.location.href = "/mypage")}></Dropdown>
                                                <Dropdown text={t("Topmenu.Button.Logout")} simple icon={null} className={`${styles.dropdown_tap} link item`} onClick={(e) => this.handlelogoutClick(e)}></Dropdown>
                                            </>
                                        ) : (
                                            <>
                                                <Divider />
                                                <Dropdown text={t("Topmenu.Button.Login")} simple icon={null} className={`${styles.dropdown_tap} link item`} onClick={() => (window.location.href = "/login")}></Dropdown>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <img src="/images/topmenu/menu_logo.png" onClick={() => window.location.href = "/"} width="60px" /> */}
                            </div>
                        </Grid.Column>

                        <Grid.Column className={styles.smallLogoColumn} textAlign="center" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            <img src="/images/topmenu/new_logo_s.png" onClick={() => (window.location.href = "/")} />
                            <p style={{ marginLeft: "5px", fontFamily: "Noto Sans KR", color: "#ffffff", fontSize: "20px", fontWeight: "600", cursor: "pointer" }} onClick={() => (window.location.href = "/")}>
                                KDSA
                            </p>
                        </Grid.Column>

                        <Grid.Column style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                            <div className={styles.login_dropdown} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                {login ? (
                                    <Dropdown inline options={loginOptions} defaultValue={0} onChange={this.loginDrodDownChange} />
                                ) : (
                                    <div style={{ width: "100%" }}>
                                        <a className={styles.style_a1} href="/login">
                                            <span style={{ color: "white", display: "flex" }}>{t("Topmenu.Button.Login")}</span>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </Grid.Column>

                        <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation()(Topmenu);
