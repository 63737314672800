import React, { Component } from "react";
import TeamDetail from "./TeamDetail.js";
import TeamMember from "./TeamMember.js";
import TeamModalForm from "./TeamModalForm.js";
import TeamSearch from "./TeamSearch.js";
import PlayerSearch from "./PlayerSearch.js";

import {
  Container,
  Dropdown,
  Grid,
  Button,
  Icon,
  Label,
  Divider,
} from "semantic-ui-react";
import Common from "../../../Common";

class TeamManager extends Component {
  constructor() {
    super();
    this.state = {
      provinceData: "",
      provinceOptions: "",
      regionOptions: "",
      teamOptions: "",
      selProvinceId: 1001,
      selRegionId: 1001,
      selTeamId: "",
      teamData: "",
      requestsCnt: 0,
      registrationCnt: 0,
    };

    this.getRegionData = this.getRegionData.bind(this);
    this.getTeamData = this.getTeamData.bind(this);
  }

  getProvinceData() {
    fetch(Common.backendUrl + "/api/province/manage")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ provinceData: data });
        let arr = [];
        data.forEach((province) =>
          arr.push({
            key: province.id,
            text: province.province_text,
            value: province.id,
          })
        );
        this.setState({ provinceOptions: arr });
      });
  }

  getRegionData(province_id) {
    fetch(Common.backendUrl + "/api/region/" + province_id)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let regions = [];
        for (let region of data) {
          regions.push({
            key: region.id,
            text: region.region_text,
            value: region.id,
          });
        }
        this.setState({ regionOptions: regions });
      });
  }

  getTeamData(region_id) {
    fetch(Common.backendUrl + "/api/team/region/" + region_id)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.state.teamData = data;
        let arr = new Array();
        data.forEach((team) =>
          arr.push({ key: team.id, text: team.team_text, value: team.id })
        );
        this.setState({ teamOptions: arr });
      });
  }

  getTeamRequestsData() {
    fetch(Common.backendUrl + "/api/team/requests/manage/count")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          requestsCnt: data.count,
        });
      });
  }

  getTeamRegistrationData() {
    fetch(Common.backendUrl + "/api/teamregistration/list/0")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          registrationCnt: data.count,
        });
      });
  }

  provinceChange = (e, { value }) => {
    this.setState({ selProvinceId: value });
    this.getRegionData(value);
  };

  regionChange = (e, { value }) => {
    this.setState({ selRegionId: value });
    this.getTeamData(value);
  };

  teamChange = (e, { value }) => {
    this.setState({ selTeamId: value });
  };

  provinceChangeSearch = (e, id) => {
    this.setState({ selProvinceId: id });
    this.getRegionData(id);
  };

  regionChangeSearch = (e, id) => {
    this.setState({ selRegionId: id });
    this.getTeamData(id);
  };

  teamChangeSearch = (e, id) => {
    this.setState({ selTeamId: id });
  };

  componentDidMount() {
    this.getProvinceData();
    this.getRegionData(1001);
    this.getTeamData(1001);
    this.getTeamRequestsData();
    this.getTeamRegistrationData();
  }

  render() {
    return (
      <div>
        <Container
          style={{ paddingLeft: "5em", paddingRight: "5em", height: "100%" }}
          fluid
        >
          <Grid divided="vertically">
            <Grid.Row columns={"equal"} divided>
              <Grid.Column textAlign="center">
                <TeamModalForm
                  province_id={this.state.selProvinceId}
                  region_id={this.state.selRegionId}
                  reloadPage={this.getTeamData}
                />
              </Grid.Column>
            </Grid.Row>

            {this.props.leftMenu === "선수/선수단 관리" ? (
              <Grid.Row columns={"equal"}>
                <Grid.Column>
                  <Dropdown
                    style={{ border: "1px solid #000000" }}
                    onChange={this.provinceChange}
                    options={this.state.provinceOptions}
                    defaultValue={1001}
                    selection
                    placeholder="지회목록"
                    fluid
                  />
                </Grid.Column>

                <Grid.Column>
                  <Dropdown
                    style={{ border: "1px solid #000000" }}
                    onChange={this.regionChange}
                    options={this.state.regionOptions}
                    defaultValue={1001}
                    selection
                    placeholder="지부목록"
                    fluid
                  />
                </Grid.Column>

                <Grid.Column>
                  {this.state.teamOptions.length === 0 && (
                    <Dropdown
                      style={{ border: "1px solid #000000" }}
                      onChange={this.teamChange}
                      options={this.state.teamOptions}
                      selection
                      placeholder="등록된 선수단 없음"
                      fluid
                    />
                  )}
                  {this.state.teamOptions.length > 0 && (
                    <Dropdown
                      style={{ border: "1px solid #000000" }}
                      onChange={this.teamChange}
                      options={this.state.teamOptions}
                      selection
                      placeholder="선수단목록"
                      fluid
                      search
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            ) : (
              <></>
            )}

            {this.props.leftMenu === "선수/선수단 검색" ? (
              <Grid.Row columns="equal">
                <Grid.Column>
                  <TeamSearch
                    changeProvince={this.provinceChangeSearch}
                    changeRegion={this.regionChangeSearch}
                    teamChange={this.teamChangeSearch}
                  />
                </Grid.Column>
                <Grid.Column>
                  <PlayerSearch
                    changeProvince={this.provinceChangeSearch}
                    changeRegion={this.regionChangeSearch}
                    teamChange={this.teamChangeSearch}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <></>
            )}

            <Grid.Row columns={1}>
              <Grid.Column>
                <TeamDetail
                  province_id={this.state.selProvinceId}
                  region_id={this.state.selRegionId}
                  team_id={this.state.selTeamId}
                  reloadPage={this.getTeamData}
                />
              </Grid.Column>
            </Grid.Row>

            {/* <Grid.Row columns={1}>
              <Grid.Column>
                <PlayerSearch />
              </Grid.Column>
            </Grid.Row> */}

            <Grid.Row columns={1}>
              <Grid.Column>
                <TeamMember
                  province_id={this.state.selProvinceId}
                  region_id={this.state.selRegionId}
                  team_id={this.state.selTeamId}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default TeamManager;
