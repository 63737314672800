import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

import {
  Modal,
  Button,
  Input,
  Dropdown,
  Form,
  TextArea,
} from "semantic-ui-react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from "../../../Common";

const daum = window.daum;

class TeamModalForm extends Component {
  state = {
    provinceId: "",
    provinceData: "",
    provinceOptions: "",
    regionId: "",
    regionOptions: "",
    teamId: "",
    leagueId: "",
    teamOptions: "",
    teamName: "",
    address: "",
    membership: "",
    phone: "",
    regDate: "",
    image_file: "",
    teamNameDupCheck: false,
    modal_open: false,
    warningModalOpen: false,
    warningText: "",
  };

  getProvinceList() {
    fetch(Common.backendUrl + "/api/province/manage")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ provinceData: data });
        let arr = [];
        data.forEach((province) =>
          arr.push({
            key: province.id,
            text: province.province_text,
            value: province.id,
          })
        );
        this.setState({ provinceOptions: arr });
      });
  }

  getRegionData(province_id) {
    fetch(Common.backendUrl + "/api/region/" + province_id)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let regions = [];
        let regionDict = {};
        for (let region of data) {
          regions.push({
            key: region.id,
            text: region.region_text,
            value: region.id,
          });
          regionDict[region.id] = region.region_text;
        }
        this.setState({
          regionOptions: regions,
          regionData: regionDict,
        });
      });
  }

  getMembershipList() {
    let arr = [];
    const membership = [
      { id: 0, membership: "일반회원" },
      { id: 1, membership: "정회원" },
    ];
    membership.forEach((data) =>
      arr.push({ key: data.id, text: data.membership, value: data.id })
    );
    this.setState({ membershipOptions: arr });
  }

  getLeagueList() {
    fetch(Common.backendUrl + "/api/league/")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let arr = [];
        data.forEach((league) =>
          arr.push({
            key: league.id,
            text: league.league_text,
            value: league.id,
          })
        );
        this.setState({ leagueOptions: arr });
      });
  }

  componentDidMount() {
    this.getProvinceList();
    this.getRegionData(this.props.province_id);
    this.getMembershipList();
    this.getLeagueList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.province_id !== prevProps.province_id) {
      this.getRegionData(this.props.province_id);
    }
  }

  handleProvinceChange = (e, { value }) => {
    this.setState({ provinceId: value });
    this.getRegionData(value);
  };

  handleRegionChange = (e, { value }) => {
    this.setState({ regionId: value });
  };

  dateHandleChange = (event, data) => {
    const checkDate = data.value;
    let strDate = "";
    if (checkDate !== null) {
      if (checkDate.getDate() < 10) {
        strDate =
          checkDate.getFullYear() +
          "-" +
          (checkDate.getMonth() + 1) +
          "-0" +
          checkDate.getDate();
      } else {
        strDate =
          checkDate.getFullYear() +
          "-" +
          (checkDate.getMonth() + 1) +
          "-" +
          checkDate.getDate();
      }
      this.setState({
        regDate: strDate,
      });
    }
  };

  searchAddr(objStr) {
    new daum.Postcode({
      oncomplete: function (data) {
        var addr = ""; // 주소 변수
        var extraAddr = ""; // 참고항목 변수

        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          addr = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          addr = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if (data.userSelectedType === "R") {
          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
            extraAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== "" && data.apartment === "Y") {
            extraAddr +=
              extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraAddr !== "") {
            extraAddr = " (" + extraAddr + ")";
          }
          // 조합된 참고항목을 해당 필드에 넣는다.
        } else {
        }
        document.getElementById(objStr).value =
          "(" + data.zonecode + ") " + addr + " " + extraAddr;
        // this.setState({ address: "(" + data.zonecode + ") " + addr + " " + extraAddr })
        // 우편번호와 주소 정보를 해당 필드에 넣는다.
      }.bind(this),
    }).open();
  }

  handleImageChange = (e) => {
    this.setState({
      image_file: e.target.files[0],
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    let region_id = "";

    if (document.getElementById("membership").innerText === "회원구분") {
      this.setState({
        warningModalOpen: true,
        warningText: "회원구분을 선택해주세요.",
      });
      return;
    } else if (
      document.getElementById("league_text").innerText === "리그를 선택하세요"
    ) {
      this.setState({
        warningModalOpen: true,
        warningText: "리그를 선택해주세요.",
      });
      return;
    } else if (this.state.teamNameDupCheck == false) {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명 중복 확인을 해주세요.",
      });
      return;
    } else if (
      regExp.test(document.getElementById("applicantEmailInput").value) ===
      false
    ) {
      this.setState({
        warningModalOpen: true,
        warningText: "잘못된 이메일입니다.",
      });
      return;
    }

    if (this.state.regionId === "") {
      region_id = this.props.region_id;
    } else {
      region_id = this.state.regionId;
    }

    if (document.getElementById("teamPhoneNum").value === "") {
      this.handleWarningModalOpen("핸드폰 번호 11자리를 입력해주세요");
      return;
    }

    let form_data = new FormData();

    if (document.getElementById("membership").innerText === "정회원") {
      form_data.append("membership", 1);
    } else if (document.getElementById("membership").innerText === "일반회원") {
      form_data.append("membership", 0);
    }
    if (document.getElementById("league_text").innerText === "1부리그") {
      form_data.append("league_id", 1);
    } else if (document.getElementById("league_text").innerText === "2부리그") {
      form_data.append("league_id", 2);
    } else if (document.getElementById("league_text").innerText === "3부리그") {
      form_data.append("league_id", 3);
    } else if (
      document.getElementById("league_text").innerText === "유소년 리그"
    ) {
      form_data.append("league_id", 4);
    }
    form_data.append("team_text", document.getElementById("team_text").value);
    form_data.append("address", document.getElementById("address").value);
    form_data.append(
      "address_detail",
      document.getElementById("address_detail").value
    );
    form_data.append("region_id", region_id);
    form_data.append(
      "team_phone",
      document.getElementById("teamPhoneNum").value
    );

    if (this.state.regDate.length !== 0) {
      form_data.append("reg_date", this.state.regDate);
    }

    if (document.getElementById("info_text").value.length !== 0) {
      form_data.append("info_text", document.getElementById("info_text").value);
    }
    if (this.state.image_file !== "") {
      form_data.append("team_image", this.state.image_file.name);
      form_data.append("team_image_file", this.state.image_file);
    }

    fetch(Common.backendUrl + "/api/team/make", {
      method: "post",
      body: form_data,
    })
      .then((response) => {
        if (response.status >= 400) {
          alert(response.status);
          return response.json();
        } else {
          return 200;
        }
      })
      .then((json) => {
        if (json != undefined) {
          if (json === 200) {
            this.handleWarningModalOpen("선수단 등록 완료");
            this.setState({ modal_open: false });
            this.props.reloadPage(this.props.region_id);
            return;
          }
          this.handleWarningModalOpen(json.detail);
        } else {
          this.handleWarningModalOpen("error");
        }
      });
  };

  teamNameDuplicateCheck = (e) => {
    e.preventDefault();

    const pattern_spc = /[~!@#$%^&`*()_=+<>/'"?₩,.;:|[{}]/;

    if (document.getElementById("team_text").value === "") {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명을 작성해주세요",
      });
      return;
    } else if (document.getElementById("team_text").value.search(/\s/) != -1) {
      this.setState({
        warningModalOpen: true,
        warningText: "선수단명 공백을 제거해주세요.",
      });
      return;
    } else if (document.getElementById("team_text").value.length > 10) {
      this.setState({
        warningModalOpen: true,
        warningText: "10자 이내의 선수단명을 입력해주세요.",
      });
      return;
    } else if (pattern_spc.test(document.getElementById("team_text").value)) {
      this.setState({
        warningModalOpen: true,
        warningText: "'-' 를 제외한 특수문자는 입력할 수 없습니다.",
      });
      return;
    } else {
      fetch(
        Common.backendUrl +
          `/api/teamregistration/check/duplicate1/${
            document.getElementById("team_text").value
          }`
      )
        .then((response) => {
          if (response.status > 400) {
            return this.setState(() => {
              return { placeholder: "Something went wrong!" };
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data === undefined) {
            this.setState({
              warningModalOpen: true,
              warningText: "잘못된 선수단명입니다.",
              teamNameDupCheck: false,
            });
            return;
          }
          if (data.length === 0) {
            this.setState({
              warningModalOpen: true,
              warningText: "사용 가능한 선수단명입니다.",
              teamNameDupCheck: true,
            });
            // this.teamNameDuplicateCheck2(document.getElementById('team_text').value)
          } else {
            this.setState({
              warningModalOpen: true,
              warningText: "이미 사용중인 선수단명입니다.",
              teamNameDupCheck: false,
            });
          }
        });
    }
  };

  // teamNameDuplicateCheck2 = (team_text) => {
  //     fetch(Common.backendUrl + `/api/teamregistration/check/duplicate2/${team_text}`)
  //     .then(response => {
  //         if (response.status > 400) {
  //             return this.setState(() => {
  //                 return { placeholder: "Something went wrong!" };
  //             });
  //         }
  //                 return response.json();
  //             }) .then(data => {
  //                 if (data.length === 0) {
  //                     this.setState({
  //                         warningModalOpen: true,
  //                         warningText: "사용 가능한 선수단명입니다.",
  //                         teamNameDupCheck: true,
  //                     })
  //                 } else {
  //                     this.setState({
  //                         warningModalOpen: true,
  //                         warningText: "이미 사용중인 선수단명입니다.",
  //                         teamNameDupCheck: false,
  //                     })
  //                 }
  //             })
  // }

  handleWarningModalOpen = (text) => {
    this.setState({
      warningModalOpen: true,
      warningText: text,
    });
  };

  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    });
  };

  render() {
    return (
      <>
        <Modal
          size={"tiny"}
          onClose={() => this.setState({ modal_open: false })}
          onOpen={() => this.setState({ modal_open: true })}
          open={this.state.modal_open}
          trigger={<Button primary>선수단 등록</Button>}
        >
          <Modal.Header>선수단정보 입력</Modal.Header>
          <Modal.Content>
            <div className="column">
              <Form onSubmit={this.handleSubmit}>
                <Form.Field required>
                  <label className="label">지회</label>
                  <Dropdown
                    placeholder="지회 선택"
                    selection
                    options={this.state.provinceOptions}
                    name="province"
                    defaultValue={this.props.province_id}
                    onChange={this.handleProvinceChange}
                    fluid
                  />
                </Form.Field>
                <Form.Field required>
                  <label className="label">지부</label>
                  <Dropdown
                    placeholder="지부 선택"
                    selection
                    options={this.state.regionOptions}
                    name="region_text"
                    defaultValue={this.props.region_id}
                    onChange={this.handleRegionChange}
                    fluid
                  />
                </Form.Field>
                <Form.Field required style={{ margin: "0px" }}>
                  <div>
                    <label
                      style={{
                        display: "inline-block",
                        margin: "0 0 .28571429rem 0",
                        color: "rgba(0,0,0,.87)",
                        fontSize: ".92857143em",
                        fontWeight: "700",
                        textTransform: "none",
                      }}
                    >
                      선수단명
                      <span style={{ color: "#db2828", fontWeight: "bold" }}>
                        *
                      </span>
                    </label>
                    <Button
                      color="blue"
                      inverted
                      size="tiny"
                      style={{
                        marginLeft: "10px",
                        padding: "5px",
                        fontFamily: "Noto Sans KR",
                      }}
                      onClick={(e) => this.teamNameDuplicateCheck(e)}
                    >
                      중복 확인
                    </Button>
                  </div>
                  <Input
                    type="text"
                    name="team_text"
                    id="team_text"
                    placeholder="공백 없이 10자 이내로 입력하세요."
                    onChange={() => this.setState({ teamNameDupCheck: false })}
                    required
                  />
                </Form.Field>
                <label style={{ display: "block" }}>
                  ※ 선수단명에 드론축구단, 선수단 등의 단어입력 불필요 (예:
                  전주시 드론축구단 -&gt; 전주시)
                </label>
                <label style={{ display: "block", margin: "0 0 1em" }}>
                  ※ 시 공식 창단 선수단만 "시" 명칭 사용 가능 (예:
                  과천시드론축구단 -&gt; 과천)
                </label>
                <Form.Field required style={{ margin: "0px" }}>
                  <label className="label">회원구분</label>
                  <Dropdown
                    placeholder="회원구분"
                    selection
                    options={[
                      { key: 0, text: "일반회원", value: 0 },
                      { key: 1, text: "정회원", value: 1 },
                    ]}
                    name="membership"
                    id="membership"
                    fluid
                  />
                </Form.Field>
                <label style={{ display: "block", margin: "0 0 1em" }}>
                  ※ 정회원은 협회에 가입비와 연회비가 납부되어 있어야 승인이
                  가능합니다.
                </label>
                <Form.Field>
                  <label className="label">팀창단일</label>
                  <SemanticDatepicker
                    name="reg_date"
                    onChange={this.dateHandleChange}
                  />
                </Form.Field>
                <Form.Field required style={{ margin: "0px" }}>
                  <label className="label">리그</label>
                  <Dropdown
                    placeholder="리그를 선택하세요"
                    selection
                    // options={this.state.leagueOptions}
                    options={[
                      { key: 0, text: "1부리그", value: 1 },
                      { key: 1, text: "2부리그", value: 2 },
                      { key: 2, text: "3부리그", value: 3 },
                      { key: 3, text: "유소년 리그", value: 4 },
                    ]}
                    name="league_text"
                    id="league_text"
                    fluid
                  />
                </Form.Field>
                <label style={{ display: "block", margin: "0 0 1em" }}>
                  ※ 유소년 리그 외 신규팀은 3부리그로 입력하세요.
                </label>
                <Form.Field required style={{ margin: "0px" }}>
                  <label className="label">대표주소</label>
                  <Input
                    style={{ margin: "0 0 0.1em" }}
                    type="text"
                    name="address"
                    id="address"
                    placeholder="대표주소를 입력해주세요."
                    // onChange={(e, { value }) => this.setState({ address: value })}
                    onClick={function (e) {
                      this.searchAddr("address");
                    }.bind(this)}
                    required
                  />
                  <Input
                    type="text"
                    name="address_detail"
                    id="address_detail"
                    placeholder="상세주소를 입력해주세요."
                    required
                  />
                </Form.Field>
                <label style={{ display: "block", margin: "0 0 1em" }}>
                  ※ 우편물 수령이 가능한 주소를 입력하세요.
                </label>
                <Form.Field required>
                  <label>선수단장 정보</label>
                  <span
                    style={{
                      fontSize: "13px",
                      fontFamily: "Noto Sans KR",
                      fontWeight: "400",
                      lineHeight: "1.4285em",
                    }}
                  >
                    이름
                  </span>
                  <Input
                    id="applicantNameInput"
                    placeholder="이름을 입력하세요"
                    type="text"
                    name="name"
                    // onChange={(e, {value}) => this.setState({applicantName: value})}
                    required
                  />
                  <span
                    style={{
                      fontSize: "13px",
                      fontFamily: "Noto Sans KR",
                      fontWeight: "400",
                      lineHeight: "1.4285em",
                    }}
                  >
                    이메일
                  </span>
                  <Input
                    id="applicantEmailInput"
                    placeholder="dronesoccer@naver.com"
                    type="text"
                    name="email"
                    required
                  />
                  <span
                    style={{
                      fontSize: "13px",
                      fontFamily: "Noto Sans KR",
                      fontWeight: "400",
                      lineHeight: "1.4285em",
                    }}
                  >
                    전화번호
                  </span>
                  <Input
                    id="teamPhoneNum"
                    placeholder="-을 제외한 11자리 번호입력"
                    type="text"
                    name="phone"
                    required
                  />
                </Form.Field>
                <Form.Field>
                  <label className="label">
                    팀 로고 이미지 (500x500 ~ 800x800 사이즈)
                  </label>
                  <Input
                    type="file"
                    name="profile"
                    onChange={this.handleImageChange}
                    accept="image/png, image/jpeg"
                  />
                </Form.Field>
                <Form.Field>
                  <label className="label">선수단 소개</label>
                  <TextArea
                    style={{ resize: "none", height: "60px" }}
                    id="info_text"
                    placeholder="선수단을 자유롭게 소개해주세요."
                  />
                </Form.Field>
                <Button positive type="submit" className="button is-info">
                  등록하기
                </Button>
                <Button
                  color="black"
                  onClick={() => this.setState({ modal_open: false })}
                >
                  취소
                </Button>
              </Form>
            </div>
          </Modal.Content>
        </Modal>
        <ModalChoiceOne
          isOpen={this.state.warningModalOpen}
          warningText={this.state.warningText}
          closeMethod={this.handleWarningModalClose}
        />
      </>
    );
  }
}

export default TeamModalForm;
