import React, { Component } from "react";

import {
    Button,
    Modal,
    Input,
    Dropdown,
    Form,
} from 'semantic-ui-react'
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from '../../../Common';

const daum = window.daum;

class RegionModalForm extends Component {
    state = {
        regionText: "",
        chiefName: "",
        email: "",
        phone: "",
        provinceId: "",
        provinceOptions: "",
        modal_open: false,
        warningModalOpen: false,
        warningText: "",
        teamName: "",
        image_file: "",
        isReLoad:false,
    };

    getProvinceList() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                this.setState({ provinceData: data });
                let arr = [];
                data.forEach(province => arr.push({ key: province.id, text: province.province_text, value: province.id }))
                this.setState({ provinceOptions: arr });
            });
    }

    componentDidMount() {
        this.getProvinceList();
    }

    handleProvinceChange = (e, { value }) => {
        this.setState({ provinceId: value });
    };

    handleRegionChange = (e, { value }) => {
        this.setState({ regionText: value });
    };

    handleChiefChange = (e, { value }) => {
        this.setState({ chiefName: value });
    };

    handleEmailChange = (e, { value }) => {
        this.setState({ email: value });
    };

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;
        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3)
            let middleNum = checkPhone.slice(3, 7)
            let lastNum = checkPhone.slice(7, 11)
            const strPhone = firstNum + '-' + middleNum + '-' + lastNum
            this.setState({
                phone: strPhone
            });
        }
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0]
        })
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamName: value });
    };

    handleSubmit = e => {
        e.preventDefault();
        let province_id = 0

        let form_data = new FormData();
        if (this.state.provinceId === "") {
            this.handleWarningModalOpen("지회를 선택해주세요.");
            return;
        }
        
        form_data.append('province_id', this.state.provinceId)
        form_data.append('region_text', this.state.regionText)
        form_data.append('chief_name', this.state.chiefName)
        form_data.append('email_text', this.state.email)
        form_data.append('phone_text', this.state.phone)
        form_data.append('team', this.state.teamName)
        // form_data.append('cheif_image', this.state.image_file)

        fetch(Common.backendUrl + "/api/region/make/", {
            method: 'post',
            body: form_data
        })
            .then(response => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen("지부정보를 다시 확인해주세요.");
                    this.setState({ modal_open: false });
                } else {
                    this.handleWarningModalOpen("지부 등록 완료");
                    this.setState({ modal_open: false, isReLoad: true });
                    // this.props.reloadPage(province_id);
                    window.location.reload();
                    return response.json();
                }
            })
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
        if(this.state.isReLoad) {
            window.location.reload();
        }
        
    }

    render() {
        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button width='100px' primary>지부등록</Button>}
                >
                    <Modal.Header>지부정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field required>
                                    <label className="label">지회</label>
                                    <Dropdown
                                        placeholder='지회를 선택하세요'
                                        selection options={this.state.provinceOptions}
                                        name="province"
                                        onChange={this.handleProvinceChange}
                                        fluid
                                        required
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">지부명</label>
                                    <Input
                                        type="text"
                                        name="region_text"
                                        onChange={this.handleRegionChange}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">지부장</label>
                                    <Input
                                        type="text"
                                        name="chief_name"
                                        onChange={this.handleChiefChange}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">선수단명</label>
                                    <Input
                                        type="text"
                                        name="team_text"
                                        onChange={this.handleTeamChange}
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label><span>이메일</span></label>
                                    <Input
                                        placeholder='dronesoccer@naver.com'
                                        type="text"
                                        name="email"
                                        maxLength='40'
                                        onChange={this.handleEmailChange}
                                        required
                                    />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">전화번호</label>
                                    <Input
                                        type="text"
                                        name="phone_text"
                                        placeholder="전화번호를 입력해주세요(-생략)"
                                        onChange={this.handlePhoneChange}
                                        required
                                    />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">등록하기</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default RegionModalForm