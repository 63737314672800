import React, { Component } from "react";

import {
    Button,
    Modal,
    Input,
    Form,
    Header, 
    Dropdown,
} from 'semantic-ui-react'

import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import Common from '../Common';

class CompetitionMatchInfoModalForm extends Component {
    state = {
        modal_open: false,
        first_red_score : 0,
        first_blue_score : 0,
        second_red_score : 0,
        second_blue_score : 0,
        third_red_score : 0,
        third_blue_score : 0,
        game_state: "",
        match_stadium: "",
        match_date: "",
        winner: "",
        video_url: "",
        warningModalOpen: false,
        warningText: "",
    };

    handleFirstRedScoreChange = (e, { value }) => {
        this.setState({ first_red_score: value });
    };

    handleFirstBlueScoreChange = (e, { value }) => {
        this.setState({ first_blue_score: value });
    };

    handleSecondRedScoreChange = (e, { value }) => {
        this.setState({ second_red_score: value });
    };

    handleSecondBlueScoreChange = (e, { value }) => {
        this.setState({ second_blue_score: value });
    };

    handleThirdRedScoreChange = (e, { value }) => {
        this.setState({ third_red_score: value });
    };

    handleThirdBlueScoreChange = (e, { value }) => {
        this.setState({ third_blue_score: value });
    };

    handleGameStateChange = (e, { value }) => {
        this.setState({ game_state: value });
    };

    handleMatchStadiumChange = (e, { value }) => {
        this.setState({ match_stadium: value });
    };

    handleWinnerChange = (e, { value }) => {
        this.setState({ winner: value });
    };

    handleVideoUrlChange = (e, { value }) => {
        this.setState({ video_url: value });
    };

    handleMatchDateChange = date => {
        const valueOfInput = date
        if (valueOfInput._isAMomentObject){
            const dateTime = valueOfInput.format();
            let dateSplit = dateTime.split('T')
            let timeSplit = dateSplit[1].split('+')
            
            let value = dateSplit[0] + ' ' + timeSplit[0]
            this.setState({match_date: value})
        } else {
            return;
        }
    };

    toDateTime(dateStr){
        if (dateStr){
            let parts = dateStr.split('-')
            let monthNames = ["January", "February", "March", "April", "May", "June",
                              "July", "August", "September", "October", "November", "December"]
            let dayParts = parts[2].split('T')
            let day = dayParts[0]                 
          
            let d = day + " " + monthNames[parseInt(parts[1]) -1] + " " + parts[0]
            
            return new Date(d)
        }else{
            return;
        } 
    }
    
    handleSubmit = e => {
        e.preventDefault();
        
        let form_data = new FormData();
        
        if (this.state.first_red_score !== 0 || this.state.first_blue_score !== 0){
            form_data.append('first_set_score', this.state.first_red_score + "-" + this.state.first_blue_score);
        } 

        if (this.state.second_red_score !== 0 || this.state.second_blue_score !== 0){
            form_data.append('second_set_score', this.state.second_red_score + "-" + this.state.second_blue_score);
        } 

        if (this.state.third_red_score !== 0 || this.state.third_blue_score !== 0){
            form_data.append('third_set_score', this.state.third_red_score + "-" + this.state.third_blue_score);
        } 
        
        if (this.state.game_state !== ""){
            form_data.append('game_state', this.state.game_state);
        }

        if (this.state.match_stadium !== ""){
            form_data.append('match_stadium', this.state.match_stadium);
        } 
        
        if (this.state.match_date !== ""){
            form_data.append('match_date', this.state.match_date);
        }

        if (this.state.winner !== ""){
            form_data.append('winner_id', this.state.winner);
        } 

        if (this.state.video_url !== ""){
            form_data.append('video_url', this.state.video_url);
        } 
        
        form_data.append('id', this.props.game_id)

        fetch(Common.backendUrl + "/api/match/update/" + this.props.game_id, {
            method: "put",
            body : form_data,
        })
            .then(response => {
                if (response.status >= 400){
                    this.handleWarningModalOpen("error");
                    //this.setState({ modal_open: false });
                    return;
                }else {
                    this.setState({ modal_open: false });
                    this.handleWarningModalOpen("업데이트 완료");
                    this.props.reloadPage();
                    return;
                }
            })
    };

    
    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
      handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        let first_red_score = 0
        let first_blue_score = 0

        let second_red_score = 0
        let second_blue_score = 0

        let third_red_score = 0
        let third_blue_score = 0

        if(this.props.first_set_score != null){
            let parse = this.props.first_set_score.split("-")
            first_red_score= parseInt(parse[0])
            first_blue_score = parseInt(parse[1])
        }

        if(this.props.second_set_score != null){
            let parse = this.props.second_set_score.split("-")
            second_red_score= parseInt(parse[0])
            second_blue_score = parseInt(parse[1])
        }

        if(this.props.third_set_score != null){
            let parse = this.props.third_set_score.split("-")
            third_red_score= parseInt(parse[0])
            third_blue_score = parseInt(parse[1])
        }

        let stateOptions = []
        const game_state = [{ id: 0, game_state: "경기 예정" }, { id: 1, game_state: "경기 진행 중" }, { id: 2, game_state: "경기 종료" }]
        game_state.forEach(data => stateOptions.push({ key: data.id, text: data.game_state, value: data.id }))
        
        let victoryTeams = []
        const victory_team = [{ id: this.props.team_red.id, victory_team: this.props.team_red.team_text }, { id: this.props.team_blue.id, victory_team: this.props.team_blue.team_text } ]
        victory_team.forEach(data => victoryTeams.push({ key: data.id, text: data.victory_team, value: data.id }))

        let defaultWinner = 0
       
        if (this.props.winner !== null){
            defaultWinner = this.props.winner.id
        }
        
        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button primary style={{ margin: '0px 0px 0px 0px' }} size='mini'>설정</Button>}
                >
                    <Modal.Header>경기정보 업데이트</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Header as='h4'>1세트</Header>
                                <Form.Group>
                                    <Form.Field>
                                        <label className="label">{this.props.team_red.team_text}</label>
                                        <Input type='number' defaultValue={first_red_score} name="red_score" onChange={this.handleFirstRedScoreChange} required />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className="label">{this.props.team_blue.team_text}</label>
                                        <div style={{display:'flex'}}>
                                        <Input type='number' defaultValue={first_blue_score} name="blue_score" onChange={this.handleFirstBlueScoreChange} required />
                                        </div>
                                    </Form.Field>
                                </Form.Group>
                                <Header as='h4'>2세트</Header>
                                <Form.Group>
                                <Form.Field>
                                    <label className="label">{this.props.team_red.team_text}</label>
                                    <Input type='number' defaultValue={second_red_score} name="red_score" onChange={this.handleSecondRedScoreChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">{this.props.team_blue.team_text}</label>
                                    <Input type='number' defaultValue={second_blue_score} name="blue_score" onChange={this.handleSecondBlueScoreChange} required />
                                </Form.Field>
                                </Form.Group>
                                <Header as='h4'>3세트</Header>
                                <Form.Group>
                                <Form.Field>
                                    <label className="label">{this.props.team_red.team_text}</label>
                                    <Input type='number' defaultValue={third_red_score} name="red_score" onChange={this.handleThirdRedScoreChange} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">{this.props.team_blue.team_text}</label>
                                    <Input type='number' defaultValue={third_blue_score} name="blue_score" onChange={this.handleThirdBlueScoreChange} required />
                                </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <label className="label">승리팀</label>
                                    <Dropdown placeholder='승리팀을 선택해주세요' selection options={victoryTeams} name="victory_team"  defaultValue={defaultWinner} onChange={this.handleWinnerChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">경기 시간</label>
                                    <DatePicker
                                      initialValue={this.toDateTime(this.props.match_date)}
                                      dateFormat="DD-MM-YYYY"
                                      timeFormat="hh:mm A"
                                      onChange={this.handleMatchDateChange}
                                    /> 
                                </Form.Field>   
                                <Form.Field>
                                    <label className="label">경기 상태</label>
                                    <Dropdown placeholder='경기 상태를 선택해주세요' selection options={stateOptions} name="game_state"  defaultValue={this.props.game_state} onChange={this.handleGameStateChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">경기 장소</label>
                                    <Input type='text' defaultValue={this.props.match_stadium} name="match_stadium" onChange={this.handleMatchStadiumChange} />
                                </Form.Field>              
                                <Form.Field>
                                    <label className="label">유튜브 중계 링크</label>
                                    <Input type='text' defaultValue={this.props.video_url} name="video_url" onChange={this.handleVideoUrlChange}  />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">업데이트</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionMatchInfoModalForm