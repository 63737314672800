import React, { Component } from "react";
import {
  Header,
  Segment,
  Table,
  TableRow,
} from 'semantic-ui-react'

class CompetitionFirstRoundResult extends Component {
  state = {
    resultData: [],
    matchTypeData: [],
  }

  render() {
    let i = 1;
  
    const list = this.props.firstRoundResult.map(
      (info, index) => (
        index === this.props.firstRoundResult.length -1 ?
          <TableRow>
            <Table.Cell textAlign='center'>{i++}</Table.Cell>
            <Table.Cell textAlign='center'>{info.team.team_text}</Table.Cell>
            <Table.Cell textAlign='center'>{info.first_round_score}</Table.Cell>
            <Table.Cell textAlign='center'>{info.first_round_goal}</Table.Cell>
          </TableRow>
          :
          <TableRow>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{i++}</Table.Cell>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.team.team_text}</Table.Cell>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.first_round_score}</Table.Cell>
            <Table.Cell style={{borderBottom: "1px solid #000000"}} textAlign='center'>{info.first_round_goal}</Table.Cell>
          </TableRow>
      )
    )
    

    return (
      <div>
        <Segment attached style={{border: "1px solid #000000"}}>
          <Header as='h3' textAlign='center'>조별예선 {this.props.first_round_group}조</Header>
          <Table striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{borderBottom :"1px solid #000000"}} width={1} textAlign='center'>순위</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom :"1px solid #000000"}} width={1} textAlign='center'>팀명</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom :"1px solid #000000"}} width={1} textAlign='center'>승점</Table.HeaderCell>
                <Table.HeaderCell style={{borderBottom :"1px solid #000000"}} width={1} textAlign='center'>골득실</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {list}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    )
  }
}

export default CompetitionFirstRoundResult