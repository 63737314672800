import React, { Component } from "react";
import styles from "./Payment.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import Common from "../Common.js";

import ReactToPrint from "react-to-print";
import Credentials from "../certificate_for_print/Credentials.js";
import CertificateOfExperience from "../certificate_for_print/CertificateOfExperience.js";
import TestPage from "../certificate_for_print/TestPage.js";
import { Button } from "semantic-ui-react";

const IMP = window.IMP;
IMP.init("imp98317073");

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: "",
            isPrint: "",
            step: 0,
            selectLicense: "",
            memberAuthlevelTime: 0,
            selectedLicenseCert: "",
            participationCompetition: "",
            selectedCompetition: {},
            lastSelected: [],
            // uniqueNum: "",
        };
    }

    componentDidMount = () => {
        this.getCurrentUser();
    };

    getCurrentUser() {
        if (sessionStorage.getItem("token")) {
            fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    Authorization: `JWT ${sessionStorage.getItem("token")}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (this.props.match.params.kind === "1") {
                        this.getParticipationCompetition(json.id);
                    }
                    this.setState({
                        user: json,
                    });
                });
        }
    }

    getParticipationCompetition = (user_id) => {
        fetch(Common.backendUrl + `/api/find/competition/${user_id}`)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    participationCompetition: json,
                });
            });
    };

    requestPay = (e, payment) => {
        e.preventDefault();
        let prod_name = "";

        if (this.props.match.params.kind === "0") {
            prod_name = "자격확인서-" + this.state.selectLicense;
        } else if (this.props.match.params.kind === "1") {
            prod_name = "수상실적확인서";
        }

        IMP.request_pay(
            {
                // param
                pg: "kcp",
                pay_method: payment,
                merchant_uid: "M" + new Date().getTime(),
                name: prod_name,
                amount: 1000,
                buyer_email: this.state.user.profile.member_email,
                buyer_name: this.state.user.profile.member_text,
                buyer_tel: this.state.user.profile.member_phone,
            },
            (rsp) => {
                // callback
                if (rsp.success) {
                    // 고유 주문번호 저장하기 추가하기.
                    // console.log("고유 주문번호: ", rsp.merchant_uid);
                    // this.setState({
                    //     uniqueNum: rsp.merchant_uid
                    // })

                    // console.log("unique_num : ", rsp.merchant_uid);
                    // console.log("profile : ", this.state.user.profile.id);
                    // console.log("competitions : ", this.state.lastSelected);

                    const formData = new FormData();
                    formData.append("product", rsp.name);
                    formData.append("method", rsp.pay_method);
                    formData.append("unique_num", rsp.merchant_uid);
                    formData.append("profile_id", this.state.user.profile.id);
                    formData.append("competitions", this.state.lastSelected);

                    fetch(Common.backendUrl + `/api/payment/post/`, {
                        method: "post",
                        body: formData,
                    }).then((response) => {
                        if (response.status >= 400) {
                            alert("error");
                            return;
                        }
                    });

                    if (this.props.match.params.kind === "0") {
                        alert("결제 완료");
                        document.getElementById("printBtn1").click();
                    } else if (this.props.match.params.kind === "1") {
                        // alert("결제 완료");
                        // document.getElementById("printBtn2").click();
                        window.open(`/certificate/${rsp.merchant_uid}`, "자격증명서", "toolbar=no, menubar=no, scrollbars=no, resizable=yes, location=no");
                    }
                } else {
                    alert("결제 실패");
                }
            }
        );
    };

    onAfterPrint = (e) => {
        if (window.confirm("완료되었습니다. 마이페이지로 돌아가시겠습니까?")) {
            window.location.href = "/mypage";
        }
    };

    onBeforePrint = (e) => {
        alert("인쇄창을 닫을 시 재결재해야합니다. 주의해주세요.");
    };

    onStepClick = (e, value) => {
        e.preventDefault();

        if (this.props.match.params.kind === "1") {
            const lastSelected = Object.keys(this.state.selectedCompetition).filter((x) => this.state.selectedCompetition[x]);

            if (lastSelected.length === 0) {
                alert("대회를 선택하세요.");
                return;
            }

            if (value === -1) {
                this.setState({
                    step: this.state.step + value,
                    selectedCompetition: {},
                    lastSelected: [],
                });
                return;
            }
            this.setState({
                step: this.state.step + value,
                lastSelected: "|||" + lastSelected.join("|||") + "|||",
            });
            return;
        }
        this.setState({
            step: this.state.step + value,
        });
    };

    onLicenseClick = (e, license) => {
        e.preventDefault();
        if (license === "드론축구 선수 자격증") {
            const [minSec, decimalSec] = this.state.user.profile.member_authlevel_time.split(".");

            let memberAuthlevelTime = 0;
            if (minSec % 60 < 10) {
                memberAuthlevelTime = parseInt(minSec / 60) + "분 " + "0" + (minSec % 60) + "초 " + decimalSec;
            } else {
                memberAuthlevelTime = parseInt(minSec / 60) + "분 " + (minSec % 60) + "초 " + decimalSec;
            }
            this.setState({
                step: 1,
                selectLicense: license,
                selectedLicenseCert: this.state.user.profile.member_authlevel.auth_text + " " + this.state.user.profile.cert_grade + "급",
                memberAuthlevelTime: memberAuthlevelTime,
            });
        } else if (license === "드론축구 지도자 자격증") {
            this.setState({
                step: 1,
                selectLicense: license,
                selectedLicenseCert: this.state.user.profile.leader_certificate + "급",
            });
        } else if (license === "드론축구 심판 자격증") {
            let refereeCert = "";

            if (this.state.user.profile.referee_certificate === 4) {
                refereeCert = "국제심판";
            } else {
                refereeCert = this.state.user.profile.referee_certificate + "급";
            }

            this.setState({
                step: 1,
                selectLicense: license,
                selectedLicenseCert: refereeCert,
            });
        }
    };

    checkBoxClick = (e) => {
        let { name, checked, value, className } = e.target;
        let regMembers = value.slice(3, -3).split("|||");

        const forFindIndex = (element) => element.split(",")[0] == this.state.user.id;

        this.setState((e) => {
            let selectedSport = e.selectedCompetition;
            return (selectedSport[name + "," + regMembers[regMembers.findIndex(forFindIndex)]] = checked);
        });
    };

    render() {
        return (
            <div>
                <Topmenu />
                <div style={{ minHeight: "100vh", height: "100%", padding: "130px 17px", backgroundColor: "#F9F9F9", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div className={styles.title_div}>
                        {this.props.match.params.kind === "0" ? (
                            this.state.step === 0 ? (
                                <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>대한드론축구협회 자격확인서</p>
                            ) : (
                                <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>대한드론축구협회 자격확인서 - {this.state.selectLicense}</p>
                            )
                        ) : null}
                        {this.props.match.params.kind === "1" && <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>대한드론축구협회 수상실적확인서</p>}

                        {this.state.step === 1 && <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>최종 결제금액: 1000</p>}
                    </div>

                    {this.state.step === 0 ? (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "11px", height: "30px" }}>
                                <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>인쇄항목 선택</p>
                            </div>
                            <hr style={{ width: "70%", maxWidth: "1200px", border: 0, borderTop: "1px solid #4d4d4d", marginBottom: "10px" }} />
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "70%", maxWidth: "1200px", alignItems: "center", justifyContent: "center" }}>
                                {this.props.match.params.kind === "0" ? (
                                    <>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer", margin: "10px" }} onClick={(e) => this.onLicenseClick(e, "드론축구 선수 자격증")}>
                                            <img src="/images/payment/kindBox.png" />
                                            <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold", position: "absolute" }}>선수자격증</p>
                                        </div>
                                        {this.state.user && (
                                            <>
                                                {this.state.user.profile.leader_certificate !== 0 && (
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer", margin: "10px" }} onClick={(e) => this.onLicenseClick(e, "드론축구 지도자 자격증")}>
                                                        <img src="/images/payment/kindBox.png" />
                                                        <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold", position: "absolute" }}>지도자 자격증</p>
                                                    </div>
                                                )}
                                                {this.state.user.profile.referee_certificate !== 0 && (
                                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", cursor: "pointer", margin: "10px" }} onClick={(e) => this.onLicenseClick(e, "드론축구 심판 자격증")}>
                                                        <img src="/images/payment/kindBox.png" />
                                                        <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold", position: "absolute" }}>심판 자격증</p>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : this.state.participationCompetition.length > 0 ? (
                                    <div className={styles.participationCompetition_div}>
                                        {this.state.participationCompetition.map((competition) => {
                                            return (
                                                <div key={competition.id}>
                                                    <input type="checkbox" name={competition.competition.id + `///${competition.team.team_text}`} className="partiCom" style={{ marginRight: "10px", cursor: "pointer" }} value={competition.reg_member} onChange={this.checkBoxClick} />
                                                    <p style={{ margin: 0, fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold" }}>
                                                        {competition.competition.competition_text}
                                                        <br />
                                                        {competition.team.team_text}({competition.competition.competition_start_date.split("T")[0]})
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative" }}>
                                        <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold" }}>참가한 대회가 없습니다.</p>
                                    </div>
                                )}
                                <ReactToPrint onAfterPrint={(e) => this.onAfterPrint(e)} trigger={() => <div id="printBtn2" style={{ display: "none" }}></div>} content={() => this.componentRef2} />
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", marginTop: "30px" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "11px", height: "30px" }}>
                                <p style={{ fontSize: "20px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>결제방법 선택</p>
                            </div>
                            <hr style={{ width: "70%", maxWidth: "1200px", border: 0, borderTop: "1px solid #4d4d4d", marginBottom: "40px" }} />
                            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "70%", maxWidth: "1200px", alignItems: "center", justifyContent: "center" }}>
                                <ReactToPrint onAfterPrint={(e) => this.onAfterPrint(e)} trigger={() => <div id="printBtn1" style={{ display: "none" }}></div>} content={() => this.componentRef1} />
                                <ReactToPrint onAfterPrint={(e) => this.onAfterPrint(e)} trigger={() => <div id="printBtn2" style={{ display: "none" }}></div>} content={() => this.componentRef2} />
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <img style={{ marginBottom: "10px", cursor: "pointer" }} src="/images/payment/payment1.png" onClick={(e) => this.requestPay(e, "card")} alt="신용카드 결제" />
                                    <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold" }}>신용카드</p>
                                </div>
                                {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <img style={{ marginBottom: "10px", cursor: "pointer" }} src="/images/payment/payment2.png" onClick={(e) => this.requestPay(e, "phone")} alt="핸드폰 결제" />
                                    <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold" }}>휴대폰결제</p>
                                </div> */}
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "10px" }}>
                                    <img style={{ marginBottom: "10px", cursor: "pointer" }} src="/images/payment/payment4.png" onClick={(e) => this.requestPay(e, "trans")} alt="실시간 계좌이체" />
                                    <p style={{ fontFamily: "Noto Sans KR", fontSize: "15px", fontWeight: "bold" }}>실시간 계좌이체</p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "30px" }}>
                        {this.state.step > 0 && (
                            <p style={{ cursor: "pointer", fontFamily: "Noto Sans KR", fontWeight: "bold", margin: 0, marginRight: "20px" }} onClick={(e) => this.onStepClick(e, -1)}>
                                이전 단계
                            </p>
                        )}
                        {this.props.match.params.kind === "1" && this.state.step === 0 && (
                            <p style={{ cursor: "pointer", fontFamily: "Noto Sans KR", fontWeight: "bold", margin: 0 }} onClick={(e) => this.onStepClick(e, 1)}>
                                다음 단계
                            </p>
                        )}
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width: "70%", maxWidth: "1200px", alignItems: "center", justifyContent: "space-around", marginTop: "40px" }}>
                        <p style={{ fontSize: "15px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>⛔️ 어떠한 사유에도 결제 이후에는 환불이 불가함으로 신중한 결제 부탁드립니다.</p>
                        <p style={{ fontSize: "15px", fontWeight: "bold", boxSizing: "border-box", lineHeight: "30px", color: "#010101", margin: 0 }}>⛔️ 결제 후 프린트가 진행됩니다. 프린트 연결을 미리 확인하기위해 테스트페이지 인쇄를 진행해주세요.</p>
                        <Button style={{ marginTop: "10px" }} onClick={() => document.getElementById("printBtn2").click()}>
                            테스트페이지 인쇄
                        </Button>
                    </div>
                </div>
                <Footer />
                <div style={{ display: "none" }}>
                    {this.state.user && (
                        <>
                            {/* <Credentials name={this.state.user.profile.member_text} birthDate={this.state.user.profile.birth_date.split("T")[0]} authText={this.state.user.profile.member_authlevel.auth_text} certGrade={this.state.user.profile.cert_grade} kind="드론축구선수 자격증" ref={el => (this.componentRef1 = el)} /> */}
                            <Credentials name={this.state.user.profile.member_text} birthDate={this.state.user.profile.birth_date.split("T")[0]} memberId={this.state.user.profile.member_id} authText={this.state.selectedLicenseCert} kind={this.state.selectLicense} memberAuthlevelTime={this.state.memberAuthlevelTime} ref={(el) => (this.componentRef1 = el)} />
                            {/* <CertificateOfExperience  name={this.state.user.profile.member_text} birthDate={this.state.user.profile.birth_date.split("T")[0]} team={this.state.user.profile.team.team_text} rfid={this.state.user.profile.member_rfid} competitions={this.state.lastSelected} ref={el => (this.componentRef2 = el)} /> */}
                        </>
                    )}
                    <TestPage ref={(el) => (this.componentRef2 = el)} />
                </div>
            </div>
        );
    }
}

export default Payment;
