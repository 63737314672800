import React, { Component } from "react";
import styles from "./TournamentBracketForResult.module.css";
import { Icon } from "semantic-ui-react";
import Common from "../Common";

export default class TournamentBracketForResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            semifinal: [],
            final: [],
        };
    }

    getSemifinaltData() {
        fetch(Common.backendUrl + "/api/competitiongamelist/" + this.props.competition_id + "/" + 4 + "/" + this.props.league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ semifinal: data });
            });
    }

    getFinaltData() {
        fetch(Common.backendUrl + "/api/competitiongamelist/" + this.props.competition_id + "/" + 2 + "/" + this.props.league_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ final: data });
            });
    }

    getSemifinalResult() {
        let semifinal_result = [];
        let imgPath = "/images/default_teamlogo_02_old.png";
        let redTeamLog = "";
        let blueTeamLog = "";

        let redSetScore = 0;
        let blueSetScore = 0;

        if (this.state.semifinal.length > 0) {
            for (let i = 0; i < this.state.semifinal.length; i++) {
                if (this.state.semifinal[i].team_red.team.team_image_file === null) {
                    imgPath = "/images/default_teamlogo_02_old.png";
                    redTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
                } else {
                    imgPath = this.state.semifinal[i].team_red.team.team_image_file;
                    redTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
                }

                if (this.state.semifinal[i].team_blue.team.team_image_file === null) {
                    imgPath = "/images/default_teamlogo_02_old.png";
                    blueTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
                } else {
                    imgPath = this.state.semifinal[i].team_blue.team.team_image_file;
                    blueTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
                }

                let first_set_score = this.state.semifinal[i].first_set_score.split("-");
                let second_set_score = this.state.semifinal[i].second_set_score.split("-");
                let third_set_score = this.state.semifinal[i].third_set_score.split("-");

                if (parseInt(first_set_score[0]) > parseInt(first_set_score[1])) {
                    redSetScore += 1;
                } else if (parseInt(first_set_score[0]) < parseInt(first_set_score[1])) {
                    blueSetScore += 1;
                }

                if (parseInt(second_set_score[0]) > parseInt(second_set_score[1])) {
                    redSetScore += 1;
                } else if (parseInt(second_set_score[0]) < parseInt(second_set_score[1])) {
                    blueSetScore += 1;
                }

                if (parseInt(third_set_score[0]) > parseInt(third_set_score[1])) {
                    redSetScore += 1;
                } else if (parseInt(third_set_score[0]) < parseInt(third_set_score[1])) {
                    blueSetScore += 1;
                }

                if (this.state.semifinal[i].winner !== null) {
                    semifinal_result.push(
                        <>
                            {this.state.semifinal[i].winner.id === this.state.semifinal[i].team_red.team.id && (
                                <td className={styles.matchWinner} colspan="2" height="50" width="14%">
                                    <span>{redTeamLog}</span>
                                    <span>{this.state.semifinal[i].team_red.team.team_text}</span>
                                </td>
                            )}
                            {this.state.semifinal[i].winner.id !== this.state.semifinal[i].team_red.team.id && (
                                <td className={styles.matchLosser} colspan="2" height="50" width="14%">
                                    <span>{redTeamLog}</span>
                                    <span>{this.state.semifinal[i].team_red.team.team_text}</span>
                                </td>
                            )}
                            <td height="18" width="7%"></td>
                            <td height="18" width="7%"></td>
                            {this.state.semifinal[i].winner.id === this.state.semifinal[i].team_blue.team.id && (
                                <td className={styles.matchWinner} colspan="2" height="50" width="14%">
                                    <span>{blueTeamLog}</span>
                                    <span>{this.state.semifinal[i].team_blue.team.team_text}</span>
                                </td>
                            )}
                            {this.state.semifinal[i].winner.id !== this.state.semifinal[i].team_blue.team.id && (
                                <td className={styles.matchLosser} colspan="2" height="50" width="14%">
                                    <span>{blueTeamLog}</span>
                                    <span>{this.state.semifinal[i].team_blue.team.team_text}</span>
                                </td>
                            )}

                            <td height="18" width="7%"></td>
                            <td height="18" width="7%"></td>
                        </>
                    );
                }
                redSetScore = 0;
                blueSetScore = 0;
            }
        }

        return semifinal_result;
    }

    getFinalResult() {
        let final_result = [];

        let imgPath = "/images/default_teamlogo_02_old.png";
        let redTeamLog = "";
        let blueTeamLog = "";

        let redSetScore = 0;
        let blueSetScore = 0;

        if (this.state.final.length > 0) {
            if (this.state.final[0].team_red.team.team_image_file === null) {
                imgPath = "/images/default_teamlogo_02_old.png";
                redTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
            } else {
                imgPath = this.state.final[0].team_red.team.team_image_file;
                redTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
            }

            if (this.state.final[0].team_blue.team.team_image_file === null) {
                imgPath = "/images/default_teamlogo_02_old.png";
                blueTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
            } else {
                imgPath = this.state.final[0].team_blue.team.team_image_file;
                blueTeamLog = <img className={styles.teamLog} src={imgPath} alt="team logo" />;
            }

            let first_set_score = this.state.final[0].first_set_score.split("-");
            let second_set_score = this.state.final[0].second_set_score.split("-");
            let third_set_score = this.state.final[0].third_set_score.split("-");

            if (parseInt(first_set_score[0]) > parseInt(first_set_score[1])) {
                redSetScore += 1;
            } else if (parseInt(first_set_score[0]) < parseInt(first_set_score[1])) {
                blueSetScore += 1;
            }

            if (parseInt(second_set_score[0]) > parseInt(second_set_score[1])) {
                redSetScore += 1;
            } else if (parseInt(second_set_score[0]) < parseInt(second_set_score[1])) {
                blueSetScore += 1;
            }
            if (parseInt(third_set_score[0]) > parseInt(third_set_score[1])) {
                redSetScore += 1;
            } else if (parseInt(third_set_score[0]) < parseInt(third_set_score[1])) {
                blueSetScore += 1;
            }

            final_result.push(
                <>
                    {this.state.final[0].winner.id === this.state.final[0].team_red.team.id && (
                        <td className={styles.matchWinner} colspan="2" height="50" width="14%">
                            <span>{redTeamLog}</span>
                            <span>{this.state.final[0].team_red.team.team_text}</span>
                        </td>
                    )}
                    {this.state.final[0].winner.id !== this.state.final[0].team_red.team.id && (
                        <td className={styles.matchLosser} colspan="2" height="50" width="14%">
                            <span>{redTeamLog}</span>
                            <span>{this.state.final[0].team_red.team.team_text}</span>
                        </td>
                    )}
                    <td width="7%"></td>
                    <td width="7%"></td>
                    <td width="7%"></td>
                    <td width="7%"></td>
                    <td width="7%"></td>
                    <td width="7%"></td>
                    {this.state.final[0].winner.id === this.state.final[0].team_blue.team.id && (
                        <td className={styles.matchWinner} colspan="2" height="50" width="14%">
                            <span>{blueTeamLog}</span>
                            <span>{this.state.final[0].team_blue.team.team_text}</span>
                        </td>
                    )}
                    {this.state.final[0].winner.id !== this.state.final[0].team_blue.team.id && (
                        <td className={styles.matchLosser} colspan="2" height="50" width="14%">
                            <span>{blueTeamLog}</span>
                            <span>{this.state.final[0].team_blue.team.team_text}</span>
                        </td>
                    )}
                    <td height="18" width="7%"></td>
                    <td height="18" width="7%"></td>
                </>
            );
            redSetScore = 0;
            blueSetScore = 0;
        }
        return final_result;
    }

    componentDidMount() {
        this.getSemifinaltData();
        this.getFinaltData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.competition_id !== prevProps.competition_id) {
            this.getSemifinaltData();
            this.getFinaltData();
        }
    }

    render() {
        window.scrollTo(0, 0);
        let finalResult = this.getFinalResult();
        let semiFinalResult = this.getSemifinalResult();
        let image = "";
        let winner = "";

        if (this.state.final[0] !== undefined) {
            if (this.state.final[0].winner.team_image_file === null) {
                image = <img className={styles.teamLog} src="/images/default_teamlogo_02_old.png"></img>;
                winner = this.state.final[0].winner.team_text;
            } else {
                image = <img className={styles.teamLog} src={this.state.final[1].winner.team_image_file}></img>;
                winner = this.state.final[0].winner.team_text;
            }
        }

        return (
            <div className={styles.bracket}>
                <table className={styles.bracketTable} cellspacing="0" cellpadding="0">
                    <tr>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td className={styles.matchWinner} colspan="2" width="14%" height="50">
                            <span>
                                <Icon inverted color="yellow" name="trophy" size="large"></Icon>
                            </span>
                            <span>{winner}</span>
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                    </tr>
                    <tr>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td className={styles.borderLeft} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                    </tr>
                    <tr>
                        <td width="7%">　</td>
                        <td width="7%">　</td>
                        <td width="7%">　</td>
                        <td className={styles.borderLeftTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTopRight} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                    </tr>
                    <tr>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        {finalResult}
                    </tr>
                    <tr>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td className={styles.borderRight} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td className={styles.borderRight} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                    </tr>
                    <tr>
                        <td width="7%"></td>
                        <td className={styles.borderLeftTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTopRight} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td width="7%"></td>
                        <td className={styles.borderTopLeft} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTop} width="7%">
                            　
                        </td>
                        <td className={styles.borderTopRight} width="7%">
                            　
                        </td>
                        <td width="7%"></td>
                    </tr>
                    <tr>{semiFinalResult}</tr>
                </table>
            </div>
        );
    }
}
