import React, { Component } from 'react';
import TopMenu from './Topmenu/Topmenu';
import Footer from './footer/Footer.js';
import styles from './FailedToFetchPage.module.css';

class FailedToFetchPage extends Component {

    render() {
        return (
            <>
            <TopMenu />
                <div style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "hidden" }}>
                    <div style={{ height: "100vh" }} className={styles.stars}></div>
                    <div style={{ height: "100vh" }} className={styles.twinkling}></div>
                    
                    <div className={styles.wrap_div}>
                        <div className={styles.content_div}>
                            <img src='/images/maintain.png' alt='maintain' />
                            <h1>점검 중입니다.</h1>
                            <p>서비스 이용에 불편을 드려 죄송합니다.</p>
                        </div>
                    </div>

                </div>
                <Footer/>
            </>
        );
    }
}

export default FailedToFetchPage;