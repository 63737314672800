import React, { Component } from "react";
import { Button, Form, Grid, Header, Confirm, Segment } from 'semantic-ui-react'
import styles from "./FindId.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer"
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import {withTranslation} from 'react-i18next';
import Common from "../Common";

class FindId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member_text: "",
      member_email: "",
      findId: "",
      warningModalOpen: false,
      warningText: "",
      warningLocation: false,
    };
  }

  getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  handleMemberTextChange = (e, { value }) => {
    this.setState({ member_text: value });
  };

  handleMemberEmailChange = (e, { value }) => {
    this.setState({ member_email: value });
  };

  handleFindId = e => {
    const { t } = this.props;
    e.preventDefault();
    if (this.state.member_text === "") {
      this.handleWarningModalOpen(t('find_id.FindId.Name_Alert'));
      return;
    }

    if (this.state.member_email === "") {
      this.handleWarningModalOpen(t('find_id.FindId.Email_Alert'));
      return;
    }

    fetch( Common.backendUrl + '/api/findid/' + this.state.member_text + "/" + this.state.member_email)
      .then(response => {
        return response.json();
      })
      .then(json => {
        const isMemberIdFound = json.length;
        if (isMemberIdFound > 0) {
          let pattern = /.{2}$/;
          let findId = json[0].user.username
          let covertId = findId.replace(pattern, "**");
          this.handleWarningModalOpen(covertId)
          this.setState({
            warningLocation: true,
          })

          return;
        } else {
          this.handleWarningModalOpen(t('find_id.FindId.No_id'));
          return;
        }
      });
  };

  handleWarningModalOpen = (text) => {
    this.setState({
      warningLocation: false,
      warningModalOpen: true,
      warningText: text,
    })
  }


  handleWarningModalClose = () => {
    this.setState({
      warningModalOpen: false,
    })
    if (this.state.warningLocation) {
      document.location.href = '/login/';
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Topmenu />
        <div style={{ minHeight: "1080px", backgroundColor: "red", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <div className={styles.stars}></div>
          <div className={styles.twinkling}></div>
          <div className={styles.clouds}></div>

          <div className={styles.login_form_wrap}>
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450 }}>
                <Form size='large' >
                  <Segment className={styles.login_form_div} textAlign='left'>
                    <Header as='h2' inverted color='grey' textAlign='center' style={{ marginBottom: "50px" }}>
                      {t('find_id.FindId.Header')}
                    </Header>
                    <label className={styles.label}><span>{t('find_id.FindId.Name')}</span></label>
                    <Form.Input fluid icon='user plus' iconPosition='left' style={{ marginTop: '5px' }} placeholder={t('find_id.FindId.Name_Alert')} onChange={this.handleMemberTextChange} />
                    <label className={styles.label}><span>{t('find_id.FindId.Email')}</span></label>
                    <Form.Input
                      fluid
                      icon='mail'
                      iconPosition='left'
                      placeholder={t('find_id.FindId.Email_Alert')}
                      style={{ marginTop: '5px' }}
                      onChange={this.handleMemberEmailChange}
                    />
                    <a className={`${styles.style_a} ${styles.mouse_hover} `} onClick={this.handleFindId} style={{ textAlign: 'center', width: "100%" }} >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      {t('find_id.FindId.Find_Id')}
                    </a>
                  </Segment>
                </Form>

              </Grid.Column>
            </Grid>
          </div>
        </div>
        <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
        <Footer />
      </>
    );
  }
}

export default withTranslation()(FindId);
