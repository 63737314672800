import React, { Component } from 'react';
import styles from './IdentityVerification.module.css'
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer"
import Common from '../Common.js';

class IdentityVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enc_data: '',
            returnMsg: '',
        }
    }


    componentDidMount = () => {
        this.getEncData();
    }

    getEncData = () => {
        fetch(Common.backendUrl + '/api/checkplus_main/')
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                // console.log(data)
                this.setState({
                    enc_data: data.enc_data,
                    returnMsg: data.returnMsg
                })
                // console.log(data)
            });
    }

    handleIdentity = (e) => {
        e.preventDefault();
        window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=yes, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
        document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        document.form_chk.target = "popupChk";
        document.form_chk.submit();
    }

    render() {
        return (
            <>
                <Topmenu />
                <div className={styles.total_div} style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "hidden" }}>
                    <div style={{ height: "100vh" }} className={styles.stars}></div>
                    <div style={{ height: "100vh" }} className={styles.twinkling}></div>

                    <div className={styles.wrap_div}>
                        <div className={styles.content_div}>
                            <p>본인인증</p>
                            <div>
                                {sessionStorage.getItem('adult') === 'true' ?
                                    <div className={styles.memberselect_div}>
                                        <img src="/images/login/phone.png" alt="phone" style={{marginBottom: "10px"}} />
                                        <p>휴대폰</p>
                                        <p style={{ textAlign: "center" }} >본인 명의로 된 휴대폰으로 인증번호를 전송받아<br />가입하실 수 있습니다.</p>
                                        <form name="form_chk" method="post">
                                            <input type="hidden" name="m" value="checkplusSerivce" />
                                            <input type="hidden" name="EncodeData" value={this.state.enc_data} />
                                            <a className={styles.style_a} style={{ marginTop: "5px", width: "fit-content" }} onClick={(e) => this.handleIdentity(e)} >
                                                인증하기
                                            </a>

                                        </form>
                                    </div>
                                    :
                                    <div className={styles.memberselect_div}>
                                        <img src="/images/login/phone.png" alt="phone" style={{marginBottom: "10px"}} />
                                        <p>법정대리인 휴대폰 인증</p>
                                        <p style={{ textAlign: "center" }} >법정대리인 명의로 된 휴대폰으로<br />인증번호를 전송받아 가입하실수 있습니다.</p>
                                        <form name="form_chk" method="post">
                                            <input type="hidden" name="m" value="checkplusSerivce" />
                                            <input type="hidden" name="EncodeData" value={this.state.enc_data} />
                                            <a className={styles.style_a} style={{ marginTop: "5px", width: "fit-content" }} onClick={(e) => this.handleIdentity(e)} >
                                                인증하기
                                    </a>

                                        </form>
                                    </div>
                                }
                            </div>
                            <a className={styles.style_a1} onClick={() => window.location.href = '/login'} >
                                        취소
                                </a>
                        </div>
                    </div>

                </div>

                <Footer />
            </>
        );
    }
}


export default IdentityVerification;