import React, { Component } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

import { Button, Input, Dropdown, Form, Modal } from "semantic-ui-react";

import Common from "../../../Common";

class MemberModalForm extends Component {
    state = {
        provinceId: "",
        provinceData: "",
        provinceOptions: "",
        regionId: "",
        regionOptions: "",
        teamId: "",
        teamOptions: "",
        member_homepage_id: "",
        member_password: "",
        name: "",
        birth: "",
        positionId: "",
        positionOptions: "",
        kind: "",
        kindOptions: "",
        phone: "",
        email: "",
        image_file: null,
        isDuplicateId: null,
        isDuplicateEmail: null,
        validEmail: false,
        errors: {
            duplicate_homepage_id: "",
            duplicate_email: "",
        },
        warningModalOpen: false,
        warningText: "",
    };

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    getProvinceList() {
        fetch(Common.backendUrl + "/api/province/manage")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.state.provinceData = data;
                let arr = [];
                data.forEach((province) => arr.push({ key: province.id, text: province.province_text, value: province.id }));
                this.setState({ provinceOptions: arr });
            });
    }

    getRegionData(province_id) {
        fetch(Common.backendUrl + "/api/region/" + province_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let regions = [];
                let regionDict = {};
                for (let region of data) {
                    regions.push({ key: region.id, text: region.region_text, value: region.id });
                    regionDict[region.id] = region.region_text;
                }
                this.setState({
                    regionOptions: regions,
                    regionData: regionDict,
                });
            });
    }

    getTeamData(region_id) {
        fetch(Common.backendUrl + "/api/team/region/" + region_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.state.teamData = data;
                let arr = [];
                data.forEach((team) => arr.push({ key: team.id, text: team.team_text, value: team.id }));
                this.setState({ teamOptions: arr });
            });
    }

    getPositionData() {
        fetch(Common.backendUrl + "/api/position/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((position) => arr.push({ key: position.id, text: position.position_text, value: position.id }));
                this.setState({ positionOptions: arr });
            });
    }

    getAuthLevelData() {
        fetch(Common.backendUrl + "/api/authlevel/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((authlevel) => arr.push({ key: authlevel.id, text: authlevel.auth_text, value: authlevel.id }));
                this.setState({ authlevelOptions: arr });
            });
    }

    getKindList() {
        let arr = [];
        const kind = [
            { id: 1, kind: "일반" },
            { id: 2, kind: "유소년" },
        ];
        kind.forEach((data) => arr.push({ key: data.id, text: data.kind, value: data.id }));
        this.setState({ kindOptions: arr });
    }

    componentDidMount() {
        this.getProvinceList();
        this.getRegionData(this.state.provinceId);
        this.getPositionData();
        this.getAuthLevelData();
        this.getKindList();
    }

    componentDidUpdate(prevProps) {
        if (this.props.province_id !== prevProps.province_id) {
            this.getRegionData(this.props.province_id);
        }

        if (this.props.region_id !== prevProps.region_id) {
            this.getTeamData(this.props.region_id);
        }
    }

    handleProvinceChange = (e, { value }) => {
        this.setState({ provinceId: value });
        this.getRegionData(value);
    };

    handleRegionChange = (e, { value }) => {
        this.setState({ regionId: value });
        this.getTeamData(value);
    };

    handleTeamChange = (e, { value }) => {
        this.setState({ teamId: value });
    };

    handleMemberHomepageIdChange = (e, { value }) => {
        this.setState({ member_homepage_id: value });
    };

    handlePasswordChange = (e, { value }) => {
        if (value.length >= 8 && value.length <= 16) this.setState({ member_password: value });
    };

    handleNameChange = (e, { value }) => {
        this.setState({ name: value });
    };

    handleBirthChange = (event, data) => {
        const checkDate = data.value;
        if (checkDate !== null) {
            const strDate = checkDate.getFullYear() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getDate() + " " + "00:00:00.000000";
            this.setState({
                birth: strDate,
            });
        }
    };

    handlePositionChange = (e, { value }) => {
        this.setState({ positionId: value });
    };

    handleKindChange = (e, { value }) => {
        this.setState({ kind: value });
    };

    handlePhoneChange = (event, data) => {
        const checkPhone = data.value;
        if (checkPhone.length === 11) {
            let firstNum = checkPhone.slice(0, 3);
            let middleNum = checkPhone.slice(3, 7);
            let lastNum = checkPhone.slice(7, 11);
            const strPhone = firstNum + "-" + middleNum + "-" + lastNum;
            this.setState({
                phone: strPhone,
            });
        } else {
            this.setState({
                phone: "",
            });
        }
    };

    handleImageChange = (e) => {
        this.setState({
            image_file: e.target.files[0],
        });
    };

    handleEmailChange = (e, { value }) => {
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        this.setState({ validEmail: regExp.test(value) });
        if (regExp.test(value) === true) {
            this.setState({ email: value });
        }
    };

    handleDuplicateIdCheck = (e) => {
        e.preventDefault();
        if (this.state.member_homepage_id === "") {
            this.handleWarningModalOpen("아이디를 입력해주세요");
            return;
        }
        fetch(Common.backendUrl + "/api/userid/" + this.state.member_homepage_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                const isMemberIdFound = data.length;
                isMemberIdFound
                    ? this.setState({
                          errors: {
                              duplicate_hompage_id: "중복된 아이디 입니다.",
                          },
                          isDuplicateId: true,
                      })
                    : this.setState({
                          errors: {
                              duplicate_hompage_id: "가입 가능한 아이디 입니다.",
                          },
                          isDuplicateId: false,
                      });
            });
    };

    handleDuplicateEmailCheck = (e) => {
        e.preventDefault();
        if (this.state.email === "") {
            this.handleWarningModalOpen("이메일을 입력해주세요");
            return;
        }
        fetch(Common.backendUrl + "/api/useremail/" + this.state.email)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                const isMemberEmailFound = data.length;
                isMemberEmailFound
                    ? this.setState({
                          errors: {
                              duplicate_email: "중복된 이메일 입니다.",
                          },
                          isDuplicateEmail: true,
                      })
                    : this.setState({
                          errors: {
                              duplicate_email: "가입가능한 이메일 입니다.",
                          },
                          isDuplicateEmail: false,
                      });
            });
    };

    handleCancle = (e) => {
        e.preventDefault();
        this.props.history.goBack();
        //window.history.back();
    };

    handleSubmit = (e) => {
        e.preventDefault();
        let form_data = new FormData();

        const csrftoken = this.getCookie("csrftoken");

        if (this.state.rfidCode !== "") {
            form_data.append("member_rfid", parseInt(this.state.rfidCode));
        }

        if (this.state.image_file !== null) {
            form_data.append("image", this.state.image_file.name);
            form_data.append("image_file", this.state.image_file);
        }

        form_data.append("member_text", this.state.name);
        form_data.append("member_phone", this.state.phone);
        form_data.append("member_email", this.state.email);
        form_data.append("birth_date", this.state.birth);
        form_data.append("member_position_id", this.state.positionId);
        form_data.append("kind", this.state.kind);
        form_data.append("username", this.state.member_homepage_id);
        form_data.append("password", this.state.member_password);
        form_data.append("team_id", this.state.teamId);
        form_data.append("email", this.state.email);

        if (this.state.provinceId === "") {
            this.handleWarningModalOpen("지회를 선택해주세요");
            return;
        } else if (this.state.regionId === "") {
            this.handleWarningModalOpen("지부를 선택해주세요");
            return;
        } else if (this.state.teamId === "") {
            this.handleWarningModalOpen("팀을 선택해주세요");
            return;
        } else if (this.state.kind === "") {
            this.handleWarningModalOpen("일반/유소년을 선택해주세요");
            return;
        } else if (this.state.positionId === "") {
            this.handleWarningModalOpen("포지션을 선택해주세요");
            return;
        } else if (this.state.isDuplicateId === true) {
            this.handleWarningModalOpen("아이디가 중복됩니다.");
            return;
        } else if (this.state.isDuplicateEmail === true) {
            this.handleWarningModalOpen("이메일이 중복됩니다.");
            return;
        } else if (this.state.isDuplicateId === null) {
            this.handleWarningModalOpen("아이디 중복검사를 진행해주세요.");
            return;
        } else if (this.state.isDuplicateEmail === null) {
            this.handleWarningModalOpen("email 중복검사를 진행해주세요.");
            return;
        } else if (this.state.validEmail === false) {
            this.handleWarningModalOpen("email 형식이 아닙니다.");
            return;
        } else if (this.state.phone === "") {
            this.handleWarningModalOpen("핸드폰 번호를 다시 입력해주세요");
        } else if (this.state.isDuplicateEmail === false && this.state.isDuplicateId === false && this.state.phone !== "") {
            const request = new Request(Common.backendUrl + "/api/signup/", { headers: { "X-CSRFToken": csrftoken } });
            fetch(request, {
                method: "post",
                body: form_data,
            }).then((response) => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen("error");
                    // this.handleWarningModalOpen(response.statusText);
                } else {
                    this.handleWarningModalOpen("회원가입 완료");
                    this.setState({ modal_open: false });
                    this.props.reloadPage();
                    return response.json();
                }
            });
        }
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        return (
            <>
                <Modal size={"tiny"} onClose={() => this.setState({ modal_open: false })} onOpen={() => this.setState({ modal_open: true })} open={this.state.modal_open} trigger={<Button primary>선수 등록</Button>}>
                    <Modal.Header>선수정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group widths={2}>
                                    <Form.Field required>
                                        <label>
                                            <span>지회</span>
                                        </label>
                                        <Dropdown placeholder="지회 선택" selection options={this.state.provinceOptions} name="province" onChange={this.handleProvinceChange} fluid />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>
                                            <span>지부</span>
                                        </label>
                                        <Dropdown placeholder="지부 선택" selection options={this.state.regionOptions} name="region_text" onChange={this.handleRegionChange} fluid />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field required>
                                    <label>
                                        <span>팀명</span>
                                    </label>
                                    <Dropdown placeholder="팀 선택" selection options={this.state.teamOptions} name="team_text" onChange={this.handleTeamChange} fluid />
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>아이디</span>
                                    </label>
                                    <Input placeholder="아이디 20자 이하" type="text" name="member_hompage_id" maxLength="20" onChange={this.handleMemberHomepageIdChange} required />
                                    <div style={{ display: "flex", marginTop: "5px" }}>
                                        <Button onClick={this.handleDuplicateIdCheck} content="아이디 중복검사" size="small" primary />
                                        {this.state.isDuplicateId === true && <div>{this.state.errors.duplicate_hompage_id}</div>}
                                        {this.state.isDuplicateId === false && <div>{this.state.errors.duplicate_hompage_id}</div>}
                                    </div>
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>비밀번호</span>
                                    </label>
                                    <Input placeholder="비밀번호 8~16자리" type="password" name="member_password" maxLength="16" minLength="8" onChange={this.handlePasswordChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>이름</span>
                                    </label>
                                    <Input placeholder="이름" type="text" name="name" maxLength="20" onChange={this.handleNameChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>생년월일</span>
                                    </label>
                                    <SemanticDatepicker name="birth_date" onChange={this.handleBirthChange} required />
                                </Form.Field>
                                <Form.Group widths={2}>
                                    <Form.Field required>
                                        <label>
                                            <span>일반/유소년</span>
                                        </label>
                                        <Dropdown placeholder="일반/유소년" selection options={this.state.kindOptions} name="membership" fluid onChange={this.handleKindChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>
                                            <span>포지션</span>
                                        </label>
                                        <Dropdown placeholder="포지션 선택" selection options={this.state.positionOptions} name="position_text" fluid onChange={this.handlePositionChange} />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field required>
                                    <label>
                                        <span>핸드폰 번호</span>
                                    </label>
                                    <Input placeholder="-을 제외한 11자리 번호입력" type="text" name="phone" maxLength="20" onChange={this.handlePhoneChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label>
                                        <span>이메일</span>
                                    </label>
                                    <Input placeholder="dronesoccer@naver.com" type="text" name="member_email" maxLength="40" onChange={this.handleEmailChange} required />
                                    <div style={{ display: "flex", marginTop: "8px" }}>
                                        <Button onClick={this.handleDuplicateEmailCheck} content="이메일 중복검사" size="small" primary />
                                        {this.state.isDuplicateEmail === true && <div>{this.state.errors.duplicate_email}</div>}
                                        {this.state.isDuplicateEmail === false && <div>{this.state.errors.duplicate_email}</div>}
                                    </div>
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    선수등록
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default MemberModalForm;
