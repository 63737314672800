import React, { Component } from "react";
import CompetitionModifiedModalForm from "./CompetitionModifiedModalForm.js";
import { Header, Segment, Button } from "semantic-ui-react";

import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";

class CompetitionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            competition_data: {
                competition_type_id: 1,
                competition_type: "일반",
                competition_id: "",
                competition_text: "",
                competition_start_date: "",
                competition_end_date: "",
                competition_info: "",
                competition_state: "",
                competition_state_id: "",
            },
            loading: false,
            warningModalOpen: false,
            warningText: "",
        };
        this.reloadPage = this.reloadPage.bind(this);
        this.calculatePoint = this.calculatePoint.bind(this);
    }

    getCompetitionData() {
        fetch(Common.backendUrl + "/api/competition/" + this.props.competition_id)
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("대회정보를 불러오지 못했습니다.");
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    competition_data: {
                        competition_type_id: data.competition_type.id,
                        competition_type: data.competition_type.competition_type,
                        competition_id: data.id,
                        competition_text: data.competition_text,
                        registration_start_date: data.registration_start_date,
                        registration_end_date: data.registration_end_date,
                        competition_start_date: data.competition_start_date,
                        competition_end_date: data.competition_end_date,
                        competition_info: data.competition_info,
                        competition_state: data.competition_state.state_text,
                        competition_state_id: data.competition_state.id,
                        competition_image_file: data.competition_image_file,
                        competition_image: data.competition_image,
                        weight: data.weight,
                        first_league_num: data.first_league_num,
                        second_league_num: data.second_league_num,
                        third_league_num: data.third_league_num,
                        youth_league_num: data.youth_league_num,
                        place: data.place,
                    },
                });
            })
            .catch((error) => {
                this.handleWarningModalOpen(error);
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.competition_id !== prevProps.competition_id) {
            this.getCompetitionData();
        }
    }

    componentDidMount() {
        this.getCompetitionData();
    }

    reloadPage() {
        this.getCompetitionData();
    }

    calculatePoint() {
        let form_data = new FormData();
        this.setState({ loading: true });
        form_data.append("competition_id", this.props.competition_id);
        form_data.append("competition_state_id", this.state.competition_data.competition_state_id);
        form_data.append("weight", this.state.competition_data.weight);

        fetch(Common.backendUrl + "/api/competition_point/update/", {
            method: "post",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                if (response.status === 400) {
                    this.handleWarningModalOpen("대회가 종료되지 않습니다.");
                    this.setState({ loading: false });
                    return;
                } else {
                    this.handleWarningModalOpen("error");
                    this.setState({ loading: false });
                    return;
                }
            } else {
                this.handleWarningModalOpen("DS포인트 계산 완료");
                this.setState({ loading: false });
                return;
            }
        });
    }

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let day = parts[2].split("T");
            let time = day[1].split(".");
            let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0] + " " + time[0];
            return covertedDate;
        } else {
            return;
        }
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        return (
            <div>
                <Segment attached="top" clearing secondary style={{ border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        대회정보
                    </Header>
                    <Header as="h4" floated="right">
                        {/* 대회수정버튼 */}
                        <CompetitionModifiedModalForm
                            selCompetitionType={this.state.competition_data.competition_type_id}
                            competition_id={this.props.competition_id}
                            competition_text={this.state.competition_data.competition_text}
                            registration_start_date={this.state.competition_data.registration_start_date}
                            registration_end_date={this.state.competition_data.registration_end_date}
                            competition_start_date={this.state.competition_data.competition_start_date}
                            competition_end_date={this.state.competition_data.competition_end_date}
                            competition_info={this.state.competition_data.competition_info}
                            qualifier_match_date={this.state.competition_data.qualifier_match_date}
                            first_league_match_date={this.state.competition_data.first_league_match_date}
                            second_league_match_date={this.state.competition_data.second_league_match_date}
                            third_league_match_date={this.state.competition_data.third_league_match_date}
                            quarterfinals_finals_match_date={this.state.competition_data.quarterfinals_finals_match_date}
                            competition_state={this.state.competition_data.competition_state_id}
                            competition_state_text={this.state.competition_data.competition_state}
                            competition_state_id={this.state.competition_data.competition_state_id}
                            competition_image_file={this.state.competition_data.competition_image_file}
                            competition_image={this.state.competition_data.competition_image}
                            first_league_num={this.state.competition_data.first_league_num}
                            second_league_num={this.state.competition_data.second_league_num}
                            third_league_num={this.state.competition_data.third_league_num}
                            youth_league_num={this.state.competition_data.youth_league_num}
                            weight={this.state.competition_data.weight}
                            place={this.state.competition_data.place}
                            reloadPage={this.reloadPage}
                        />
                    </Header>
                    <Header as="h4" floated="right">
                        <Button loading={this.state.loading} primary onClick={this.calculatePoint}>
                            DS포인트 및 팀 랭킹 계산
                        </Button>
                    </Header>
                </Segment>
                <Segment attached style={{ border: "1px solid #000000", borderTop: "0", fontSize: "15px" }}>
                    대회종류 : {this.state.competition_data.competition_type} 대회
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    대회명 : {this.state.competition_data.competition_text}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    대회장소 : {this.state.competition_data.place}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    접수 시작일 : {this.toDate(this.state.competition_data.registration_start_date)}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    접수 종료일 : {this.toDate(this.state.competition_data.registration_end_date)}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    시작일 : {this.state.competition_data.competition_start_date}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    종료일 : {this.state.competition_data.competition_end_date}
                    <br />
                    <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                    대회진행상태 : {this.state.competition_data.competition_state}
                    <br />
                    {this.state.competition_data.competition_info && (
                        <>
                            <hr style={{ border: "0", borderBottom: "1px solid #000000", margin: "5px 0px" }} />
                            대회안내
                            <br />
                            <br />
                            <div style={{ whiteSpace: "break-spaces", height: "95px", overflow: "auto" }} dangerouslySetInnerHTML={{ __html: this.state.competition_data.competition_info }}></div>
                            <br />
                        </>
                    )}
                </Segment>

                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

export default CompetitionDetail;
