import React, { Component } from 'react'
import { Button, Divider, Header, Icon, Modal, Form, Dropdown } from 'semantic-ui-react'
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from '../../../Common';

export default class ModalApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningModalOpen: false,
      warningText: "",
      requestText: "",
      selectedOption: "",
    }
  }

  handleSubmit = () => {
    if(this.state.requestText === ""){
      this.handleWarningModalOpen("요청사항을 입력하세요.")
      return  
    }

    const teamRequest = {
      profile_id: this.props.profile.id,
      content: this.state.requestText,
      request_state: "신청",
      select_menu: this.state.selectedOption,
    }

    const conf = {
      method: "post",
      body: JSON.stringify(teamRequest),
      headers: new Headers({ "Content-Type": "application/json" })
    }

    fetch(Common.backendUrl + "/api/team/requests/create", conf)
      .then(res => {
        if (res.status >= 400){
          this.handleWarningModalOpen("error")
        } else {
          this.handleWarningModalOpen("요청완료")
        }
      })

  }

  requestTextChange = (e) => {
    this.setState({
      requestText: e.target.value,
    })
  }

  
  handleWarningModalOpen = (text) => {
    this.setState({
        warningModalOpen: true,
        warningText: text,
    })
}


handleWarningModalClose = () => {
    this.setState({
        warningModalOpen: false,
    })
    window.location.reload()
}

selectMenuChange = (e, {value}) => {
  this.setState({
    selectedOption: value,
  })
}

  render() {
    const selectMenuOptions = [
      { key: 0, text: "선수단 말소", value: "선수단 말소" },
      { key: 1, text: "선수단 이적", value: "선수단 이적" },
      { key: 2, text: "선수 말소", value: "선수 말소" },
      { key: 3, text: "선수 이적", value: "선수 이적" },
      { key: 4, text: "단장 변경", value: "단장 변경" },
    ]

    return (
      <Modal
        open={this.props.isOpen}
        size='mini'
      >
        <Header style={{ border: "0px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <p style={{ textAlign: "center", fontSize: "20px", fontFamily: "Noto Sans KR", fontWeight: "bold" }}>선수상태 및 선수단 변경 요청</p>
          <Dropdown  style={{fontSize: "15"}}
            placeholder="메뉴를 선택하세요"
            options={selectMenuOptions}
            onChange={this.selectMenuChange}
          />
        </Header>
          <Modal.Content>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <textarea onChange={(e) => this.requestTextChange(e)} placeholder="요청내용을 입력하세요." style={{resize: "none"}} />
              </Form.Field>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly"}}>
              <Button color="green" type="submit" inverted>
                <Icon name="check" /> 신청하기
              </Button>
              <Button color='red' inverted onClick={() => this.props.closeMethod()}>
                <Icon name='remove' /> 닫기
            </Button>
            </div>
            </Form>
          </Modal.Content>
          <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
      </Modal>
    )
  }
}

