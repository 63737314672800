import React, { Component } from "react";
import CompetitionDetail from "./CompetitionDetail.js";
import CompetitionModalForm from "./CompetitionModalForm.js";
import CompetitionLeagueTab from "./CompetitionLeagueTab.js";
import styles from "./CompetitionManager.module.css";
// import Topmenu from "../Topmenu/Topmenu";
// import Footer from "../footer/Footer";

import {
  Container,
  Dropdown,
  Grid,
  List,
  Menu,
  Segment,
  Checkbox,
  Header,
} from "semantic-ui-react";
import Common from "../Common";

// 대회관리페이지 대회정보
class CompetitionManager extends Component {
  constructor() {
    super();

    const maxYear = new Date().getFullYear();
    const minYear = 2020;
    const years = [];

    for (let i = maxYear; i >= minYear; i--) {
      years.push({ key: i, text: i, value: i });
    }

    this.state = {
      crntYear: maxYear,
      yearDropDownOptions: years,
      selType: "종합",
      competitionTypeOptions: [],
      selectedCompetitionId: 0,
      competitions: [],
      teamOptions: "",
      aplicationTeamList: [],
      joinTeamList: [],
      gameList: [],
    };
    this.reloadPage = this.reloadPage.bind(this);
    this.getCompetition = this.getCompetition.bind(this);
  }

  handleChange = (e, { value }) => {
    this.setState({ selectedCompetitionId: value });
  };

  componentDidMount() {
    this.getLastYearCompetition();
    this.getCompetition(this.state.crntYear, this.state.selType);
    this.getCompetitionTypeOptions();
  }

  getCompetitionTypeOptions = () => {
    fetch(Common.backendUrl + "/api/competition/type/", {
      method: "get",
    })
      .then((res) => {
        if (res.status >= 400) {
          throw new Error("오류가 발생했습니다. 관리자에게 문의하세요");
        }
        return res.json();
      })
      .then((json) => {
        const competitionTypeOptions = [];

        json.map((type) => {
          competitionTypeOptions.push({
            key: type.id,
            text: type.competition_type,
            value: type.competition_type,
          });
        });
        this.setState({
          competitionTypeOptions,
        });
      })
      .catch((error) => {
        this.handleWarningModalOpen(error + "");
      });
  };

  getLastYearCompetition() {
    fetch(Common.backendUrl + "/api/competition/lastone")
      .then((res) => {
        if (res.status > 400) {
          throw new Error("error");
        }
        return res.json();
      })
      .then((data) => {
        const lastYear = parseInt(data[0].competition_start_date.split("-")[0]);
        if (this.state.yearDropDownOptions[0].key < lastYear) {
          this.setState({
            yearDropDownOptions: [
              { key: lastYear, text: lastYear, value: lastYear },
              ...this.state.yearDropDownOptions,
            ],
          });
        }
      });
  }

  getCompetition(year, type) {
    fetch(Common.backendUrl + `/api/show_competition/${year}/${type}/`)
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let competitions = [];
        for (let competition of data) {
          competitions.push({
            key: competition.id,
            text: competition.competition_text,
            value: competition.id,
          });
        }
        if (competitions.length > 0) {
          this.setState({
            competitions: competitions,
            selectedCompetitionId: competitions[0]["key"],
          });
        } else {
          this.setState({ competitions: [], selectedCompetitionId: 0 });
        }
      });
  }

  reloadPage(competition_id) {
    fetch(Common.backendUrl + "/api/competition")
      .then((response) => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then((data) => {
        let competitions = [];
        for (let competition of data) {
          competitions.push({
            key: competition.id,
            text: competition.competition_text,
            value: competition.id,
          });
        }
        this.setState({
          competitions: competitions,
          selectedCompetitionId: competition_id,
        });
      });
  }

  yearDropdownChange = (e, { value }) => {
    this.getCompetition(value, this.state.selType);
    this.setState({
      crntYear: value,
    });
  };

  typeDropdownChange = (e, { value }) => {
    this.getCompetition(this.state.crntYear, value);
    this.setState({
      selType: value,
    });
  };

  render() {
    return (
      // 대회관리페이지
      <div>
        <Container style={{ paddingLeft: "5em", paddingRight: "5em" }} fluid>
          <Grid divided="vertically">
            <Grid.Row columns={2}>
              <Grid.Column>
                <Dropdown
                  style={{ border: "1px solid #000000" }}
                  onChange={this.yearDropdownChange}
                  value={this.state.crntYear}
                  options={this.state.yearDropDownOptions}
                  selection
                  placeholder="대회연도"
                  fluid
                />
              </Grid.Column>
              <Grid.Column>
                <Dropdown
                  style={{ border: "1px solid #000000" }}
                  onChange={this.typeDropdownChange}
                  value={this.state.selType}
                  options={this.state.competitionTypeOptions}
                  selection
                  placeholder="대회리그"
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            {this.state.competitions.length > 0 ? (
              <>
                <Grid.Row columns={2}>
                  {/* 대회리스트 드롭다운 */}
                  <Grid.Column>
                    {this.state.selectedCompetitionId !== 0 ? (
                      <Dropdown
                        style={{ border: "1px solid #000000" }}
                        onChange={this.handleChange}
                        options={this.state.competitions}
                        selection
                        placeholder="대회목록"
                        defaultValue={this.state.selectedCompetitionId}
                        fluid
                      />
                    ) : (
                      <></>
                    )}
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    {/* 대회등록버튼 */}
                    <CompetitionModalForm reloadPage={this.reloadPage} />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={1}>
                  {this.state.selectedCompetitionId !== 0 ? (
                    <Grid.Column>
                      {/* 대회정보 */}
                      <CompetitionDetail
                        competition_id={this.state.selectedCompetitionId}
                      />
                    </Grid.Column>
                  ) : (
                    <></>
                  )}
                </Grid.Row>
              </>
            ) : (
              <div className={styles.noDataWrap}>
                <Segment style={{ width: "90%", margin: 0 }}>
                  <p>조회된 대회가 없습니다.</p>
                </Segment>
                <CompetitionModalForm reloadPage={this.reloadPage} />
              </div>
            )}
          </Grid>
          {/* 리그별 신청팀 현황 및 게임 목록 */}
          {this.state.selectedCompetitionId !== 0 ? (
            <CompetitionLeagueTab
              competition_id={this.state.selectedCompetitionId}
            />
          ) : (
            <></>
          )}
        </Container>

        {/* <Footer/> */}
      </div>
    );
  }
}

export default CompetitionManager;
