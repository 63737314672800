import React, { Component } from "react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

import { Modal, Button, Input, Dropdown, Form, Segment, List, FormField, Checkbox } from "semantic-ui-react";

import Common from "../../../Common";
import RfidInputModal from "../../RfidInputModal.js";

class PlayerUpdateModalForm extends Component {
    state = {
        rfidCode: "",
        authLevelId: "",
        positionId: "",
        authLevelTime: "",
        authlevelOptions: "",
        authLevelId: "",
        authLevelTime: "",
        modal_open: false,
        rfidModalOpen: false,
        warningModalOpen: false,
        warningText: "",
        isLocation: false,
    };

    constructor() {
        super();
    }

    getPositionData() {
        fetch(Common.backendUrl + "/api/position/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((position, index) => {
                    if (index === 0) {
                        return;
                    }
                    arr.push({ key: position.id, text: position.position_text, value: position.id });
                });
                this.setState({ positionOptions: arr });
            });
    }

    getAuthLevelData() {
        fetch(Common.backendUrl + "/api/authlevel/")
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                let arr = [];
                data.forEach((authlevel) => arr.push({ key: authlevel.id, text: authlevel.auth_text, value: authlevel.id }));
                this.setState({ authlevelOptions: arr });
            });
    }

    componentDidMount() {
        this.getPositionData();
        this.getAuthLevelData();
    }

    handlePositionChange = (e, { value }) => {
        this.setState({ positionId: value });
    };

    handleAuthLevelChange = (e, { value }) => {
        this.setState({ authLevelId: value });
    };

    // handleRfidCodeChange = (e, { value }) => {
    //     this.setState({ rfidCode: value });
    // };

    handleAuthLevelTimeChange = (e, { value }) => {
        this.setState({ authLevelTime: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        let member = {
            member_rfid: "",
            member_authlevel_id: "",
            member_position_id: "",
            member_authlevel_time: "",
        };

        if (this.state.rfidCode === "") {
            member.member_rfid = this.props.member_rfid;
        } else {
            member.member_rfid = this.state.rfidCode;
        }

        if (this.state.authLevelId === "") {
            if (this.props.authlevel_id !== null) {
                member.member_authlevel_id = this.props.authlevel_id.id;
            }
        } else {
            member.member_authlevel_id = this.state.authLevelId;
        }

        if (this.state.positionId !== "") {
            member.member_position_id = this.state.positionId;
        }

        if (this.state.authLevelTime === "") {
            member.member_authlevel_time = this.props.authlevel_time;
        } else {
            member.member_authlevel_time = this.state.authLevelTime;
        }

        let form_data = new FormData();

        if (member.member_authlevel_id !== "") {
            form_data.append("member_authlevel_id", member.member_authlevel_id);
        }

        if (member.member_position_id !== "") {
            form_data.append("member_position_id", member.member_position_id);
        }

        form_data.append("member_rfid", member.member_rfid);
        form_data.append("member_authlevel_time", member.member_authlevel_time);

        fetch(Common.backendUrl + "/api/profileupdate/" + this.props.id, {
            method: "put",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    return response.json();
                } else {
                    return 200;
                }
            })
            .then((json) => {
                if (json !== undefined) {
                    if (json === 200) {
                        this.handleWarningModalOpen("수정 완료");
                        this.setState({ modal_open: false, isLocation: true });

                        return;
                    }
                    this.handleWarningModalOpen(json.detail);
                } else {
                    this.handleWarningModalOpen("개인기록 소수점을 체크해주세요");
                    this.setState({ modal_open: false });
                }
            });
    };

    handlePlayerDelete = (e) => {
        e.preventDefault();

        const form_data = new FormData();
        form_data.append("isDel", true);

        fetch(Common.backendUrl + "/api/profileupdate/" + this.props.id, {
            method: "put",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                this.handleWarningModalOpen("error");
                this.setState({ modal_open: false });
            } else {
                this.handleWarningModalOpen("삭제 완료");
                this.setState({ modal_open: false, isLocation: true });
                return response.json();
            }
        });
    };

    handleRfidInputClick = (e) => {
        this.setState({
            rfidModalOpen: true,
        });
    };

    handleRfidModalClose = () => {
        this.setState({
            rfidModalOpen: false,
            rfidCode: "",
        });
    };

    rfidCodeChange = (text) => {
        this.setState({
            rfidCode: text,
            rfidModalOpen: false,
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.isLocation) {
            window.location.reload();
        }
    };

    render() {
        let auth_level = null;

        if (this.props.authlevel_id === null) {
            auth_level = null;
        } else {
            auth_level = this.props.authlevel_id.id;
        }

        return (
            <>
                <Modal size={"tiny"} open={this.props.isOpen}>
                    <Modal.Header>선수정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">이름</label>
                                    <Input style={{ pointerEvents: "none" }} type="text" name="name" maxLength="20" defaultValue={this.props.member_text} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">포지션</label>
                                    {this.props.member_position === 1 ? <Input style={{ pointerEvents: "none" }} type="text" defaultValue="단장" required /> : <Dropdown placeholder="포지션 선택" selection options={this.state.positionOptions} name="position_text" defaultValue={this.props.member_position} onChange={this.handlePositionChange} fluid />}
                                </Form.Field>

                                <Form.Field>
                                    <label className="label">레벨</label>
                                    <Dropdown placeholder="레벨 선택" selection options={this.state.authlevelOptions} defaultValue={auth_level} name="authlevel" onChange={this.handleAuthLevelChange} fluid />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">개인 기록</label>
                                    <Input type="float" name="level time" placeholder="소수점 2자리까지 입력" defaultValue={this.props.authlevel_time} onChange={this.handleAuthLevelTimeChange} required />
                                </Form.Field>

                                <Form.Field>
                                    <label className="label">RFID</label>
                                    {this.state.rfidCode === "" ? <Input onClick={(e) => this.handleRfidInputClick(e)} type="text" name="rfid_code" value={this.props.member_rfid} required /> : <Input onClick={(e) => this.handleRfidInputClick(e)} type="text" name="rfid_code" value={this.state.rfidCode} required />}
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    수정하기
                                </Button>
                                {/* <Button negative className="button is-info" onClick={(e) => this.handlePlayerDelete(e)}>
                                    삭제
                                </Button> */}
                                <Button
                                    color="black"
                                    onClick={() => {
                                        this.setState({ rfidCode: "" });
                                        this.props.closeMethod();
                                    }}
                                >
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <RfidInputModal isOpen={this.state.rfidModalOpen} changeRfid={this.rfidCodeChange} closeMethod={this.handleRfidModalClose} />
            </>
        );
    }
}

export default PlayerUpdateModalForm;
