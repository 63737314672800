import React, { Component } from "react";
import { Button, Checkbox, Form, Header, Icon, Modal, Dropdown } from "semantic-ui-react";

class CertificateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 0,
        };
    }

    selectMenuChange = (e, { value }) => {
        this.setState({
            selectedOption: value,
        });
    };

    render() {
        const dropOptions = [
            { key: 0, text: "자격확인서", value: 0 },
            { key: 1, text: "수상실적확인서", value: 1 },
            // { key: 2, text: "승인", value: 1 },
        ];

        return (
            <Modal open={this.props.isOpen} size="mini">
                <Header textAlign="center" style={{ fontFamily: "Noto Sans KR" }}>
                    출력할 항목을 선택하세요
                </Header>
                <Modal.Content style={{ display: "flex", flexDirection: "column", alignItems: "left", justifyContent: "center", paddingTop: 0 }}>
                    <p style={{ textAlign: "center" }}>증명서 출력비용(건당) : 1000원</p>
                    <Dropdown selection placeholder="메뉴를 선택하세요" defaultValue={0} options={dropOptions} onChange={this.selectMenuChange} />
                </Modal.Content>
                <Modal.Actions style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <Button color="green" style={{ marginRight: "5px" }} inverted onClick={() => (window.location.href = `/payment/${this.state.selectedOption}`)}>
                        {/* <Button color='green' style={{marginRight: "5px"}} inverted onClick={() => alert("준비중입니다.")}> */}
                        <Icon name="print" /> 결제하기
                    </Button>
                    <Button color="red" inverted onClick={() => this.props.closeMethod()}>
                        <Icon name="remove" /> 닫기
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default CertificateModal;
