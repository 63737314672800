import React, { Component } from "react";

import {
    Button,
    Modal,
    Form,
    Dropdown,
    Table,
    TableRow,
    Search,
} from 'semantic-ui-react'
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from '../Common';

class CompetitionTeamModalForm extends Component {
    state = {
        modal_open: false,
        competitionStateOptions: "",
        provinceData: "",
        provinceOptions: "",
        regionOptions: "",
        teamOptions: "",
        teamAllOptions: "",
        selProvinceId: "",
        selRegionId: "",
        selTeamId: "",
        selTeamLeagueId: "",
        teamData: "",
        memberList: "",
        warningModalOpen: false,
        warningText: "",
        reg_member: ""
    };

    constructor(props) {
        super(props)

        fetch(Common.backendUrl + "/api/province/")
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                this.state.provinceData = data;
                let arr = [];
                data.forEach(province => arr.push({ key: province.id, text: province.province_text, value: province.id }))
                this.state.provinceOptions = arr;
            });
    }

    getRegionData(province_id) {
        const data = this.state.provinceData;
        let newData = data.filter(function (province) {
            return province.id === province_id;
        })
        let regionObj = newData[0].regions;
        let arr = [];
        regionObj.forEach(region => arr.push({ key: region.id, text: region.region_text, value: region.id }))
        this.setState({ regionOptions : arr });
    }

    //getTeamData(region_id) {
    //    fetch(Common.backendUrl + "/api/team/" + region_id + "/" + this.props.league_id)
    //        .then(response => {
    //            if (response.status > 400) {
    //                    this.setState({ placeholder: "Something went wrong!" }
    //                );
    //            }
    //            return response.json();
    //        })
    //        .then(data => {
    //            this.setState({teamData: data});
    //            let arr = [];
    //            data.forEach(team => arr.push({ key: team.id, text: team.team_text + " (" + team.league.league_text + ")", value: team.id + "|" + team.league.id}))
    //            this.setState({teamOptions: arr});
    //        });
    //}
    componentDidMount(){
        this.getLeagueTeamData();
    }

    getLeagueTeamData() {
        fetch(Common.backendUrl + "/api/team/" + this.props.league_id)
            .then(response => {
                if (response.status > 400) {
                        this.setState({ placeholder: "Something went wrong!" }
                    );
                }
                return response.json();
            })
            .then(data => {
                this.setState({teamData: data});
                let arr = [];
                data.forEach(team => arr.push({ key: team.id, text: team.team_text + " (" + team.league.league_text + ")", value: team.id + "|" + team.league.id}))
                this.setState({teamAllOptions: arr});
            });
    }

   getMemberData(team_id){
        fetch(Common.backendUrl + "/api/teammember/" + team_id)
            .then(response => {
              if (response.status > 400) {
                return this.setState(() => {
                  return { placeholder: "Something went wrong!" };
                });
              }
              return response.json();
            })
            .then(data => {
              this.setState({
                memberList: data,
              });
            });
        
     }

    //provinceChange = (e, { value }) => {
    //    this.setState({ selProvinceId: value });
    //    this.getRegionData(value);
    //};

    //regionChange = (e, { value }) => {
    //    this.setState({ selRegionId: value });
    //    this.getTeamData(value);
    //};

    teamChange = (e, { value }) => {
        const params = value.split('|');
        this.setState({ selTeamId: params[0], selTeamLeagueId: params[1] });
        this.getMemberData(params[0]);
    };

    handleCheckBoxClick(e) {
        let members = document.querySelectorAll(".members");
        let idArray = [];
        let idString = ""
        if ( members.length > 0) {
            for (let i = 0; i < members.length; i++) {
                if (members[i].checked == true) {
                    idString = idString + "|||" + members[i].value;
                    idArray.push(members[i].value);
                    
                }
            }
        }
        idString = idString + "|||"
        this.setState({reg_member: idString})
    }

    handleSubmit = e => {
        e.preventDefault();

        let form_data = new FormData();

        form_data.append('reg_member', this.state.reg_member);
        form_data.append('team_id', this.state.selTeamId);
        form_data.append('competition_id', this.props.competition_id);
        form_data.append('league', this.props.league_id);
      
        fetch(Common.backendUrl + "/api/competitiont_aplication/make/", {
            method: 'post',
            body: form_data,
        })
            .then(response => {
                if (response.status > 400) {
                    if (response.status === 409)
                    this.handleWarningModalOpen("이미 등록되어 있는 팀입니다.");
                    this.setState({ modal_open: false, memberList: "" });
                } else {
                    this.handleWarningModalOpen("팀등록 완료");
                    return response.json();
                }
            })
            .then(data => {
                this.setState({ modal_open: false, memberList: "" });
                this.props.reloadPage();
            });

    };

    handleWarningModalOpen = (text) => {
        this.setState({
          warningModalOpen: true,
          warningText: text,
        })
      }
    
      
    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }


    render() {

        let list = [];
        if (this.state.memberList !== ""){
            list[0] = this.state.memberList.map(
                info => (
                    <TableRow key={info.id}>
                        <Table.Cell textAlign='center'>{info.member_text}</Table.Cell>
                        <Table.Cell textAlign='center'>{info.member_id}</Table.Cell>
                        {info.member_position !== null &&
                        <Table.Cell textAlign='center'>{info.member_position.position_text}</Table.Cell>
                        }
                        {info.member_position === null &&
                        <Table.Cell textAlign='center'>{info.member_position}</Table.Cell>
                        }
                        <Table.Cell textAlign='center'><input className="members" name="test" type="checkbox" value={info.id + "," + info.team.team_text + "," + info.team.id + "," + info.team.league.league_text} onClick={(e)=> this.handleCheckBoxClick(e)}></input></Table.Cell>
                    </TableRow>
                    )
                );
        } else {
            list = []
        }

        return (
            <>
                <Modal
                    size={'tiny'}
                    onClose={() => this.setState({ modal_open: false })}
                    onOpen={() => this.setState({ modal_open: true })}
                    open={this.state.modal_open}
                    trigger={<Button size='medium' primary>팀등록</Button>}
                >
                    <Modal.Header>팀 등록</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <label className="label">등록팀</label>
                                    <Dropdown placeholder='팀을 선택하세요.' selection options={this.state.teamAllOptions} name="team" fluid search onChange={this.teamChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label className="label">참가 선수</label>
                                    <Table striped unstackable>
                                        <Table.Header>
                                          <Table.Row>
                                            <Table.HeaderCell width={1} textAlign='center'>이름</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'>선수자격번호</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'>포지션</Table.HeaderCell>
                                            <Table.HeaderCell width={1} textAlign='center'>참가여부</Table.HeaderCell>
                                          </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                          {list[0]}
                                        </Table.Body>
                                    </Table>
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">등록하기</Button>
                                <Button color='black' onClick={() => this.setState({ modal_open: false, memberList: "" })}>취소</Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default CompetitionTeamModalForm