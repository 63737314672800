import React, { Component } from "react";
import { Button, Icon, Modal, Loader } from "semantic-ui-react";
import styles from "./TeamModal.module.css";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import PlayerInfoModal from "./PlayerInfoModal.js";

export default class TeamModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: this.props.isOpen,
            teamId: this.props.teamId,
            isLoad: false,
            isRequest: this.props.isOpen,
            teamInfo: "",
            winData: [],
            teamMember: [],
            warningModalOpen: false,
            warningText: "",
            playerModalIsOpen: false,
            selectedPlayerInfo: "",
        };
    }

    componentDidMount = () => {
        this.setState({
            isOpen: this.props.isOpen,
            teamId: this.props.teamId,
        });
    };

    getTeamData = (teamId) => {
        fetch(Common.backendUrl + `/api/teamdetail/${teamId}`)
            .then((res) => {
                if (res.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                this.setState({
                    teamInfo: data,
                    isRequest: false,
                });
                this.getTeamMemberData(teamId);
            });
    };

    getTeamMemberData = (teamId) => {
        fetch(Common.backendUrl + `/api/teammember/${teamId}`)
            .then((res) => {
                if (res.status > 400) {
                    this.handleWarningModalOpen("팀 목록을 불러오지 못했습니다.");
                }
                return res.json();
            })
            .then((data) => {
                this.setState({
                    teamMember: data,
                    isLoad: true,
                });
            });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    playerModalClose = () => {
        this.setState({
            playerModalIsOpen: false,
        });
    };

    playerClicked = (e, playerInfo) => {
        e.preventDefault();

        this.setState({
            playerModalIsOpen: true,
            selectedPlayerInfo: playerInfo,
        });
    };

    render() {
        let win_rank = "";
        let win_count = 0;

        // if (this.state.winData.length > 0 && this.props.league_id < 4){
        if (this.state.winData && this.props.league_id < 4) {
            if (this.state.winData.length > 0) {
                win_rank = this.state.winData[0].winner__win_rank;
                win_count = this.state.winData[0].win_count;
            } else {
                win_count = 0;
            }
        }

        return (
            <>
                <Modal
                    className={styles.wrap_modal}
                    style={{ background: "radial-gradient(circle, rgba(68,69,154,1) 0%, rgba(11,26,42,1) 66%)" }}
                    size="large"
                    open={this.props.isOpen} //여기에 받아온 props 전달
                >
                    {this.state.isLoad ? (
                        <div className={styles.content_div}>
                            <p>{this.state.teamInfo.league.league_text}</p>
                            <h1 style={{ color: "white" }}>{this.state.teamInfo.team_text}</h1>

                            <div>
                                <div className={styles.team_logo_div}>
                                    {/* <p>{this.state.teamInfo.team_text}</p> */}
                                    {this.state.teamInfo.team_image_file ? <img style={{ backgroundColor: "black" }} src={this.state.teamInfo.team_image_file} alt="team logo" /> : <img style={{ backgroundColor: "black" }} src="/images/new_logo_big.png" alt="team logo" />}
                                </div>
                                <div className={styles.member_div}>
                                    {this.state.teamMember.length > 0 ? (
                                        this.state.teamMember.map((member) => (
                                            <div key={member.id} className={styles.member_profile} onClick={(e) => this.playerClicked(e, member)}>
                                                {member.image_file == null ? <img src="/images/default_profile.png" style={{ backgroundColor: "#020202" }} alt="member profile image" /> : <img src={member.image_file} alt="member profile image" />}
                                                <p>{member.member_text}</p>
                                                <p>{member.member_position.position_text}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ fontFamily: "Noto Sans KR", fontSize: "25px", fontWeight: "bold", color: "#ffffff" }}>등록된 선수가 없습니다.</p>
                                    )}
                                </div>
                            </div>
                            <a
                                className={styles.style_a}
                                onClick={() => {
                                    this.props.close_method();
                                    this.setState({ isLoad: false });
                                }}
                            >
                                닫기
                            </a>
                        </div>
                    ) : (
                        <>
                            <Loader style={{ fontFamily: "Noto Sans KR", fontSize: "30px" }}>Loading...</Loader>
                            {this.props.teamId ? this.getTeamData(this.props.teamId) : <></>}
                        </>
                    )}
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <PlayerInfoModal isOpen={this.state.playerModalIsOpen} playerInfo={this.state.selectedPlayerInfo} closeMethod={this.playerModalClose} />
            </>
        );
    }
}
