import React, { Component } from "react";
import styles from "./RankPlayer.module.css";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class PlayerLevelTimeRank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playerList: [],
        };
    }

    getPlayerRankList(level_type_id) {
        fetch(Common.backendUrl + "/api/player/rank/" + level_type_id)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ playerList: data });
            });
    }

    componentDidMount() {
        this.getPlayerRankList(this.props.level_type_id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.level_type_id !== prevProps.level_type_id) {
            this.getPlayerRankList(this.props.level_type_id);
        }
    }

    secToMin = (string) => {
        const [minSec, decimalSec] = string.split(".");
        if (minSec % 60 < 10) {
            return parseInt(minSec / 60) + "'" + "0" + (minSec % 60) + "''" + decimalSec;
        } else {
            return parseInt(minSec / 60) + "'" + (minSec % 60) + "''" + decimalSec;
        }
    };

    render() {
        let list = [];
        const player = this.state.playerList;
        const { t } = this.props;

        if (this.state.playerList) {
            if (this.state.playerList.length > 0) {
                for (let i = 0; i < player.length; i++) {
                    // let profile = 'http://kdsa.viasofts.com:8000/media/' + player[i]['image_file']
                    let profile = player[i]["image_file"];

                    if (player[i].rank == 1) {
                        list.push(
                            <tr className={styles.tableRow}>
                                <td className={styles.trophyRow}>
                                    <img className={styles.trophy} src="/images/rank/trophy.png"></img>
                                </td>
                                <td className={styles.rank}>{player[i].rank}</td>
                                {player[i].player_rank - player[i].rank > 0 && player[i].player_rank !== 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankUp} src="/images/rank/rank_up.png"></img>
                                        {player[i].player_rank - player[i].rank}
                                    </td>
                                )}
                                {player[i].player_rank - player[i].rank === 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                )}
                                {player[i].player_rank === 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                )}
                                {player[i].player_rank - player[i].rank < 0 && player[i].player_rank !== 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankDown} src="/images/rank/rank_down.png"></img>
                                        {player[i].rank - player[i].player_rank}
                                    </td>
                                )}
                                {player[i]["image_file"] !== "" && (
                                    <td className={styles.ProfileImgRow}>
                                        <img className={styles.profile} src={"https://kdsa-react-s3.s3.amazonaws.com/" + profile}></img>
                                    </td>
                                )}
                                {player[i]["image_file"] === "" && (
                                    <td className={styles.ProfileImgRow}>
                                        <img className={styles.profile} src="/images/default_teamlogo_02.png"></img>
                                    </td>
                                )}
                                <td className={styles.ProfileName}>{player[i].member_text}</td>
                                <td className={styles.TeamRow}>{player[i].team__team_text}</td>
                                <td className={styles.LevelRow}>
                                    {player[i].cert_grade}
                                    {t("rank.RankPlayer.grade")}
                                </td>
                                <td className={styles.record}>
                                    <div className={styles.rankRecord}>
                                        <span>{this.secToMin(player[i].member_authlevel_time)}</span>
                                    </div>
                                </td>
                            </tr>
                        );
                    } else {
                        list.push(
                            <tr className={styles.tableRow}>
                                <td className={styles.trophyRow}></td>
                                <td className={styles.rank}>{player[i].rank}</td>
                                {player[i].player_rank - player[i].rank > 0 && player[i].player_rank !== 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankUp} src="/images/rank/rank_up.png"></img>
                                        {player[i].player_rank - player[i].rank}
                                    </td>
                                )}
                                {player[i].player_rank - player[i].rank === 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                )}
                                {player[i].player_rank === 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankSame} src="/images/rank/rank_same.png"></img>
                                    </td>
                                )}
                                {player[i].player_rank - player[i].rank < 0 && player[i].player_rank !== 0 && (
                                    <td className={styles.rankImgRow}>
                                        <img className={styles.rankDown} src="/images/rank/rank_down.png"></img>
                                        {player[i].rank - player[i].player_rank}
                                    </td>
                                )}
                                {player[i]["image_file"] !== "" && (
                                    <td className={styles.ProfileImgRow}>
                                        <img className={styles.profile} src={"https://kdsa-react-s3.s3.amazonaws.com/" + profile}></img>
                                    </td>
                                )}
                                {player[i]["image_file"] === "" && (
                                    <td className={styles.ProfileImgRow}>
                                        <img className={styles.profile} src="/images/default_teamlogo_02.png"></img>
                                    </td>
                                )}
                                <td className={styles.ProfileName}>{player[i].member_text}</td>
                                <td className={styles.TeamRow}>{player[i].team__team_text}</td>
                                <td className={styles.LevelRow}>
                                    {player[i].cert_grade}
                                    {t("rank.RankPlayer.grade")}
                                </td>
                                <td className={styles.record}>
                                    <div className={styles.rankRecord}>
                                        <span>{this.secToMin(player[i].member_authlevel_time)}</span>
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                }
            }
        }

        return (
            <div className={styles.table_wrap}>
                <div style={{ marginBottom: "20px" }}></div>
                <table className={styles.rank_table}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("rank.RankPlayer.Ranking")}</th>
                            <th>{t("rank.RankPlayer.Rank_Change")}</th>
                            <th>{t("rank.RankPlayer.Player")}</th>
                            <th>{t("rank.RankPlayer.Player_Information")}</th>
                            <th>{t("rank.RankPlayer.Affiliation_Team")}</th>
                            <th>{t("rank.RankPlayer.Level")}</th>
                            <th>{t("rank.RankPlayer.Record")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan="8">
                                <div className={styles.bodyTable}>
                                    <table width="100%" cellPadding="0" cellSpacing="0">
                                        {list}
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withTranslation()(PlayerLevelTimeRank);
