import React, { Component } from "react";
import styles from "./CertificateOfExperience.module.css";
import Common from "../Common.js";

let renderCnt = 0;

class CertificateOfExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crntDate: "",
            paymentData: "",
            user: "",
            competitionData: [],
        };
    }

    componentDidMount = () => {
        if (this.props.match) {
            this.getPaymentData(this.props.match.params.number);
        }
        this.getCurrentUser();

        const crntDate = new Date();
        const year = crntDate.getFullYear();
        let month = "";
        let date = "";

        if (crntDate.getMonth() + 1 < 10) {
            month = "0" + (crntDate.getMonth() + 1);
        } else {
            month = crntDate.getMonth() + 1;
        }
        if (crntDate.getDate() < 10) {
            date = "0" + crntDate.getDate();
        } else {
            date = crntDate.getDate();
        }

        this.setState({
            crntDate: year + "." + month + "." + date,
        });

        window.onafterprint = () => {
            // 프린트 이후 checking값 0으로 변경해서 프린트가 진행이 되었다는 걸 저장
            const formData = new FormData();
            formData.append("checking", 0);

            fetch(Common.backendUrl + `/api/payment/get/${this.props.match.params.number}`, {
                method: "put",
                body: formData,
            })
                .then((res) => res.json())
                .then((json) => {
                    if (window.confirm("완료되었습니다. 마이페이지로 돌아가시겠습니까?")) {
                        window.opener.location.href = "/mypage";
                    }
                    window.close();
                });
        };
    };

    componentDidUpdate = () => {
        // alert(renderCnt)
        // 살리기
        if (this.state.competitionData && renderCnt === 3) {
            setTimeout(() => {
                window.onbeforeprint = () => {
                    alert("인쇄창을 닫을 시 재결재해야합니다. 주의해주세요.");
                };
                window.print();
            }, 3000);
        }
        renderCnt = renderCnt + 1;
    };

    getCurrentUser() {
        if (sessionStorage.getItem("token")) {
            fetch(Common.backendUrl + "/api/current/", {
                headers: {
                    Authorization: `JWT ${sessionStorage.getItem("token")}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        user: json,
                    });
                });
        }
    }

    getPaymentData = (unique_num) => {
        fetch(Common.backendUrl + `/api/payment/get/${unique_num}`)
            .then((res) => res.json())
            .then((json) => {
                // 살리기
                if (json[0].checking === 0) {
                    alert("만료되었습니다.");
                    window.location.href = "/";
                }

                const competitionText = json[0].competitions.slice(3, -3).split("|||");
                // console.log(json);
                // console.log(competitionText);
                this.getCompetitionData(competitionText);
                // this.getCompetitionData(["166", "167"])
                this.setState({
                    paymentData: json,
                });
            });
    };

    getCompetitionData = (competitionText) => {
        const competitionData = new Array();

        competitionText.map((id) => {
            fetch(Common.backendUrl + `/api/competition/${id.split(",")[0].split("///")[0]}`)
                .then((res) => res.json())
                .then((json) => {
                    const tempArray = new Array();
                    let awards = "-";
                    const teamName = id.split(",")[0].split("///")[1];
                    let league = id.split(",")[4];

                    const teamId = this.getParticipateTeamData(teamName)
                        .then((result) => {
                            return result;
                        })
                        .then((participateTeamId) => {
                            if (league === "1부리그") {
                                if (json.team_1st) {
                                    if (json.team_1st.split("|||").indexOf(participateTeamId[0].id.toString()) === 0) {
                                        awards = "우승";
                                    } else if (json.team_1st.split("|||").indexOf(participateTeamId[0].id.toString()) === 1) {
                                        awards = "준우승";
                                    } else if (json.team_1st.split("|||").indexOf(participateTeamId[0].id.toString()) === 2) {
                                        awards = "3위";
                                    } else if (json.team_1st.split("|||").indexOf(participateTeamId[0].id.toString()) === 3) {
                                        awards = "4위";
                                    } else {
                                        awards = "-";
                                    }
                                }
                            } else if (league === "2부리그") {
                                if (json.team_2st) {
                                    if (json.team_2st.split("|||").indexOf(participateTeamId[0].id.toString()) === 0) {
                                        awards = "우승";
                                    } else if (json.team_2st.split("|||").indexOf(participateTeamId[0].id.toString()) === 1) {
                                        awards = "준우승";
                                    } else if (json.team_2st.split("|||").indexOf(participateTeamId[0].id.toString()) === 2) {
                                        awards = "3위";
                                    } else if (json.team_2st.split("|||").indexOf(participateTeamId[0].id.toString()) === 3) {
                                        awards = "4위";
                                    } else {
                                        awards = "-";
                                    }
                                }
                            } else if (league === "3부리그") {
                                if (json.team_3st) {
                                    if (json.team_3st.split("|||").indexOf(participateTeamId[0].id.toString()) === 0) {
                                        awards = "우승";
                                    } else if (json.team_3st.split("|||").indexOf(participateTeamId[0].id.toString()) === 1) {
                                        awards = "준우승";
                                    } else if (json.team_3st.split("|||").indexOf(participateTeamId[0].id.toString()) === 2) {
                                        awards = "3위";
                                    } else if (json.team_3st.split("|||").indexOf(participateTeamId[0].id.toString()) === 3) {
                                        awards = "4위";
                                    } else {
                                        awards = "-";
                                    }
                                }
                            } else if (league === "유소년 리그") {
                                if (json.team_4st) {
                                    if (json.team_4st.split("|||").indexOf(participateTeamId[0].id.toString()) === 0) {
                                        awards = "우승";
                                    } else if (json.team_4st.split("|||").indexOf(participateTeamId[0].id.toString()) === 1) {
                                        awards = "준우승";
                                    } else if (json.team_4st.split("|||").indexOf(participateTeamId[0].id.toString()) === 2) {
                                        awards = "3위";
                                    } else if (json.team_4st.split("|||").indexOf(participateTeamId[0].id.toString()) === 3) {
                                        awards = "4위";
                                    } else {
                                        awards = "-";
                                    }
                                }
                            }
                            tempArray.push(json);
                            tempArray.push(awards);
                            tempArray.push(league);
                            tempArray.push(teamName);
                            competitionData.push(tempArray);
                        })
                        .then(() => {
                            if (competitionData.length === competitionText.length) {
                                // competitionData.sort((a, b) => parseFloat(a[0].id) - parseFloat(b[0].id));

                                competitionData.sort((a, b) => new Date(b[0].competition_start_date) - new Date(a[0].competition_start_date));
                                const result = new Array(competitionData);

                                this.setState({
                                    competitionData: result,
                                });

                                if (competitionData.length > 10) {
                                    const temp1 = new Array();
                                    temp1.push(competitionData.slice(0, 10));
                                    const n = 20; //tweak this to add more items per line

                                    const temp2 = new Array(Math.ceil(competitionData.slice(11).length / n)).fill().map((_) => competitionData.splice(11, n));

                                    const result = temp1.concat(temp2);
                                    this.setState({
                                        competitionData: result,
                                    });
                                } else {
                                    const result = new Array(competitionData);
                                    this.setState({
                                        competitionData: result,
                                    });
                                }
                            }
                        });
                    // console.log("111111111", teamId);
                    // if (league === "1부리그") {
                    //     console.log("2222222222", teamId);
                    //     if (json.team_1st) {
                    //         console.log(this.state.paymentData[0].profile.team.id.toString());
                    //         console.log(teamName);
                    //         console.log(json.team_1st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()));
                    //         if (json.team_1st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 0) {
                    //             awards = "우승";
                    //         } else if (json.team_1st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 1) {
                    //             awards = "준우승";
                    //         } else if (json.team_1st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 2) {
                    //             awards = "3위";
                    //         } else if (json.team_1st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 3) {
                    //             awards = "4위";
                    //         } else {
                    //             awards = "-";
                    //         }
                    //     }
                    // } else if (league === "2부리그") {
                    //     if (json.team_2st) {
                    //         if (json.team_2st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 0) {
                    //             awards = "우승";
                    //         } else if (json.team_2st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 1) {
                    //             awards = "준우승";
                    //         } else if (json.team_2st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 2) {
                    //             awards = "3위";
                    //         } else if (json.team_2st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 3) {
                    //             awards = "4위";
                    //         } else {
                    //             awards = "-";
                    //         }
                    //     }
                    // } else if (league === "3부리그") {
                    //     if (json.team_3st) {
                    //         if (json.team_3st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 0) {
                    //             awards = "우승";
                    //         } else if (json.team_3st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 1) {
                    //             awards = "준우승";
                    //         } else if (json.team_3st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 2) {
                    //             awards = "3위";
                    //         } else if (json.team_3st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 3) {
                    //             awards = "4위";
                    //         } else {
                    //             awards = "-";
                    //         }
                    //     }
                    // } else if (league === "유소년 리그") {
                    //     if (json.team_4st) {
                    //         if (json.team_4st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 0) {
                    //             awards = "우승";
                    //         } else if (json.team_4st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 1) {
                    //             awards = "준우승";
                    //         } else if (json.team_4st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 2) {
                    //             awards = "3위";
                    //         } else if (json.team_4st.split("|||").indexOf(this.state.paymentData[0].profile.team.id.toString()) === 3) {
                    //             awards = "4위";
                    //         } else {
                    //             awards = "-";
                    //         }
                    //     }
                    // }
                    // tempArray.push(json);
                    // tempArray.push(awards);
                    // tempArray.push(league);
                    // tempArray.push(teamName);
                    // competitionData.push(tempArray);
                    // console.log(tempArray);
                });
            // .finally(() => {
            //     if (competitionData.length === competitionText.length) {
            //         // competitionData.sort((a, b) => parseFloat(a[0].id) - parseFloat(b[0].id));

            //         competitionData.sort((a, b) => new Date(b[0].competition_start_date) - new Date(a[0].competition_start_date));
            //         const result = new Array(competitionData);

            //         this.setState({
            //             competitionData: result,
            //         });

            //         if (competitionData.length > 10) {
            //             const temp1 = new Array();
            //             temp1.push(competitionData.slice(0, 10));
            //             const n = 20; //tweak this to add more items per line

            //             const temp2 = new Array(Math.ceil(competitionData.slice(11).length / n)).fill().map((_) => competitionData.splice(11, n));

            //             const result = temp1.concat(temp2);
            //             this.setState({
            //                 competitionData: result,
            //             });
            //         } else {
            //             const result = new Array(competitionData);
            //             this.setState({
            //                 competitionData: result,
            //             });
            //         }
            //     }
            // });
        });
    };

    getParticipateTeamData = async (teamName) => {
        const response = await fetch(Common.backendUrl + `/api/team/search/${teamName}`);
        const data = await response.json();
        // console.log(data);
        return data;
        // .then((response) => {
        //     if (response.status > 400) {
        //         return this.setState(() => {
        //             return { placeholder: "Something went wrong!" };
        //         });
        //     }
        //     // return response.json().t;
        //     response.json().then((data) => {
        //         return data;
        //     });
        // });
        // .then((data) => {
        //     console.log(data);
        //     return data;
        //     // this.setState({teamList: data})
        // });
    };

    render() {
        const { paymentData, competitionData } = this.state;
        let lastOutIndex = 0;

        if (competitionData) {
            lastOutIndex = competitionData.length;
        }

        return (
            competitionData &&
            this.state.competitionData.map((competition, outIndex) => (
                <div key={outIndex} className={styles.wrap_div}>
                    <img className={styles.back_img} src="/images/payment/certificate_logo.png" alt="back image" />
                    {/* {outIndex === 1 &&
                        <img className={styles.back_img} src="/images/payment/certificate_logo.png" alt="back image" />
                    }
                    {outIndex === 2 &&
                        <img className={styles.back_img} src="/images/payment/certificate_logo.png" alt="back image" />
                    } */}
                    {/* {competitionData[0].length > 12 &&
                        <img className={styles.back_img1} src="/images/payment/certificate_logo.png" alt="back image" />
                    }
                    {competitionData[0].length > 35 &&
                        <img className={styles.back_img2} src="/images/payment/certificate_logo.png" alt="back image" />
                    } */}
                    {outIndex === 0 && (
                        <div className={styles.title_div}>
                            <img src="../big_logo.png" alt="logo" />
                            <div>
                                <h3>대한드론축구협회</h3>
                                <h1>수상실적확인서</h1>
                            </div>
                        </div>
                    )}
                    {outIndex === 0 && (
                        <table className={styles.info_table}>
                            <tbody>
                                <tr>
                                    {/* <td className={styles.table_title} rowspan="5"> */}
                                    <td rowspan="5">
                                        수&nbsp;&nbsp;상&nbsp;&nbsp;자
                                        <br />
                                        인&nbsp;적&nbsp;사&nbsp;항
                                    </td>
                                </tr>
                                <tr>
                                    {/* <td className={styles.table_title}>성&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td> */}
                                    <td>성&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td>
                                    <td>{paymentData[0].profile.member_text}</td>
                                </tr>
                                <tr>
                                    {/* <td className={styles.table_title}>생&nbsp;&nbsp;&nbsp;년&nbsp;&nbsp;&nbsp;월&nbsp;&nbsp;&nbsp;일</td> */}
                                    <td>생&nbsp;&nbsp;&nbsp;년&nbsp;&nbsp;&nbsp;월&nbsp;&nbsp;&nbsp;일</td>
                                    <td>{paymentData[0].profile.birth_date.split("T")[0]}</td>
                                </tr>
                                <tr>
                                    {/* <td className={styles.table_title}>팀&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td> */}
                                    <td>팀&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</td>
                                    <td>{paymentData[0].profile.team.team_text}</td>
                                </tr>
                                <tr>
                                    {/* <td className={styles.table_title}>회 원 등 록 번 호</td> */}
                                    <td>선 수 자 격 번 호</td>
                                    <td>{paymentData[0].profile.member_id}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}

                    <table className={styles.info_table}>
                        <tbody style={{ fontSize: "15px" }}>
                            <tr>
                                <td style={{ width: "5%" }} className={styles.table_title2}>
                                    구&nbsp;&nbsp;분
                                </td>
                                <td style={{ width: "50%" }}>대&nbsp;&nbsp;&nbsp;회&nbsp;&nbsp;&nbsp;명&nbsp;&nbsp;&nbsp;칭</td>
                                <td style={{ width: "10%" }}>참&nbsp;&nbsp;가&nbsp;&nbsp;리&nbsp;&nbsp;그</td>
                                <td style={{ width: "15%" }}>참&nbsp;&nbsp;가&nbsp;&nbsp;팀</td>
                                <td style={{ width: "10%" }}>대&nbsp;&nbsp;회&nbsp;&nbsp;일&nbsp;&nbsp;시</td>
                                <td style={{ width: "10%" }}>수&nbsp;&nbsp;상&nbsp;&nbsp;내&nbsp;&nbsp;역</td>
                            </tr>
                            {competition.map((data, index) => (
                                <tr key={data.id} className={styles.page_break}>
                                    {outIndex === 0 ? <td className={styles.table_title2}>{index + 1}</td> : outIndex === 1 ? <td className={styles.table_title2}>{index + outIndex + 10}</td> : <td className={styles.table_title2}>{index + 31}</td>}
                                    <td>{data[0].competition_text}</td>
                                    <td>{data[2]}</td>
                                    <td>{data[3]}</td>
                                    <td>{data[0].competition_start_date}</td>
                                    <td>{data[1]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {lastOutIndex - 1 === outIndex && (
                        <>
                            <div style={{ marginTop: "20px", width: "90%", maxWidth: "793px" }}>
                                <table style={{ borderCollapse: "collapse", textAlign: "center" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ backgroundColor: "#f5f5f5", width: "30px", border: "1px solid #010101" }}>기&nbsp;관&nbsp;명</td>
                                            <td style={{ border: "1px solid #010101" }}>사단법인 대한드론축구협회</td>
                                        </tr>
                                        <tr>
                                            <td style={{ backgroundColor: "#f5f5f5", width: "30px", border: "1px solid #010101" }}>Tel</td>
                                            <td style={{ border: "1px solid #010101" }}>063-211-0134</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p className={styles.date_p2}>{this.state.crntDate}</p>

                            <img style={{ width: "90%", maxWidth: "500px" }} src="../sign.png" alt="sign" />
                        </>
                    )}

                    <p style={{ position: "absolute", bottom: "50px", left: "50%", fontSize: "9px" }}>
                        {outIndex + 1}/{lastOutIndex}
                    </p>
                </div>
            ))
        );
    }
}

export default CertificateOfExperience;
