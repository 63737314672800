import React, { Component } from "react";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import styles from "./IntroKDSA.module.css";
import { withTranslation } from "react-i18next";
import i18n from "../lang/i18n.js";

import { ReactComponent as OrgKR } from "./org_chart.svg";
import { ReactComponent as OrgEN } from "./org_chart_en.svg";
import { ReactComponent as OrgCH } from "./org_chart_ch.svg";
import { ReactComponent as OrgFR } from "./org_chart_fr.svg";
import { ReactComponent as OrgJP } from "./org_chart_jp.svg";

class IntroKDSA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinceData: "",
            regionsCnt: "",
            teamArray: "",
            crntLang: "",
        };
    }

    componentDidMount = () => {
        document.title = "협회소개 - 대한드론축구협회";
        // this.getProvinceRegion();
        this.setState({
            crntLang: i18n.language,
        });
    };

    // getProvinceRegion = () => {
    //     fetch(Common.backendUrl + "/api/all/status")
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 return res.json();
    //             } else {
    //                 alert("error");
    //             }
    //         })
    //         .then((json) => {
    //             let regionCntArray = new Array();
    //             let provinceData = new Array();
    //             const regions = json.map((province) => {
    //                 provinceData.push([province.id, province.province_text]);
    //                 return province.regions;
    //             });

    //             regions.map((region, index) => {
    //                 // 지부에 팀이 없을때를 판별해서 팀이 없을때(지부장이 없을때) 는 지부 카운팅 x
    //                 let cnt = 0;
    //                 region.forEach((temp) => {
    //                     if (temp.team !== null) {
    //                         cnt += 1;
    //                     }
    //                 });
    //                 regionCntArray.push(cnt);
    //             });

    //             this.setState({
    //                 provinceData: provinceData,
    //                 regionsCnt: regionCntArray,
    //             });
    //             this.getTeamCount(provinceData);
    //         });
    // };

    // getTeamCount = (provinceData) => {
    //     let teamArray = new Array();

    //     provinceData.map((province, index) => {
    //         let teamTotalCnt = 0;
    //         let teamNomalCnt = 0;
    //         let teamKidsCnt = 0;
    //         let teamMemberCnt = 0;

    //         fetch(Common.backendUrl + `/api/all/status/team/${province[0]}`)
    //             .then((res) => {
    //                 if (res.status === 200) {
    //                     return res.json();
    //                 }
    //             })
    //             .then((json) => {
    //                 json.map((team) => {
    //                     if (team.league.id !== 4) {
    //                         teamNomalCnt += 1;
    //                     } else {
    //                         teamKidsCnt += 1;
    //                     }

    //                     if (team.membership === 1) {
    //                         teamMemberCnt += 1;
    //                     }
    //                 });

    //                 teamArray.push({ index: index, provinceId: province[1], teamTotal: json.length, teamNomalCnt: teamNomalCnt, teamKidsCnt: teamKidsCnt, teamMembership: teamMemberCnt });
    //                 teamArray.sort(function (a, b) {
    //                     if (a.index > b.index) {
    //                         return 1;
    //                     }
    //                     if (a.index < b.index) {
    //                         return -1;
    //                     }
    //                     return 0;
    //                 });
    //                 this.setState({ teamArray: teamArray });
    //             });
    //     });
    // };

    render() {
        window.scrollTo(0, 0);
        const { t } = this.props;
        const { provinceData, regionsCnt, teamArray } = this.state;

        return (
            <>
                <Topmenu />
                <div className={styles.intro_tap_box}>
                    <div className={styles.text_div}>
                        <h3></h3>
                        <h1>{t("Introduction.IntroKDSA.Main_Title")}</h1>
                        <div style={{ marginTop: "70px", lineHeight: "180%" }} dangerouslySetInnerHTML={{ __html: t("Introduction.IntroKDSA.Content") }}></div>
                    </div>
                    <div className={styles.text_div} style={{ marginTop: "90px" }}>
                        <h3></h3>
                        <h1>{t("Introduction.IntroKDSA.OrgChart")}</h1>
                    </div>
                    <div className={styles.orgChart_div}>
                        {this.state.crntLang === "ko" && <OrgKR style={{ borderRadius: "5px", width: "100%" }} />}
                        {this.state.crntLang === "en" && <OrgEN style={{ borderRadius: "5px", width: "100%" }} />}
                        {this.state.crntLang === "fr" && <OrgFR style={{ borderRadius: "5px", width: "100%" }} />}
                        {this.state.crntLang === "jp" && <OrgJP style={{ borderRadius: "5px", width: "100%" }} />}
                        {this.state.crntLang === "ch" && <OrgCH style={{ borderRadius: "5px", width: "100%" }} />}
                    </div>

                    <div className={styles.text_div} style={{ marginTop: "90px" }}>
                        <h3></h3>
                        <h1>{t("Introduction.IntroKDSA.Member")}</h1>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <table className={styles.squad_table}>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">{t("signup.MemberSignUp.Province")}</th>
                                        <th>{t("signup.MemberSignUp.Region")}</th>
                                        <th colSpan="3">{t("Topmenu.Trigger.Teams.Text")}</th>
                                        <th>{t("Introduction.IntroKDSA.Remarks")}</th>
                                    </tr>
                                    <tr>
                                        <th>{t("Introduction.IntroKDSA.SubTotal")}</th>
                                        <th>{t("Introduction.IntroKDSA.SubTotal")}</th>
                                        <th>{t("mypage.MemberMyPage.General")}</th>
                                        <th>{t("mypage.MemberMyPage.Youth")}</th>
                                        <th>{t("Introduction.IntroKDSA.RegularMember")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>서울</td>
                                        <td>2</td> {/* 지부 소계 */}
                                        <td>51</td> {/* 선수단 소계 */}
                                        <td>12</td> {/* 일반 선수단 수 */}
                                        <td>39</td> {/* 유소년 선수단 수 */}
                                        <td>8</td> {/* 정회원 수 */}
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>경기</td>
                                        <td>14</td>
                                        <td>154</td>
                                        <td>67</td>
                                        <td>87</td>
                                        <td>29</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>인천</td>
                                        <td>1</td>
                                        <td>9</td>
                                        <td>3</td>
                                        <td>6</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>강원</td>
                                        <td>5</td>
                                        <td>38</td>
                                        <td>27</td>
                                        <td>11</td>
                                        <td>14</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>충북</td>
                                        <td>2</td>
                                        <td>28</td>
                                        <td>12</td>
                                        <td>16</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>충남</td>
                                        <td>3</td>
                                        <td>32</td>
                                        <td>20</td>
                                        <td>12</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>세종</td>
                                        <td>-</td>
                                        <td>3</td>
                                        <td>3</td>
                                        <td>-</td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>대전</td>
                                        <td>-</td>
                                        <td>46</td>
                                        <td>12</td>
                                        <td>34</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>전북</td>
                                        <td>8</td>
                                        <td>314</td>
                                        <td>58</td>
                                        <td>256</td>
                                        <td>21</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>전남</td>
                                        <td>3</td>
                                        <td>61</td>
                                        <td>37</td>
                                        <td>24</td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>광주</td>
                                        <td>2</td>
                                        <td>71</td>
                                        <td>37</td>
                                        <td>34</td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>경북</td>
                                        <td>2</td>
                                        <td>336</td>
                                        <td>24</td>
                                        <td>312</td>
                                        <td>6</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>경남</td>
                                        <td>-</td>
                                        <td>319</td>
                                        <td>32</td>
                                        <td>287</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>부산</td>
                                        <td>-</td>
                                        <td>23</td>
                                        <td>4</td>
                                        <td>19</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>대구</td>
                                        <td>-</td>
                                        <td>13</td>
                                        <td>8</td>
                                        <td>5</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px" }}>울산</td>
                                        <td>-</td>
                                        <td>11</td>
                                        <td>7</td>
                                        <td>4</td>
                                        <td>3</td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "0px 10px", borderBottom: "1.5px solid #fff" }}>제주</td>
                                        <td style={{ borderBottom: "1.5px solid #fff" }}>-</td>
                                        <td style={{ borderBottom: "1.5px solid #fff" }}>2</td>
                                        <td style={{ borderBottom: "1.5px solid #fff" }}>2</td>
                                        <td style={{ borderBottom: "1.5px solid #fff" }}>-</td>
                                        <td style={{ borderBottom: "1.5px solid #fff" }}>-</td>
                                    </tr>
                                    <tr style={{ fontWeight: "700" }}>
                                        <td style={{ padding: "0px 10px" }}>합계</td>
                                        <td>42</td>
                                        <td>1511</td>
                                        <td>365</td>
                                        <td>1146</td>
                                        <td>117</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* {regionsCnt.length !== 0 && provinceData.length !== 0 && teamArray.length === 18 ? (
                                <table className={styles.squad_table}>
                                    <thead>
                                        <tr>
                                            <th rowSpan="2">{t("signup.MemberSignUp.Province")}</th>
                                            <th>{t("signup.MemberSignUp.Region")}</th>
                                            <th colSpan="3">{t("Topmenu.Trigger.Teams.Text")}</th>
                                            <th>{t("Introduction.IntroKDSA.Remarks")}</th>
                                        </tr>
                                        <tr>
                                            <th>{t("Introduction.IntroKDSA.SubTotal")}</th>
                                            <th>{t("Introduction.IntroKDSA.SubTotal")}</th>
                                            <th>{t("mypage.MemberMyPage.General")}</th>
                                            <th>{t("mypage.MemberMyPage.Youth")}</th>
                                            <th>{t("Introduction.IntroKDSA.RegularMember")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {provinceData.map((province, index) => (
                                            <tr key={index}>
                                                <td style={{ padding: "0px 10px" }}>{province[1].slice(0, -2)}</td>
                                                <td>{regionsCnt[index]}</td>
                                                <td>{teamArray[index].teamTotal}</td>
                                                <td>{teamArray[index].teamNomalCnt}</td>
                                                <td>{teamArray[index].teamKidsCnt}</td>
                                                <td>{teamArray[index].teamMembership}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <></>
                            )} */}
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(IntroKDSA);
