import React, { Component } from 'react';
import {
    Button,
    Checkbox,
    Form,
    FormField,
    Header,
    Icon,
    Input,
    Modal,
    ModalActions,
} from 'semantic-ui-react'
import Common from '../Common.js';
import ModalChoiceOne from '../warning_modal/ModalChoiceOne.js';
import { withTranslation } from 'react-i18next';

class PasswordChangeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText:'',
            whatFocus: '',
            isLocation: false,
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (document.getElementById('crntPwd').value.length < 8 || document.getElementById('crntPwd').value.length > 16) {
            this.setState({
                warningModalOpen: true,
                warningText: "8~16 사이의 비밀번호를 입력해주세요",
                whatFocus: 'crntPwd',
            })
            return;
        } else if (document.getElementById('newPwd').value.length < 8 || document.getElementById('newPwd').value.length > 16) {
            this.setState({
                warningModalOpen: true,
                warningText: "8~16 사이의 비밀번호를 입력해주세요",
                whatFocus: 'newPwd',
            })
            return;
        } else if (document.getElementById('checkNewPwd').value.length < 8 || document.getElementById('checkNewPwd').value.length > 16) {
            this.setState({
                warningModalOpen: true,
                warningText: "8~16 사이의 비밀번호를 입력해주세요",
                whatFocus: 'checkNewPwd',
            })
            return;
        } else {
            this.setState({ whatFocus: '' })
        }

        if (document.getElementById('newPwd').value !== document.getElementById('checkNewPwd').value){
            this.setState({
                warningModalOpen: true,
                warningText: "비밀번호가 일치하지 않습니다.",
                whatFocus: 'newPwd',
            })
            return
        }

        const formData = new FormData();

        formData.append('crntPwd', document.getElementById('crntPwd').value)
        formData.append('newPwd', document.getElementById('newPwd').value)

        fetch(Common.backendUrl + `/api/profileupdate/${this.props.profileId}`, {
            method: 'put',
            body: formData,
        }) .then(res => {
            return res.json();
        }) .then(data => {
            this.setState({
                warningModalOpen: true,
                warningText: data.message
            })

            if (data.isOk) {
                this.setState({
                    isLocation: true,
                })
            }
        })
    }

    handleWarningModalclose = () => {
        this.setState({
            warningModalOpen: false,
        })
        if (this.state.whatFocus !== ''){
            document.getElementById(this.state.whatFocus).focus();
        }
        if (this.state.isLocation) {
            window.location.reload();
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                open={this.props.isOpen}
                size='tiny'
            >
                <Modal.Header style={{fontFamily: 'Noto Sans KR', width: "100%", textAlign: "center"}}>
                    {t("find_password.SendMail.Change_Password")}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>{t("mypage.MemberMyPage.CrntPwd")}</label>
                            <input
                                placeholder={t("signup.MemberSignUp.Password_placeholder")}
                                type='password'
                                id='crntPwd'
                            />
                        </Form.Field>
                        <Form.Field style={{marginTop: "30px"}}>
                            <label>{t("find_password.SendMail.New_password")}</label>
                            <input
                                placeholder={t("signup.MemberSignUp.Password_placeholder")}
                                type='password'
                                id='newPwd'
                            />
                        </Form.Field>
                        <Form.Field>
                        <label>{t("find_password.SendMail.New_password_confirmation")}</label>
                            <input
                                placeholder={t("signup.MemberSignUp.Password_placeholder")}
                                type='password'
                                id='checkNewPwd'
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <Button color='blue' style={{fontSize: '12px', fontWeight: "normal", fontFamily: 'Noto Sans KR'}} onClick={(e) => this.handleSubmit(e)}>
                        변경하기
                    </Button>
                    <Button color='red' style={{fontSize: '12px', fontWeight: "normal", fontFamily: 'Noto Sans KR'}} onClick={() => this.props.closeMethod()}>
                        닫기
                    </Button>
                </Modal.Actions>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalclose} />
            </Modal>
        );
    }
}

export default withTranslation()(PasswordChangeModal);