import React, { Component } from "react";

import { Button, Modal, Input, Dropdown, Form } from "semantic-ui-react";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import Common from "../../../Common";

class RegionModifiyModalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_open: false,
            province_text: "",
            chief_name: "",
            phone_text: "",
            email_text: "",
            reg_date: "",
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const form_data = new FormData();

        if (this.state.province_text !== "") {
            form_data.append("province_text", this.state.province_text);
        }
        if (this.state.chief_name !== "") {
            form_data.append("chief_name", this.state.chief_name);
        }
        if (this.state.phone_text !== "") {
            form_data.append("phone_text", this.state.phone_text);
        }
        if (this.state.email_text !== "") {
            form_data.append("email_text", this.state.email_text);
        }
        if (this.state.reg_date !== "") {
            if (this.state.reg_date.length !== 10) {
                this.handleWarningModalOpen("YYYY-mm-DD 형식을 지켜주세요.");
                return;
            }

            form_data.append("reg_date", this.state.reg_date);
        }

        fetch(Common.backendUrl + `/api/province/detail/${this.props.selProvinceData.id}`, {
            method: "put",
            body: form_data,
        }).then((response) => {
            if (response.status >= 400) {
                this.handleWarningModalOpen("error");
                return;
            } else {
                this.handleWarningModalOpen("지회 수정 완료");
                this.setState({ modal_open: false });
                this.props.reloadData();
                return response.json();
            }
        });
    };

    handleProvinceTextChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            province_text: value,
        });
    };
    handleChiefNameChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            chief_name: value,
        });
    };
    handlePhoneTextChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            phone_text: value,
        });
    };
    handleEmailChange = (e, { value }) => {
        e.preventDefault();
        this.setState({
            email_text: value,
        });
    };

    handleRegDateChange = (e, { value }) => {
        e.preventDefault();

        let formatNum = value;

        // 공백제거
        value = value.replace(/\s/gi, "");

        if (value.length == 8) {
            formatNum = value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
            document.getElementById("reg_date").value = formatNum;
        }
        this.setState({
            reg_date: formatNum,
        });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
    };

    render() {
        return (
            <>
                <Modal size={"tiny"} onClose={() => this.setState({ modal_open: false })} onOpen={() => this.setState({ modal_open: true })} open={this.state.modal_open} trigger={<Button primary>지회수정</Button>}>
                    <Modal.Header>지회정보 입력</Modal.Header>
                    <Modal.Content>
                        <div className="column">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field required>
                                    <label className="label">지회명</label>
                                    <Input type="text" name="province_text" maxLength="50" defaultValue={this.props.selProvinceData.province_text} onChange={this.handleProvinceTextChange} required />
                                </Form.Field>

                                <Form.Field required>
                                    <label className="label">지회장</label>
                                    <Input type="text" name="chief_name" maxLength="50" defaultValue={this.props.selProvinceData.chief_name} onChange={this.handleChiefNameChange} required />
                                </Form.Field>

                                <Form.Field required>
                                    <label className="label">연락처</label>
                                    <Input type="text" name="phone_text" maxLength="50" defaultValue={this.props.selProvinceData.phone_text} onChange={this.handlePhoneTextChange} required />
                                </Form.Field>

                                <Form.Field required>
                                    <label className="label">e-mail</label>
                                    <Input type="text" name="email_text" maxLength="50" defaultValue={this.props.selProvinceData.email_text} onChange={this.handleEmailChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className="label">등록일</label>
                                    <Input type="text" id="reg_date" maxLength="10" defaultValue={this.props.selProvinceData.reg_date.split("T")[0]} placeholder="YYYY-mm-DD" onChange={this.handleRegDateChange} required />
                                </Form.Field>
                                <Button positive type="submit" className="button is-info">
                                    수정
                                </Button>
                                <Button color="black" onClick={() => this.setState({ modal_open: false })}>
                                    취소
                                </Button>
                            </Form>
                        </div>
                    </Modal.Content>
                </Modal>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default RegionModifiyModalForm;
