import React, { Component } from "react";
import TeamUpdateModalForm from "./TeamUpdateModalForm.js"
import {
  Header,
  List,
  Segment,
  Image,
} from 'semantic-ui-react'
import Common from '../../../Common';

class TeamDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team_data: {
        team_id: '',
        team_text: '',
        team_address: '',
        team_address_detail: '',
        team_membership: '',
        team_regdate: '',
        team_league: '',
        team_image: '',
        team_image_file: '',
      },
    }
    this.reloadPage = this.reloadPage.bind(this);
  }

  getTeamData() {
    const conf = {
        method: "get",
        headers: new Headers({ "Content-Type": "application/json" })
    }

    fetch(Common.backendUrl + "/api/teamdetail/" + this.props.team_id, conf)
      .then(response => {
        if (response.status > 400) {
          return this.setState(() => {
            return { placeholder: "Something went wrong!" };
          });
        }
        return response.json();
      })
      .then(data => {
        this.setState({
          target_team_id : this.props.team_id,
          team_data : {
            team_id : data.id,
            team_text : data.team_text,
            team_address : data.address,
            team_address_detail : data.address_detail,
            team_membership : data.membership,
            team_practice_info: data.practice_info,
            team_memo: data.memo_text,
            team_reg_date: data.reg_date,
            team_league : data.league.league_text,
            team_league_id: data.league.id,
            team_phone: data.team_phone,
            team_image : data.team_image,
            team_image_file : data.team_image_file,
            isHidden: data.isHidden,
            info_text: data.info_text,
          }
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.team_id !== prevProps.team_id) {
        this.getTeamData();
    }
  
    if (this.props.region_id !== prevProps.region_id || this.props.province_id !== prevProps.province_id ) {
      this.setState({
        team_data:{
        team_id : ''
        }
      })
      
    }
  }

  componentDidMount() {
    if(this.props.team_id !== ''){
        this.getTeamData();
    }
  }

  reloadPage() {
      this.getTeamData();
  }

  toDate(dateStr){
    if (dateStr){
        let parts = dateStr.split('-')
        let day = parts[2].split('T')      
        let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0]
        return covertedDate;
    }else{
        return;
    } 
  }

  render() {
      let team_address = ''

      if (this.state.team_data.team_address === 'nan' || this.state.team_data.team_address === '\N'){
        team_address = '없음'
      }else{
        team_address = this.state.team_data.team_address
      }

    
      
      return (
          <div>
            <Segment attached='top' clearing secondary style={{border: "1px solid #000000"}}>
                <Header as='h3' floated='left'>팀정보</Header>
                { this.state.team_data.team_id !== '' &&
                <Header as='h4' floated='right'><TeamUpdateModalForm province_id={this.props.province_id} 
                                                                     region_id={this.props.region_id} 
                                                                     team_id={this.state.team_data.team_id}
                                                                     team_text={this.state.team_data.team_text} 
                                                                     team_membership={this.state.team_data.team_membership}
                                                                     team_reg_date={this.state.team_data.team_reg_date}
                                                                     team_address={team_address}
                                                                     team_address_detail={this.state.team_data.team_address_detail}
                                                                     team_league_id={this.state.team_data.team_league_id}
                                                                     team_practice_info={this.state.team_data.team_practice_info}
                                                                     team_memo={this.state.team_data.team_memo}
                                                                     team_phone={this.state.team_data.team_phone}
                                                                     team_image={this.state.team_data.team_image}
                                                                     team_image_file={this.state.team_data.team_image_file}
                                                                     isHidden={this.state.team_data.isHidden}
                                                                     info_text={this.state.team_data.info_text}
                                                                     reloadPage={this.reloadPage}
                                                                     /></Header>
                }
            </Segment>
            <Segment attached style={{border: "1px solid #000000", borderTop: "0", fontSize: "15px"}}>
                <List>
                    <List.Item >
                        팀명 : {this.state.team_data.team_text}
                    </List.Item >
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    {this.state.team_data.team_membership === '' &&
                    <List.Item >
                        정회원/일반회원 :
                    </List.Item>
                    }
                    {this.state.team_data.team_membership === 0 &&
                    <List.Item >
                        정회원/일반회원 : 일반회원
                    </List.Item>
                    }
                    
                    {this.state.team_data.team_membership === 1 &&
                    <List.Item >
                        정회원/일반회원 : 정회원
                    </List.Item>
                    }
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    <List.Item >
                        팀창단일 : {this.toDate(this.state.team_data.team_reg_date)}
                    </List.Item>
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    <List.Item >
                        대표주소 : {team_address}
                    </List.Item>
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    <List.Item >
                        상세주소 : {this.state.team_data.team_address_detail}
                    </List.Item>
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    <List.Item >
                        소속리그 :  {this.state.team_data.team_league}
                    </List.Item>
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    <List.Item >
                        전화번호 : {this.state.team_data.team_phone}
                    </List.Item>
                    <hr style={{border: "0", borderTop: "1px solid #000000", margin: "5px 0px"}} />
                    {this.state.team_data.team_image_file !== null &&
                    <List.Item >
                        팀 이미지 : <Image src={ this.state.team_data.team_image_file } size='tiny' verticalAlign='middle'/>
                    </List.Item>
                    }
                    {this.state.team_data.team_image_file === null &&
                    <List.Item >
                        팀 이미지 : 없음
                    </List.Item>
                    }
                    
                </List>
            </Segment>
          </div>
          
        )
    }
}

export default TeamDetail