import React, { Component } from 'react';
import styles from "./ApplyTeamRegistrationManager.module.css"
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import ApplyTeamRegistrationForm from "./ApplyTeamRegistrationForm.js";
import {
    Dropdown,
    Table,
    Pagination,
    Button,
} from 'semantic-ui-react'
import Common from "../../../Common";

class ApplyTeamRegistrationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 0,
            selectedPage: 1,
            totalPages: 1,
            teamRegistrations: '',
            registrationCnt: '',
            registrationContent: '',
            registrationModalOpen :false,
            warningModalOpen: false,
            warningText: "",
        }
    }

    componentDidMount = () => {
        window.scrollTo(0,0);
        this.getTeamRegistrationData(0,1)
    }

    getTeamRegistrationData = (option, page) => {
        // fetch(Common.backendUrl + `/api/team/requests/manage/${option}?page=${page}`)
        fetch(Common.backendUrl + `/api/teamregistration/list/${option}?page=${page}`)
            .then(res => {
                if (res.status >= 400) {
                    this.setState({
                        warningModalOpen:true,
                        warningText: "error"
                    })
                    return;
                }
                return res.json()
            })
            .then(data => {
                if (data["count"] % 10 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10) + 1
                    })
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10)
                    })
                }
                this.setState({
                    teamRegistrations: data.results,
                    registrationCnt: data.count,
                })
            })
    }

    selectMenuChange = (e, { value }) => {
        this.setState({
            selectedOption: value,
        })
        this.getTeamRegistrationData(value, this.state.selectedPage)
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page
        })
        this.getTeamRegistrationData(this.state.selectedOption, page)
    }

    teamRegistrationsTableClick = (e, request) => {
        e.preventDefault();
        let registrationContent = new Array();
        registrationContent.push(request)
        
        this.setState({
            registrationContent,
            registrationModalOpen: true,
        })
        
    }

    registrationModalClose = () => {
        this.setState({
            registrationModalOpen: false,
        })
    }

    
    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    
    render() {
        const selectMenuOptions = [
            { key: 0, text: "신청", value: 0 },
            { key: 1, text: "반려", value: 2 },
            { key: 2, text: "승인", value: 1 },
        ]
        const { teamRegistrations, registrationCnt } = this.state;
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.wrap_div}>
                    <p style={{ fontSize: "20px", fontWeight: "bold" }}>선수단 창단신청 관리</p>
                    <div className={styles.content_div}>
                        <Dropdown style={{ marginBottom: "30px", border: "1px solid #000000" }}
                            selection
                            placeholder="메뉴를 선택하세요"
                            defaultValue= {0}
                            options={selectMenuOptions}
                            onChange={this.selectMenuChange}
                        />
                        <Table striped unstackable style={{border: "1px solid #000000"}}>
                            <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                <Table.Row style={{ width: "100%" }}>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>no</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청자</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청 팀 이름</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청일자</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>처리상태</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className={styles.teamRegistrations_table} >
                                {teamRegistrations && registrationCnt != 0 ?
                                    teamRegistrations.map((registration, index) =>
                                    index === teamRegistrations.length-1?
                                        <Table.Row key={registration.id} onClick={(e) => this.teamRegistrationsTableClick(e, registration)} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell width="1" textAlign='center'>{registration.id}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{registration.applicant_name}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{registration.team_name}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{registration.create_at}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{registration.status === 0 ? "신청" : (registration.status === 1 ? "승인" : (registration.status === 2) ? "반려" : "")}</Table.Cell>    
                                        </Table.Row>
                                        :
                                        <Table.Row key={registration.id} onClick={(e) => this.teamRegistrationsTableClick(e, registration)} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{registration.id}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{registration.applicant_name}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{registration.team_name}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{registration.create_at}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{registration.status === 0 ? "신청" : (registration.status === 1 ? "승인" : (registration.status === 2) ? "반려" : "")}</Table.Cell>
                                            
                                        </Table.Row>
                                    )
                                    :
                                    <Table.Row>
                                        <Table.Cell textAlign='center' colSpan="6" style={{ fontSize: "15px" }}>요청사항이 없습니다.</Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                        <Pagination
                            style={{ marginTop: "10px" }}
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={this.state.totalPages}
                            onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                        />
                    </div>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <ApplyTeamRegistrationForm isOpen={this.state.registrationModalOpen} registration={this.state.registrationContent} closeMethod={this.registrationModalClose} />
            </div>
        );
    }
}

export default ApplyTeamRegistrationManager;