import React, { Component } from 'react'
import styles from "./LeagueTab.module.css";
import {withTranslation} from 'react-i18next';
import Common from '../Common';
class LeagueTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      league_list: [],
      sel_league_id: 1,
    }
  }

  getLeagueList() {
    const { t } = this.props;

    this.setState({
      league_list: [
        { id: 1, league_text: t('competition_info_detail.CompetitionDetailManager.Division_1') },
        { id: 2, league_text: t('competition_info_detail.CompetitionDetailManager.Division_2') },
        { id: 3, league_text: t('competition_info_detail.CompetitionDetailManager.Division_3') },
        { id: 4, league_text: t('competition_info_detail.CompetitionDetailManager.Division_Youth')}
    ]
    })
    // fetch(Common.backendUrl + "/api/league/")
    //   .then(response => {
    //     if (response.status > 400) {
    //       return this.setState(() => {
    //         return { placeholder: "Something went wrong!" };
    //       });
    //     }
    //     return response.json();
    //   })
    //   .then(data => {
    //     this.setState({
    //       league_list: data
    //     });
    //   });
  }

  componentDidMount() {
    this.getLeagueList();
  }

  onClickHandler(leagueId) {
    this.setState({ sel_league_id: leagueId });
    this.props.onSelLeague(leagueId);
  }

  render() {
    let itemDiv = [];
    for (let i = 0; i < this.state.league_list.length - 1; i++) {
      if (parseInt(this.state.sel_league_id) === parseInt(this.state.league_list[i].id)) {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.league_list[i].id)} className={styles.item_div} key={this.state.league_list[i].id} style={{ backgroundColor: "#5814E1", color: "white", fontWeight: "bold" }}>
          {this.state.league_list[i].league_text}
        </div>);
      } else {
        itemDiv.push(<div onClick={(e) => this.onClickHandler(this.state.league_list[i].id)} className={styles.item_div} key={this.state.league_list[i].id} style={{fontWeight: "bold"}}>
          {this.state.league_list[i].league_text}
        </div>);
      }
    }
    return (
      <div style={{ display: "flex" }}>
        {itemDiv}
      </div>
    )
  }
}
export default withTranslation()(LeagueTab);