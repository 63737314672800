import React, { Component } from 'react';
import styles from "./TeamRequestsManage.module.css"
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import TeamRequestsManageModal from "./TeamRequestsManageModal.js";
import {
    Dropdown,
    Table,
    Pagination,
    Button,
} from 'semantic-ui-react'
import Common from "../../../Common";

class TeamRequestsManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: "신청",
            selectedPage: 1,
            totalPages: 1,
            warningModalOpen: false,
            warningText: "",
            teamRequests: "",
            requestModalOpen: false,
            requestContent: "",
            requestsCnt: 0,
        }
    }

    componentDidMount = () => {
        this.getTeamRequestsData(this.state.selectedOption, this.state.selectedPage);
    }

    getTeamRequestsData = (option, page) => {
        fetch(Common.backendUrl + `/api/team/requests/manage/${option}?page=${page}`)
            .then(res => {
                if (res.status >= 400) {
                    this.handleWarningModalOpen("error");
                    return;
                }
                return res.json()
            })
            .then(data => {
                if (data["count"] % 10 != 0) {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10) + 1
                    })
                } else {
                    this.setState({
                        totalPages: Math.floor(data["count"] / 10)
                    })
                }
                this.setState({
                    teamRequests: data.results,
                    requestsCnt: data.count,
                })
            })
    }

    selectMenuChange = (e, { value }) => {
        this.setState({
            selectedOption: value,
        })
        this.getTeamRequestsData(value, this.state.selectedPage)
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    handleRequestModalClose = () => {
        this.setState({
            requestModalOpen: false,
        })
    }

    navOnClick = (e, page) => {
        this.setState({
            selectedPage: page
        })
        this.getTeamRequestsData(page)
    }

    teamRequestsTableClick = (e, request) => {
        e.preventDefault();
        // this.handleRequestModalOpen(request)
        let requestContent = new Array();
        requestContent.push(request)
        this.setState({
            requestModalOpen: true,
            requestContent,
        })
    }

    render() {
        const selectMenuOptions = [
            { key: 0, text: "신청", value: "신청" },
            { key: 1, text: "반려", value: "반려" },
            { key: 2, text: "승인", value: "승인" },
        ]
        const { teamRequests, requestsCnt } = this.state;
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.wrap_div}>
                    <p style={{ fontSize: "24px", fontWeight: "bold" }}>선수상태 및 선수단 변경 요청관리</p>
                    <div className={styles.content_div}>
                        <Dropdown style={{ marginBottom: "30px", border: "1px solid #000000" }}
                            selection
                            placeholder="메뉴를 선택하세요"
                            defaultValue="신청"
                            options={selectMenuOptions}
                            onChange={this.selectMenuChange}
                        />
                        <Table striped unstackable style={{border: "1px solid #000000"}}>
                            <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                <Table.Row style={{ width: "100%" }}>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>no</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>작성자</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>신청일자</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>분류</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='center'>요청사항</Table.HeaderCell>
                                    <Table.HeaderCell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>처리상태</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className={styles.teamRequests_table} >
                                {teamRequests && requestsCnt != 0 ?
                                    teamRequests.map((request, index) =>
                                    index === teamRequests.length-1 ?
                                        <Table.Row key={request.id} onClick={(e) => this.teamRequestsTableClick(e, request)} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell width="1" textAlign='center'>{request.id}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{request.profile.member_text}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'>{request.create_at.split("T")[0]}</Table.Cell>
                                            <Table.Cell width="1" textAlign='center'> {request.select_menu} </Table.Cell>
                                            {request.content.length < 22 ?
                                                <Table.Cell width="2" textAlign='left'>{request.content}</Table.Cell>
                                                :
                                                <Table.Cell width="2" textAlign='left'>{request.content.slice(0, 20)}...</Table.Cell>
                                            }
                                            <Table.Cell width="1" textAlign='center'>{request.request_state}</Table.Cell>
                                        </Table.Row>
                                        :
                                        <Table.Row key={request.id} onClick={(e) => this.teamRequestsTableClick(e, request)} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.id}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.profile.member_text}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.create_at.split("T")[0]}</Table.Cell>
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'> {request.select_menu} </Table.Cell>
                                            {request.content.length < 22 ?
                                                <Table.Cell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='left'>{request.content}</Table.Cell>
                                                :
                                                <Table.Cell style={{borderBottom: "1px solid #000000"}} width="2" textAlign='left'>{request.content.slice(0, 20)}...</Table.Cell>
                                            }
                                            <Table.Cell style={{borderBottom: "1px solid #000000"}} width="1" textAlign='center'>{request.request_state}</Table.Cell>
                                        </Table.Row>
                                    )
                                    :
                                    <Table.Row>
                                        <Table.Cell textAlign='center' colSpan="6" style={{ fontSize: "15px" }}>요청사항이 없습니다.</Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                        <Pagination
                            style={{ marginTop: "10px" }}
                            boundaryRange={1}
                            defaultActivePage={1}
                            ellipsisItem={null}
                            firstItem={null}
                            lastItem={null}
                            siblingRange={2}
                            totalPages={this.state.totalPages}
                            onPageChange={(e, data) => this.navOnClick(e, data.activePage)}
                        />
                    </div>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <TeamRequestsManageModal isOpen={this.state.requestModalOpen} requestContent={this.state.requestContent} closeMethod={this.handleRequestModalClose} />
            </div>
        );
    }
}

export default TeamRequestsManager;