import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";

export default function AuthLeaderRoute({ component: Component, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);
  const [warningText, setWarningText] = useState("");

  const closeMethod = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Route
        {...rest}
        render={props => {
          if (sessionStorage.getItem('token')) {
            if (sessionStorage.getItem('level') === '210') {
              return <Component {...props} />
            } else {
              setIsOpen(true)
              setWarningText("접근 권한이 없습니다.")
              return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            }
          } else {
            setIsOpen(true)
            setWarningText("로그인이 필요합니다.")
            return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
          }
        }
        }
      />
      <ModalChoiceOne isOpen={isOpen} warningText={warningText} closeMethod={closeMethod} />
    </>
  );
}