import React, { Component } from "react";
import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";
import styles from "./PlayerRankMain.module.css";
import Common from "../Common";

class PlayerRankMain extends Component {
    constructor() {
        super();
        this.state = {
            playerList: "",
        };
    }

    componentDidMount = () => {
        this.HandleProPlayerList();
    };

    HandleProPlayerList = () => {
        fetch(Common.backendUrl + "/api/playerrank/" + 3)
            .then((response) => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then((data) => {
                this.setState({
                    playerList: data,
                });
            });

        let tempTime = "113.10";
        const min = parseInt(tempTime.split(".")[0] / 60) + "'" + (tempTime.split(".")[1] % 60) + "'" + tempTime.split(".")[1];
    };

    secToMin = (string) => {
        const [minSec, decimalSec] = string.split(".");
        if (minSec % 60 < 10) {
            return parseInt(minSec / 60) + "'" + "0" + (minSec % 60) + "''" + decimalSec;
        } else {
            return parseInt(minSec / 60) + "'" + (minSec % 60) + "''" + decimalSec;
        }
    };

    renderImgBorder1 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border1} src="/images/main/1st_border.png" alt="1st team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border1} src="/images/main/1st_border.png" alt="1st team" />;
        }
    };

    renderImgBorder2 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border2} src="/images/main/2nd_border.png" alt="2nd team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border2} src="/images/main/2nd_border.png" alt="2nd team" />;
        }
    };

    renderImgBorder3 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border3} src="/images/main/3rd_border.png" alt="3rd team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border3} src="/images/main/3rd_border.png" alt="3rd team" />;
        }
    };

    render() {
        return (
            <div className={styles.wrap_div}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "300px" }}>
                    <h1>TOP PLAYER</h1>
                    <hr style={{ width: "100%" }} />
                    <button className={styles.all_rank_btn} onClick={() => (window.location.href = "/rank/player")}>
                        전체 순위
                    </button>
                </div>

                <div className={styles.content_div}>
                    {this.state.playerList.length > 2 ? (
                        <>
                            <div className={styles.rank_div2}>
                                <div style={{ position: "relative" }}>
                                    {this.state.playerList[1]["image_file"] === null || this.state.playerList[1]["image_file"] === "" ? (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img2} src="/images/default_profile.png" alt="2nd player" />
                                    ) : (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img2} src={"https://kdsa-react-s3.s3.amazonaws.com/" + this.state.playerList[1]["image_file"]} alt="2nd team logo" />
                                    )}
                                    {this.renderImgBorder2()}
                                    {/* <img className={styles.rank_team_border2} src="/images/main/2nd_border.png" alt="2nd team" /> */}
                                    <img className={styles.rank_flag} src="/images/main/2nd_flag.png" alt="2nd team" />
                                    <img className={styles.rank_flash2} src="/images/main/2nd_flash.png" art="flash" />
                                    <p className={styles.player_recored_text}>{this.secToMin(this.state.playerList[1]["member_authlevel_time"])}</p>
                                    <p className={styles.player_name}>{this.state.playerList[1]["member_text"]}</p>
                                    <p className={styles.player_team}>{this.state.playerList[1]["team__team_text"]}</p>
                                </div>
                            </div>

                            <div className={styles.rank_div}>
                                <div style={{ position: "relative" }}>
                                    {this.state.playerList[0]["image_file"] === null || this.state.playerList[0]["image_file"] === "" ? (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img1} src="/images/default_profile.png" alt="1st player" />
                                    ) : (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img1} src={"https://kdsa-react-s3.s3.amazonaws.com/" + this.state.playerList[0]["image_file"]} alt="1st team logo" />
                                    )}
                                    {this.renderImgBorder1()}
                                    {/* <img className={styles.rank_team_border1} src="/images/main/1st_border.png" alt="1st team" /> */}
                                    <img className={styles.rank_flag} src="/images/main/1st_flag.png" alt="1st team" />
                                    <img className={styles.rank_flash1} src="/images/main/1st_flash.png" art="flash" />
                                    <p className={styles.player_recored_text}>{this.secToMin(this.state.playerList[0]["member_authlevel_time"])}</p>
                                    <p className={styles.player_name}>{this.state.playerList[0]["member_text"]}</p>
                                    <p className={styles.player_team1}>{this.state.playerList[0]["team__team_text"]}</p>
                                </div>
                            </div>

                            <div className={styles.rank_div3}>
                                <div style={{ position: "relative" }}>
                                    {this.state.playerList[2]["image_file"] === null || this.state.playerList[2]["image_file"] === "" ? (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img2} src="/images/default_profile.png" alt="3rd player" />
                                    ) : (
                                        <img style={{ backgroundColor: "black" }} className={styles.logoTeam_img2} src={"https://kdsa-react-s3.s3.amazonaws.com/" + this.state.playerList[2]["image_file"]} alt="3rd team logo" />
                                    )}
                                    {this.renderImgBorder3()}
                                    {/* <img className={styles.rank_team_border3} src="/images/main/3rd_border.png" alt="3rd team" /> */}
                                    <img className={styles.rank_flag} src="/images/main/3rd_flag.png" alt="3rd team" />
                                    <img className={styles.rank_flash3} src="/images/main/3rd_flash.png" art="flash" />
                                    <p className={styles.player_recored_text}>{this.secToMin(this.state.playerList[2]["member_authlevel_time"])}</p>
                                    <p className={styles.player_name}>{this.state.playerList[2]["member_text"]}</p>
                                    <p className={styles.player_team}>{this.state.playerList[2]["team__team_text"]}</p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        );
    }
}

export default PlayerRankMain;
