import React, { Component, useEffect } from "react";
import styles from "./Main.module.css";
import Topmenu from "../Topmenu/Topmenu";
import PlayerRankMain from "./PlayerRankMain";
import TeamRankMain from "./TeamRankMain";
import Footer from "../footer/Footer";
import "semantic-ui-css/semantic.min.css";
import MainVideo from "./MainVideo.js";
import CompetitionInfo from "./CompetitionInfo.js";
import Shop from "./Shop.js";
import NationalPlayerMain from "./NationalPlayerMain";

class Mainpage extends Component {
  state = {
    login: sessionStorage.getItem("token") ? true : false,
  };

  render() {
    window.scrollTo(0, 0);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Topmenu />
        <div className={styles.wrap_div}>
          <MainVideo />
          {/* <TeamRankMain />
                    <PlayerRankMain /> */}
          <NationalPlayerMain noPageTitle />
          <CompetitionInfo />
          <Shop />
        </div>
        <Footer />
      </div>
    );
  }
}
export default Mainpage;
