import React, { Component } from "react";
import { Header, List, Segment, Image } from "semantic-ui-react";
import ProvinceModifyModalForm from "./ProvinceModifyModalForm.js";
import Common from "../../../Common";

export default class ProvinceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinceData: "",
        };
    }

    render() {
        return (
            <div>
                <Segment attached="top" clearing secondary style={{ border: "1px solid #000000" }}>
                    <Header as="h3" floated="left" style={{ margin: "0px" }}>
                        지회정보
                    </Header>
                    <Header as="h4" floated="right">
                        {this.props.selProvinceData.id !== undefined ? <ProvinceModifyModalForm selProvinceData={this.props.selProvinceData} reloadData={this.props.reloadData} /> : <></>}
                    </Header>
                </Segment>
                <Segment attached style={{ border: "1px solid #000000", borderTop: "0", fontSize: "15px" }}>
                    <List>
                        <List.Item>지회명 : {this.props.selProvinceData.province_text}</List.Item>
                        <hr style={{ border: "0", borderTop: "1px solid #000000", margin: "5px 0px" }} />
                        <List.Item>지회장 : {this.props.selProvinceData.chief_name}</List.Item>
                        <hr style={{ border: "0", borderTop: "1px solid #000000", margin: "5px 0px" }} />
                        <List.Item>연락처 : {this.props.selProvinceData.phone_text}</List.Item>
                        <hr style={{ border: "0", borderTop: "1px solid #000000", margin: "5px 0px" }} />
                        <List.Item>e-mail : {this.props.selProvinceData.email_text}</List.Item>
                        <hr style={{ border: "0", borderTop: "1px solid #000000", margin: "5px 0px" }} />
                        {this.props.selProvinceData.reg_date !== undefined ? <List.Item>등록일 : {this.props.selProvinceData.reg_date.split("T")[0]}</List.Item> : <></>}
                    </List>
                </Segment>
            </div>
        );
    }
}
