import React, { Component } from "react";
import styles from "./CertificateOfExperience.module.css";

class Credentials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            crntDate: "",
        };
    }

    componentDidMount = () => {
        const crntDate = new Date();
        const year = crntDate.getFullYear();
        let month = "";
        let date = "";

        if (crntDate.getMonth() + 1 < 10) {
            month = "0" + (crntDate.getMonth() + 1);
        } else {
            month = crntDate.getMonth() + 1;
        }
        if (crntDate.getDate() < 10) {
            date = "0" + crntDate.getDate();
        } else {
            date = crntDate.getDate();
        }

        this.setState({
            crntDate: year + "." + month + "." + date,
        });
    };

    render() {
        return (
            <div className={styles.wrap_div}>
                <img className={styles.back_img} src="/images/payment/certificate_logo.png" alt="back image" />
                <div className={styles.title_div}>
                    <img src="../big_logo.png" alt="logo" />
                    <div>
                        <h3>대한드론축구협회</h3>
                        <h1>선수자격확인서</h1>
                    </div>
                </div>
                <div className={styles.info_div}>
                    <p>성&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                        <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.name}</p>
                    </div>
                </div>
                <div className={styles.info_div}>
                    <p>생&nbsp;&nbsp;년&nbsp;&nbsp;&nbsp;월&nbsp;&nbsp;일</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                        <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.birthDate}</p>
                    </div>
                </div>
                <div className={styles.info_div}>
                    <p>선수자격번호</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                        <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.memberId}</p>
                    </div>
                </div>
                <div className={styles.info_div}>
                    <p>자&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;격&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;명</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                        <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.kind}</p>
                    </div>
                </div>
                <div className={styles.info_div}>
                    <p>등&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;급</p>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                        <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.authText}</p>
                    </div>
                </div>
                {this.props.kind === "드론축구 선수 자격증" && (
                    <div className={styles.info_div}>
                        <p>기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;록</p>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "left", flex: "1.5" }}>
                            <p style={{ margin: 0, marginBottom: "17px" }}>{this.props.memberAuthlevelTime}</p>
                        </div>
                    </div>
                )}

                <p className={styles.content_p}>
                    위 사람은 대한드론축구협회 위의 자격 과정을 이수하고 소정의 자격검증 시험에서 해당 자격을 취득하였음을
                    <br />
                    확인합니다.
                </p>

                <p className={styles.date_p}>{this.state.crntDate}</p>

                <img style={{ width: "90%", maxWidth: "500px" }} src="../sign.png" alt="sign" />
            </div>
        );
    }
}

export default Credentials;
