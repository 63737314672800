import React, { Component } from 'react'
import Topmenu from "../Topmenu/Topmenu"
import Footer from "../footer/Footer";
import styles from "./RankYouthTeam.module.css";


export default class RankYouthTeam extends Component {
    
    render() {
        return (
            <div>
                <Topmenu />
                <div className={styles.intro_tap_box} style={{ backgroundImage: "url('/images/intro_DS/bg_05.jpg')", alignItems: "center", justifyContent: "center" }}>
                    <h1>준비 중 입니다...</h1>
                </div>
                <Footer />
            </div>
        );
    }
}