import React, { Component } from "react";
import { Button, Form, Grid, Header, Message, Segment, Icon } from "semantic-ui-react";
import styles from "./login.module.css";
import Topmenu from "../Topmenu/Topmenu";
import Footer from "../footer/Footer";
import ModalChoiceOne from "../warning_modal/ModalChoiceOne.js";
import Common from "../Common";
import { withTranslation } from "react-i18next";

class MemberLogin extends Component {
    state = {
        member_homepage_id: "",
        member_password: "",
        warningModalOpen: false,
        warningText: "",
        warningLocation: false,
    };

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    handleMemberHomePageIdChange = (e, { value }) => {
        this.setState({ member_homepage_id: value });
    };

    handleMemberPasswordChange = (e, { value }) => {
        this.setState({ member_password: value });
    };

    getCurrentUser() {
        fetch(Common.backendUrl + "/api/current/", {
            headers: {
                Authorization: `JWT ${sessionStorage.getItem("token")}`,
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (!json.profile.isDel) {
                    this.handleWarningModalOpen(`${this.state.member_homepage_id}님 안녕하세요😀`);
                    if (json.profile.member_position.id === 1) {
                        sessionStorage.setItem("level", 210);
                    } else {
                        sessionStorage.setItem("level", json.profile.member_site_level);
                    }
                    sessionStorage.setItem("id", json.profile.id);
                    if (json.profile.isAdmin === true) {
                        sessionStorage.setItem("isAdmin", json.profile.isAdmin);
                    }
                    this.setState({
                        warningLocation: true,
                    });
                } else {
                    this.handleWarningModalOpen("로그인이 제한된 계정입니다. 관리자에게 문의하세요.");
                    sessionStorage.clear();
                }
            });
    }

    handleKeyDown = (e) => {
        if (e.key === "Enter") {
            this.handleLogin(e);
        }
    };

    handleLogin = (e) => {
        e.preventDefault();
        const { t } = this.props;
        let form_data = new FormData();

        form_data.append("username", this.state.member_homepage_id);
        form_data.append("password", this.state.member_password);

        fetch(Common.backendUrl + "/api/login/", {
            method: "POST",
            body: form_data,
        })
            .then((response) => {
                if (response.status >= 400) {
                    this.handleWarningModalOpen(t("login.MemberLogin.login_fail"));
                    return;
                } else if (response.status === 200) {
                    return response.json();
                }
            })
            .then((json) => {
                if (json !== undefined) {
                    sessionStorage.setItem("token", json.token);
                    this.getCurrentUser();
                }
            });
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
            warningLocation: false,
        });
    };

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        });
        if (this.state.warningLocation) {
            document.location.href = "/";
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <Topmenu />
                <div style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "hidden" }}>
                    <div style={{ height: "100vh" }} className={styles.stars}></div>
                    <div style={{ height: "100vh" }} className={styles.twinkling}></div>
                    {/* <div className={styles.clouds}></div> */}

                    <div className={styles.login_form_wrap}>
                        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
                            <Grid.Column style={{ maxWidth: 450 }}>
                                <Form onSubmit={this.handleLogin} size="large">
                                    <Segment className={styles.login_form_div}>
                                        <Header as="h2" inverted color="grey" textAlign="center" style={{ margin: "50px 0px", fontFamily: "Noto Sans KR" }}>
                                            {t("login.MemberLogin.LoginHeader")}
                                        </Header>
                                        <Form.Input fluid icon="user" iconPosition="left" placeholder={t("login.MemberLogin.Id")} onChange={this.handleMemberHomePageIdChange} onKeyDown={this.handleKeyDown} />
                                        <Form.Input fluid icon="lock" iconPosition="left" placeholder={t("login.MemberLogin.password")} type="password" onChange={this.handleMemberPasswordChange} onKeyDown={this.handleKeyDown} />
                                        <a className={`${styles.style_a} ${styles.mouse_hover} `} onClick={this.handleLogin} style={{ width: "fit-content" }}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            {t("login.MemberLogin.login")}
                                        </a>

                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                                            <a className={styles.style_a} style={{ alignItems: "center", justifyContent: "center", fontSize: "13px", height: "35px" }} href="/findid">
                                                <p className={styles.optionText} style={{ background: "none" }}>
                                                    {t("login.MemberLogin.find_ID")}
                                                </p>
                                            </a>

                                            <a className={styles.style_a} style={{ fontSize: "13px", height: "35px" }} href="/findpassword">
                                                <p className={styles.optionText} style={{ background: "none" }}>
                                                    {t("login.MemberLogin.find_Password")}
                                                </p>
                                            </a>

                                            <a className={styles.style_a} style={{ fontSize: "13px", height: "35px" }} href="/signup/check/">
                                                <p className={styles.optionText} style={{ background: "none" }}>
                                                    {t("login.MemberLogin.Sign_Up")}
                                                </p>
                                            </a>
                                        </div>
                                    </Segment>
                                </Form>
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
                <Footer />
            </>
        );
    }
}

export default withTranslation()(MemberLogin);
