import React, { Component } from 'react';
import {
    Input,
    Table,
    TableRow,
    Button
} from 'semantic-ui-react'
import Common from '../../../Common.js';
import styles from './TeamSearch.module.css';
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";

export default class TeamSearch extends Component {
    constructor(props){
        super(props);
        this.state = {
            teamList: '',
            warningModalOpen: false,
            warningText: '',

        }
    }

    getTeamSearchData = (teamName) => {
        fetch(Common.backendUrl + `/api/team/search/${teamName}`)
            .then(response => {
                if (response.status > 400) {
                    return this.setState(() => {
                        return { placeholder: "Something went wrong!" };
                    });
                }
                return response.json();
            })
            .then(data => {
                this.setState({teamList: data})
            });
    }
    

    clickSearchBtn = (e) => {
        e.preventDefault();
        if (document.getElementById('searchTeamName').value === ''){
            this.handleWarningModalOpen('선수단명을 입력하세요');
            return
        }
        
        this.getTeamSearchData(document.getElementById('searchTeamName').value)
    }

    clickSearchTeamTableRow = (e, provinceId, regionId, teamId) => {
        e.preventDefault();
        this.props.changeProvince(e, provinceId)
        this.props.changeRegion(e, regionId)
        this.props.teamChange(e, teamId)
    }

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        })
    }


    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
    }

    render() {
        let teams = [];

        if (this.state.teamList !== '') {
            teams = this.state.teamList.map(team => (
                <TableRow key={team.id} onClick={(e) => this.clickSearchTeamTableRow(e, team.region.province.id, team.region.id, team.id)} className={styles.tableRow} style={{ display: "table", width: "100%", tableLayout: "fixed" }}>
                    <Table.Cell textAlign='center'>{team.region.province.province_text}</Table.Cell>
                    <Table.Cell textAlign='center'>{team.region.region_text}</Table.Cell>
                    <Table.Cell textAlign='center'>{team.league.league_text}</Table.Cell>
                    <Table.Cell textAlign='center'>{team.team_text}</Table.Cell>
                </TableRow>
            ))
        }


        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <p style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>선수단 검색</p>
                <div style={{ marginBottom: "10px", display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                    <Input id='searchTeamName' placeholder="검색할 팀 명을 입력하세요." onChange={this.handleTeamSearchChange} style={{ width: "300px", marginRight: "10px", border: "1px solid #000000", borderRadius: ".28571429rem" }} />
                    <Button primary onClick={(e) => this.clickSearchBtn(e)}>검색</Button>
                </div>
                <div>
                    <Table striped unstackable style={{border: "1px solid #000000"}}>
                        <Table.Header style={{ display: "table", width: "100%", tableLayout: "fixed", borderBottom: "1px solid #000000" }}>
                            <Table.Row style={{ width: "100%" }}>
                                <Table.HeaderCell width="1" textAlign='center'>소속지회</Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign='center'>소속지부</Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign='center'>리그</Table.HeaderCell>
                                <Table.HeaderCell width="1" textAlign='center'>선수단명</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body style={{ display: "block", height: "200px", overflow: "auto" }}>
                            {teams}
                        </Table.Body>
                    </Table>
                </div>
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

