import React, { Component } from "react";
import MemberUpdateModalForm from "../manage_team/MemberUpdateModalForm.js";
import ModalChoiceOne from "../../../warning_modal/ModalChoiceOne.js";
import { Header, Segment, Button, Table, TableRow, Image, Grid, Dropdown } from "semantic-ui-react";
import Common from "../../../Common";

class RegionalPlayerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText: "",
            profiles: "",
            region: "전라북도",
            warningModalOpen: false,
            warningText: "",
        };
    }

    getRegionProfileData = (region) => {
        fetch(Common.backendUrl + "/api/player/regional/profile/" + region)
            .then((res) => {
                if (res.status > 400) {
                    this.handleWarningModalOpen("error❗❗");
                }
                return res.json();
            })
            .then((profiles) => {
                this.setState({
                    profiles,
                    region: region,
                });
            });
    };

    componentDidMount = () => {
        this.getRegionProfileData(this.state.region);
    };
    reloadPage = () => {
        this.getRegionProfileData(this.state.region);
    };

    handleWarningModalOpen = (text) => {
        this.setState({
            warningModalOpen: true,
            warningText: text,
        });
    };

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let day = parts[2].split("T");

            let d = parts[0] + "-" + parts[1] + "-" + day[0];
            return d;
        } else {
            return;
        }
    }

    regionChange = (e, { value }) => {
        this.getRegionProfileData(value);
    };

    render() {
        let regionOptions = [
            { key: 1, value: "강원도", text: "강원도" },
            { key: 2, value: "경기도", text: "경기도" },
            { key: 3, value: "경상북도", text: "경상북도" },
            { key: 4, value: "경상남도", text: "경상남도" },
            { key: 5, value: "전라북도", text: "전라북도" },
            { key: 6, value: "전라남도", text: "전라남도" },
            { key: 7, value: "충청북도", text: "충청북도" },
            { key: 8, value: "충청남도", text: "충청남도" },
            { key: 9, value: "서울특별시", text: "서울특별시" },
            { key: 10, value: "광주광역시", text: "광주광역시" },
            { key: 11, value: "대구광역시", text: "대구광역시" },
            { key: 12, value: "대전광역시", text: "대전광역시" },
            { key: 13, value: "부산광역시", text: "부산광역시" },
            { key: 14, value: "인천광역시", text: "인천광역시" },
            { key: 15, value: "울산광역시", text: "울산광역시" },
            { key: 16, value: "제주도", text: "제주도" },
            { key: 17, value: "경기북부", text: "경기북부" },
            { key: 18, value: "경기남부", text: "경기남부" },
            { key: 19, value: "경북 & 대구", text: "경북 & 대구" },
            { key: 20, value: "충청 & 대전", text: "충청 & 대전" },
            { key: 21, value: "전남 & 광주", text: "전남 & 광주" },
        ];

        let list = new Array(1);
        let match_type_str;
        if (this.state.profiles !== "") {
            list[0] = this.state.profiles.map((info, index) =>
                index === this.state.profiles.length - 1 ? (
                    <TableRow key={info.id}>
                        <Table.Cell textAlign="center">
                            <Image src={info.image_file} size="tiny" verticalAlign="middle" />
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            {info.member_text}
                            <br />({info.user_site_name})
                        </Table.Cell>
                        <Table.Cell textAlign="center">{info.member_id}</Table.Cell>
                        <Table.Cell textAlign="center">{this.toDate(info.birth_date)}</Table.Cell>
                        {info.member_position !== null && <Table.Cell textAlign="center">{info.member_position.position_text}</Table.Cell>}
                        {info.member_position === null && <Table.Cell textAlign="center">{info.member_position}</Table.Cell>}
                        <Table.Cell textAlign="center">{info.member_phone}</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_email}</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_rfid}</Table.Cell>
                        {info.member_authlevel !== null && <Table.Cell textAlign="center">{info.member_authlevel.auth_text}</Table.Cell>}
                        {info.member_authlevel === null && <Table.Cell textAlign="center">{info.member_authlevel}</Table.Cell>}
                        <Table.Cell textAlign="center">{info.cert_grade}급</Table.Cell>
                        <Table.Cell textAlign="center">{info.member_authlevel_time}초</Table.Cell>
                        <Table.Cell textAlign="center">{info.isAdmin ? "✅" : "❌"}</Table.Cell>
                        <Table.Cell textAlign="center">
                            <MemberUpdateModalForm
                                province_id={info.team.region.province.id}
                                region_id={info.team.region.id}
                                team_id={info.team.id}
                                member_text={info.member_text}
                                member_id={info.member_id}
                                birth_date={info.birth_date}
                                member_position={info.member_position}
                                member_phone={info.member_phone}
                                member_email={info.member_email}
                                member_rfid={info.member_rfid}
                                authlevel_id={info.member_authlevel}
                                authlevel_time={info.member_authlevel_time}
                                cert_grade={info.cert_grade}
                                kind={info.kind}
                                use_value={info.use_value}
                                image={info.image}
                                image_file={info.image_file}
                                id={info.id}
                                national={info.national_player}
                                regional={info.regional_player}
                                isAdmin={info.isAdmin}
                                reloadPage={this.reloadPage}
                                siteName={info.user_site_name}
                            />
                        </Table.Cell>
                    </TableRow>
                ) : (
                    <TableRow key={info.id}>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            <Image src={info.image_file} size="tiny" verticalAlign="middle" />
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_text}
                            <br />({info.user_site_name})
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_id}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {this.toDate(info.birth_date)}
                        </Table.Cell>
                        {info.member_position !== null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_position.position_text}
                            </Table.Cell>
                        )}
                        {info.member_position === null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_position}
                            </Table.Cell>
                        )}
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_phone}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_email}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_rfid}
                        </Table.Cell>
                        {info.member_authlevel !== null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_authlevel.auth_text}
                            </Table.Cell>
                        )}
                        {info.member_authlevel === null && (
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                {info.member_authlevel}
                            </Table.Cell>
                        )}
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.cert_grade}급
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.member_authlevel_time}초
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            {info.isAdmin ? "✅" : "❌"}
                        </Table.Cell>
                        <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                            <MemberUpdateModalForm
                                province_id={info.team.region.province.id}
                                region_id={info.team.region.id}
                                team_id={info.team.id}
                                member_text={info.member_text}
                                member_id={info.member_id}
                                birth_date={info.birth_date}
                                member_position={info.member_position}
                                member_phone={info.member_phone}
                                member_email={info.member_email}
                                member_rfid={info.member_rfid}
                                authlevel_id={info.member_authlevel}
                                authlevel_time={info.member_authlevel_time}
                                cert_grade={info.cert_grade}
                                kind={info.kind}
                                use_value={info.use_value}
                                image={info.image}
                                image_file={info.image_file}
                                id={info.id}
                                national={info.national_player}
                                regional={info.regional_player}
                                isAdmin={info.isAdmin}
                                reloadPage={this.reloadPage}
                                siteName={info.user_site_name}
                            />
                        </Table.Cell>
                    </TableRow>
                )
            );
        }
        return (
            <div>
                <Segment attached="top" clearing secondary style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        지역대표 목록
                    </Header>
                    <Dropdown
                        style={{ width: "20%", border: "1px solid #000000" }}
                        onChange={this.regionChange}
                        options={regionOptions}
                        defaultValue="전라북도"
                        // search={true}
                        selection
                        placeholder="지역선택"
                        fluid
                    />
                </Segment>
                <Segment attached style={{ display: "block", width: "100%", overflow: "auto", border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    사진
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    이름(id)
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    선수자격번호
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    생년월일
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    포지션
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    전화번호
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    이메일
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    RFID
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    레벨
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    급수
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    기록
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    관리자
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                    수정
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>{list[0]}</Table.Body>
                    </Table>
                </Segment>

                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </div>
        );
    }
}

export default RegionalPlayerManager;
