import React, { Component } from "react";
import CompetitionTeamModalForm from "./CompetitionTeamModalForm.js";
import CompetitionDrawModalForm from "./CompetitionDrawModalForm.js";
import CompetitionTeamApproveModalForm from "./CompetitionTeamApproveModalForm.js";
import { Header, Segment, Table, TableRow } from "semantic-ui-react";
import PlayerAddModal from "./PlayerAddModal.js";
import { Button } from "react-scroll";

class CompetitionJoinTeamList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playerAddModalOpen: false,
            selectGameJoinId: "",
        };
    }

    handlePlayerAddModalOpen = (e, gameJoinId) => {
        e.preventDefault();
    };

    handlePlayerAddModalClose = () => {
        this.setState({
            playerAddModalOpen: false,
        });
    };

    toDate(dateStr) {
        if (dateStr) {
            let parts = dateStr.split("-");
            let day = parts[2].split("T");
            let time = day[1].split(".");
            let covertedDate = parts[0] + "-" + parts[1] + "-" + day[0] + " " + time[0];
            return covertedDate;
        } else {
            return;
        }
    }

    render() {
        const list = this.props.aplicationTeamList.map((info, index) =>
            index === this.props.aplicationTeamList.length - 1 ? (
                <TableRow key={info.id}>
                    <Table.Cell textAlign="center">{info.team.team_text}</Table.Cell>
                    <Table.Cell textAlign="center">{this.toDate(info.reg_date)}</Table.Cell>
                    {info.register_state === 0 && (
                        <>
                            <Table.Cell textAlign="center">신청</Table.Cell>
                            <Table.Cell textAlign="center">
                                <CompetitionTeamApproveModalForm id={info.id} competition_id={info.competition.id} team_id={info.team.id} register_state={info.register_state} reloadPage={this.props.getCompetitionAplication} getJoinTeamData={this.props.getJoinTeamData} applicationMember={this.props.aplicationTeamList[index].reg_member} />
                            </Table.Cell>
                        </>
                    )}
                    {info.register_state === 1 && (
                        <>
                            {/* {console.log(info.team.team_text)} */}
                            <Table.Cell textAlign="center">승인완료</Table.Cell>
                            <Table.Cell textAlign="center">
                                <PlayerAddModal id={info.id} competitionId={this.props.competition_id} competitionState={this.props.competitionState} teamId={info.team.id} reloadPage={this.props.getCompetitionAplication} />
                            </Table.Cell>
                        </>
                    )}
                    {info.register_state === 2 && (
                        <>
                            <Table.Cell textAlign="center">반려</Table.Cell>
                            <Table.Cell textAlign="center"></Table.Cell>
                        </>
                    )}
                </TableRow>
            ) : (
                <TableRow key={info.id}>
                    {/* {console.log(info.team.team_text)} */}
                    <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                        {info.team.team_text}
                    </Table.Cell>
                    <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                        {this.toDate(info.reg_date)}
                    </Table.Cell>
                    {info.register_state === 0 && (
                        <>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                신청
                            </Table.Cell>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                <CompetitionTeamApproveModalForm id={info.id} competition_id={info.competition.id} team_id={info.team.id} register_state={info.register_state} reloadPage={this.props.getCompetitionAplication} getJoinTeamData={this.props.getJoinTeamData} applicationMember={this.props.aplicationTeamList[index].reg_member} />
                            </Table.Cell>
                        </>
                    )}
                    {info.register_state === 1 && (
                        <>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                승인완료
                            </Table.Cell>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                <PlayerAddModal id={info.id} competitionId={this.props.competition_id} competitionState={this.props.competitionState} teamId={info.team.id} reloadPage={this.props.getCompetitionAplication} />
                            </Table.Cell>
                        </>
                    )}
                    {info.register_state === 2 && (
                        <>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center">
                                반려
                            </Table.Cell>
                            <Table.Cell style={{ borderBottom: "1px solid #000000" }} textAlign="center"></Table.Cell>
                        </>
                    )}
                </TableRow>
            )
        );

        return (
            <div>
                <Segment attached="top" clearing secondary style={{ border: "1px solid #000000" }}>
                    <Header as="h3" floated="left">
                        신청팀 현황{" "}
                    </Header>
                    <Header as="h4" floated="right">
                        <CompetitionDrawModalForm joinTeamList={this.props.joinTeamList} reloadPage={this.props.getJoinTeamData} />
                    </Header>
                    <Header as="h4" floated="right">
                        <CompetitionTeamModalForm competition_id={this.props.competition_id} league_id={this.props.league_id} reloadPage={this.props.getCompetitionAplication} />
                    </Header>
                </Segment>
                <Segment attached style={{ border: "1px solid #000000", borderTop: "0" }}>
                    <Table striped unstackable style={{ border: "1px solid #000000" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width={1} textAlign="center">
                                    팀명
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width={1} textAlign="center">
                                    신청시간
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width={1} textAlign="center">
                                    처리상태
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ borderBottom: "1px solid #000000" }} width={1} textAlign="center">
                                    설정
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>{list}</Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}

export default CompetitionJoinTeamList;
