import React, { Component } from "react";
import styles from "./CompetitionInfoLeague.module.css";
import { isMobile, isTablet, isAndroid, isIOS } from "react-device-detect";
import TournamentBracketForResult from "./TournamentBracketForResult.js";
import { withTranslation } from "react-i18next";

class CompetitionInfoLeague extends Component {
    constructor(props) {
        super(props);
    }

    changeTitle = (league) => {
        const { t } = this.props;
        if (league === 1) {
            return <h1 className={styles.league1_title}>{t("competition_info.Title_1")}</h1>;
        } else if (league === 2) {
            return <h1 className={styles.league2_title}>{t("competition_info.Title_2")}</h1>;
        } else {
            return <h1 className={styles.league3_title}>{t("competition_info.Title_3")}</h1>;
        }
    };

    renderImgBorder1 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border1} src="/images/info_competition/league_result/1st_border.png" alt="1st team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border1} src="/images/info_competition/league_result/1st_border.png" alt="1st team" />;
        }
    };

    renderImgBorder2 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border2} src="/images/info_competition/league_result/2nd_border.png" alt="2nd team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border2} src="/images/info_competition/league_result/2nd_border.png" alt="2nd team" />;
        }
    };

    renderImgBorder3 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border3} src="/images/info_competition/league_result/3rd_border.png" alt="3rd team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border3} src="/images/info_competition/league_result/3rd_border.png" alt="3rd team" />;
        }
    };

    renderImgBorder4 = () => {
        if (isMobile || isAndroid || isIOS || isTablet) {
            return <img className={styles.rank_team_border4} src="/images/info_competition/league_result/4th_border.png" alt="4th team" style={{ filter: "none" }} />;
        } else {
            return <img className={styles.rank_team_border4} src="/images/info_competition/league_result/4th_border.png" alt="4th team" />;
        }
    };

    render() {
        return (
            <>
                <div className={styles.wrap_div}>
                    {this.changeTitle(this.props.league)}
                    <div className={styles.content_div}>
                        <div className={styles.rank_div2} style={{ marginTop: "0px" }}>
                            <div style={{ position: "relative" }}>
                                {this.props.teams[1].team.team_image_file === null ? <img className={styles.logoTeam_img2} src="/images/default_teamlogo_02.png" alt="2nd team logo" /> : <img className={styles.logoTeam_img2} src={this.props.teams[1].team.team_image_file} alt="2nd team logo" />}
                                {/* <img className={styles.rank_team_border2} src="/images/info_competition/league_result/2nd_border.png" alt="2nd team" /> */}
                                {this.renderImgBorder2()}
                                <img className={styles.rank_flag} src="/images/info_competition/league_result/2nd_flag.png" alt="2nd team" />
                                <p className={styles.rank_team_name}>{this.props.teams[1].team.team_text}</p>
                                {/* <p className={styles.rank_team_reward}>₩{this.props.teams[1].reward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                            </div>
                        </div>

                        <div className={styles.rank_div}>
                            <div style={{ position: "relative" }}>
                                {this.props.teams[0].team.team_image_file === null ? <img className={styles.logoTeam_img1} src="/images/default_teamlogo_02.png" alt="1st team logo" /> : <img className={styles.logoTeam_img1} src={this.props.teams[0].team.team_image_file} alt="1st team logo" />}
                                {/* <img className={styles.rank_team_border1} src="/images/info_competition/league_result/1st_border.png" alt="1st team" /> */}
                                {this.renderImgBorder1()}
                                <img className={styles.rank_flag} src="/images/info_competition/league_result/1st_flag.png" alt="1st team" />
                                <p className={styles.rank_team_name}>{this.props.teams[0].team.team_text}</p>
                                {/* <p className={styles.rank_team_reward}>₩{this.props.teams[0].reward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                            </div>
                        </div>

                        <div className={styles.rank_div3}>
                            <div style={{ position: "relative" }}>
                                {this.props.teams[2].team.team_image_file === null ? <img className={styles.logoTeam_img2} src="/images/default_teamlogo_02.png" alt="3rd team logo" /> : <img className={styles.logoTeam_img2} src={this.props.teams[2].team.team_image_file} alt="3rd team logo" />}
                                {/* <img className={styles.rank_team_border3} src="/images/info_competition/league_result/3rd_border.png" alt="3rd team" /> */}
                                {this.renderImgBorder3()}
                                <img className={styles.rank_flag} src="/images/info_competition/league_result/3rd_flag.png" alt="3rd team" />
                                <p className={styles.rank_team_name}>{this.props.teams[2].team.team_text}</p>
                                {/* <p className={styles.rank_team_reward}>₩{this.props.teams[2].reward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                            </div>
                        </div>

                        <div className={styles.rank_div4} style={{ marginRight: "0px" }}>
                            <div style={{ position: "relative" }}>
                                {this.props.teams[3].team.team_image_file === null ? <img className={styles.logoTeam_img2} src="/images/default_teamlogo_02.png" alt="4th team logo" /> : <img className={styles.logoTeam_img2} src={this.props.teams[3].team.team_image_file} alt="4th team logo" />}
                                {/* <img className={styles.rank_team_border4} src="/images/info_competition/league_result/4th_border.png" alt="4th team" /> */}
                                {this.renderImgBorder4()}
                                <img className={styles.rank_flag} src="/images/info_competition/league_result/4th_flag.png" alt="4th team" />
                                <p className={styles.rank_team_name}>{this.props.teams[3].team.team_text}</p>
                                {/* <p className={styles.rank_team_reward}>₩{this.props.teams[3].reward.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <TournamentBracketForResult competition_id={this.props.competitionId} league_id={this.props.league} />
            </>
        );
    }
}

export default withTranslation()(CompetitionInfoLeague);
