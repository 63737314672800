import React, { Component } from 'react';
import Topmenu from '../Topmenu/Topmenu.js';
import Footer from "../footer/Footer.js";
import styles from './NiceFail.module.css';
import ModalChoiceOne from '../warning_modal/ModalChoiceOne.js';


class NiceFail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            warningModalOpen: false,
            warningText: '',
        }
    }
    

    componentDidMount = () => {
        this.setState({
            warningModalOpen: true,
            warningText: '오류가 발생하였습니다. 관리자에게 문의하여주세요.'
        })
    }

    handleWarningModalClose = () => {
        this.setState({
            warningModalOpen: false,
        })
        window.location.href='/'
    }
    


    render() {
        return (
            <>
                <Topmenu />
                <div style={{ height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "hidden" }}>
                    <div style={{ height: "100vh" }} className={styles.stars}></div>
                    <div style={{ height: "100vh" }} className={styles.twinkling}></div>
                </div>
                <Footer />
                <ModalChoiceOne isOpen={this.state.warningModalOpen} warningText={this.state.warningText} closeMethod={this.handleWarningModalClose} />
            </>
        );
    }
}

export default NiceFail;